export default {
  ru: {
    privilege_club_for_new_residents: 'Клуб привилегий для новоселов',
    privilege_club: 'КЛУБ ПРИВИЛЕГИЙ',
    for_new_residents: 'для новоселов',
    kitchens: 'Кухни',
    furniture: 'Мебель',
    finishing_work: 'Отделочные работы',
    repair_products: 'Товары для ремонта',
    in_stock_and_at_special_prices: 'В наличии и по спец ценам',
    our_partner_furniture_factory_vivat: 'Наш партнер — фабрика мебели Vivat',
    contact_the_designer: 'Связаться с дизайнером',
    companies_with_extensive_experience: 'Компании с большим опытом',
    provide_free_measurements_and_estimates: 'Бесплатно выполним обмер и смету',
    discounts_on_the_card: 'Скидки по карте',
    our_partner: 'Наш партнёр',
    get_card: 'Получить карту',
    request_sent: 'Ваша заявка отправлена! Ожидайте звонка специалиста.',
    advantageous_offers: 'Выгодные предложения',
    discounts_promotions: 'скидки, акции',
  },

  en: {
    privilege_club_for_new_residents: 'Privilege club for new residents',
    privilege_club: 'PRIVILEGE CLUB',
    for_new_residents: 'for new residents',
    kitchens: 'Kitchens',
    furniture: 'Furniture',
    finishing_work: 'Finishing work',
    repair_products: 'Repair products',
    in_stock_and_at_special_prices: 'In stock and at special prices',
    our_partner_furniture_factory_vivat: 'Our partner is a furniture factory Vivat',
    contact_the_designer: 'Contact the designer',
    companies_with_extensive_experience: 'Companies with extensive experience',
    provide_free_measurements_and_estimates: 'We will provide free measurements and estimates',
    discounts_on_the_card: 'Discounts on card',
    our_partner: 'Our partner',
    get_card: 'Get a card',
    request_sent: 'Your request has been sent! Wait for a call from a specialist.',
    advantageous_offers: 'Advantageous offers',
    discounts_promotions: 'discounts, promotions',
  },
}