<template>
  <div class="section-header">
    <slot name="icon" />

    <router-link v-if="moreLink" :to="moreLink" class="section-header__more-link">
      <slot>
        <h2 class="section-header__title" v-html="title"></h2>
      </slot>

      <span class="section-header__more">
        {{ $t('more') }}
        <chevron-right-icon size="16" class="section-header__more-icon" />
      </span>
    </router-link>

    <template v-else>
      <slot>
        <h2 class="section-header__title" v-html="title"></h2>
      </slot>
    </template>
  </div>
</template>

<script>
import { ChevronRightIcon } from '@vue-hero-icons/outline';

export default {
  name: 'SectionHeader',

  components: {
    ChevronRightIcon,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    moreLink: {
      type: Object, // Router object
      required: false,
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

.section-header
  display: inline-flex
  margin-bottom: 16px

  @media screen and (min-width: 992px)
    margin-bottom: 24px

  ::v-deep .icon
    color: $cIconNeutral
    margin-right: 8px
    flex-shrink: 0

.section-header__title
  +TextHeader(1)
  flex-shring: 1

.section-header__more-link
  display: flex
  flex-direction: row
  align-items: center

  &:hover .section-header__more
    color: $cTextMain

.section-header__more
  +TextMini()
  +Transition(color)
  display: flex
  flex-direction: row
  align-items: center
  margin-left: 16px
</style>
