<template>
  <div
    v-if="accounts"
    class="accounts"
  >
    <base-button
      type="button"
      style-class="text"
      class="accounts__add"
      @click="openAddAccountIdentModal"
    >
      <plus-icon />
      {{ $t('connect_personal_account') }}
    </base-button>

    <div
      v-if="config.isShowUpdateMonthAmountWarning"
      class="accounts__pay-alert"
    >
      <svg
        class="accounts__pay-alert-icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 23C9.82441 23 7.69767 22.3549 5.88873 21.1462C4.07979 19.9375 2.66989 18.2195 1.83733 16.2095C1.00477 14.1995 0.786929 11.9878 1.21137 9.85401C1.6358 7.72022 2.68345 5.76021 4.22183 4.22183C5.76021 2.68345 7.72022 1.6358 9.85401 1.21137C11.9878 0.786929 14.1995 1.00477 16.2095 1.83733C18.2195 2.66989 19.9375 4.07979 21.1462 5.88873C22.3549 7.69767 23 9.82441 23 12C22.9967 14.9164 21.8367 17.7124 19.7745 19.7745C17.7124 21.8367 14.9164 22.9967 12 23ZM12 3.00001C10.22 3.00001 8.47992 3.52785 6.99987 4.51678C5.51983 5.50571 4.36628 6.91132 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12C20.9972 9.61391 20.0481 7.32633 18.3609 5.6391C16.6737 3.95188 14.3861 3.00278 12 3.00001ZM13.0098 16C13.0098 15.8687 12.984 15.7386 12.9337 15.6173C12.8835 15.4959 12.8098 15.3857 12.717 15.2928C12.6241 15.2 12.5138 15.1263 12.3925 15.0761C12.2712 15.0258 12.1411 15 12.0098 15H12C11.8027 15.0019 11.6103 15.0622 11.4471 15.1733C11.284 15.2843 11.1573 15.4412 11.0831 15.624C11.0089 15.8069 10.9905 16.0077 11.0302 16.201C11.0699 16.3943 11.1659 16.5716 11.3061 16.7105C11.4463 16.8494 11.6245 16.9436 11.8182 16.9814C12.012 17.0192 12.2125 16.9989 12.3947 16.9229C12.5768 16.8469 12.7324 16.7188 12.8419 16.5545C12.9514 16.3903 13.0098 16.1974 13.0098 16ZM13 12V8.00001C13 7.73479 12.8946 7.48043 12.7071 7.2929C12.5196 7.10536 12.2652 7 12 7C11.7348 7 11.4804 7.10536 11.2929 7.2929C11.1054 7.48043 11 7.73479 11 8.00001V12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13C12.2652 13 12.5196 12.8946 12.7071 12.7071C12.8946 12.5196 13 12.2652 13 12Z"
          fill="white"
        />
      </svg>
      <p class="accounts__pay-alert-text">
        {{ $t('update_sum_information') }}
      </p>
    </div>

    <div
      v-show="accountsCount > 1"
      class="accounts__total"
    >
      <div v-loading="isLoadingTotalSum">
        <div
          v-if="!isLoadingTotalSum"
          class="total"
        >
          <div :class="['total__content', !canPayForAllAccounts ? `total__content--no-button` : false]">
            <div class="total__amount">
              <span>{{ $t('to_pay') }}</span>
              {{ accountsCount }} {{ $t('personal_accounts_on') }}
              <strong>{{ totalSum }} ₽</strong>
            </div>
            <div
              v-if="canPayForAllAccounts && !hideComissionInfo"
              class="total__tax"
            >
              {{ $t('commission_included_in_payment_amount') }} {{ totalComission }} ₽
            </div>
          </div>
          <base-button
            v-if="canPayForAllAccounts"
            type="button"
            class="total__button"
            @click="handlePayAccount(null)"
          >
            {{ $t('pay_all') }}
          </base-button>
        </div>
      </div>
    </div>

    <alert
      v-if="isGranel"
      type="danger"
      critical
      class="accounts__alert"
    >
      {{ $t('when_paying_through_sber') }}
    </alert>

    <div
      class="accounts__list"
      v-loading="content.isLoading"
    >
      <div
        v-for="(account, i) in activeAccounts"
        :key="`account-${i}`"
        class="accounts__item"
      >
        <div class="card">
          <div class="card__header">
            <div class="card__title">
              <h3 class="card__name">{{ $t('short_personal_account') }} № {{ account.Ident }}</h3>
              <p class="card__address">{{ account.Address }}</p>

              <p class="card__company-name">{{ account.CompanyName }}</p>
            </div>
            <trash-icon
              class="card__delete-icon"
              :title="`${$t('delete_personal_account')} ${account.Ident}`"
              @click="deleteAccountInfo(account.Ident)"
            />
          </div>
          <div class="card__body">
            <p class="card__field card__field--date"><span>На</span> {{ account.DebtActualDate }}</p>
            <p class="card__field card__field--amount field">
              <span>{{ $t('to_pay') }}</span>
              <span
                :class="{ 'amount--red': config.HighlightDebtInRed }"
                class="field__amount amount"
              >
                {{ account.Sum }}
              </span> ₽
            </p>
            <p
              v-if="config.useSeparatedFinePayment && account.SumFine !== 0"
              class="card__field card__field--date"
            >
              <span>{{ $t('penalty_incl') }}</span> {{ account.SumFine }} ₽
            </p>
          </div>
          <div class="card__footer">
            <base-button
              type="button"
              style-class="secondary"
              @click="handlePayAccount(account)"
            >
              {{ $t('pay') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="closedAccounts.length"
      class="accounts__list accounts__list--closed"
    >
      <p class="accounts__header">Закрытые лицевые счета</p>
      <div
        v-for="(account, i) in closedAccounts"
        :key="`account-${i}`"
        class="accounts__item"
      >
        <div class="card">
          <div class="card__header">
            <div class="card__title">
              <h3 class="card__name">{{ $t('short_personal_account') }} № {{ account.Ident }}</h3>
              <p class="card__address">{{ account.Address }}</p>
              <p class="card__company-name">{{ account.CompanyName }}</p>
            </div>
            <trash-icon
              class="card__delete-icon"
              :title="`${$t('delete_personal_account')} ${account.Ident}`"
              @click="deleteAccountInfo(account.Ident)"
            />
          </div>
          <div class="card__body">
            <p class="card__field card__field--date"><span>На</span> {{ account.DebtActualDate }}</p>
            <p class="card__field card__field--amount field">
              <span>{{ $t('to_pay') }}</span>
              <span
                :class="{ 'amount--red': config.HighlightDebtInRed }"
                class="field__amount amount"
              >
                {{ account.Sum }}
              </span> ₽
            </p>
            <p
              v-if="config.useSeparatedFinePayment && account.SumFine !== 0"
              class="card__field card__field--date"
            >
              <span>{{ $t('penalty_incl') }}</span> {{ account.SumFine }} ₽
            </p>
          </div>
          <div class="card__footer">
            <base-button
              type="button"
              style-class="secondary"
              @click="handlePayAccount(account)"
            >
              {{ $t('pay') }}
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <email-for-pay-modal name="emailForPayModal" @close="onCloseEmailForPayModal" @continue="onRedirectByEmailForPayModal"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { TrashIcon, PlusIcon } from '@vue-hero-icons/outline';
import BaseButton from '@/components/ui/BaseButton';
import Alert from '@/components/ui/Alert';
import EmailForPayModal from '@/components/pages/payment/EmailForPayModal'

export default {
  name: 'personalAccounts',

  components: {
    TrashIcon,
    PlusIcon,
    BaseButton,
    Alert,
    EmailForPayModal
  },

  props: {
    content: {
      type: [Array, Object],
      required: true,
    },
  },

  data() {
    return {
      totalSum: 0,
      totalComission: 0,
      hideComissionInfo: false,
      isLoadingTotalSum: true,
      currentPayLink: '',
    };
  },

  computed: {
    ...mapGetters({
      canPayForAllAccounts: 'account/canPayForAllAccounts',
    }),

    accounts() {
      let accounts = this.content.data;

      if (this.isDgservice) {
        accounts = accounts.filter(
          (account) => {
            if (/^\d+$/.test(account.Ident)) return true;
          }
        );
      }

      return accounts;
    },

    /**
    * Количество лицевых счетов
    * @returns {number}
    */
    accountsCount() {
      return this.activeAccounts.length;
    },

    /**
    * Уникальные лицевые счета. Удалены дубликаты.
    * @returns {array} Список счетов.
    */
    uniqueAccounts() {
      // console.log('--- uniqueAccounts computed value ---')
      return this.activeAccounts
        .map((x) => {
          return x;
        })
        .filter((x, i, self) => self.findIndex((t) => t.Ident == x.Ident) === i);
    },

    activeAccounts() {
      const accounts = this.accounts || [];

      return accounts.filter(account => !account.IsClosed);
    },

    closedAccounts() {
      const accounts = this.accounts || [];

      return accounts.filter(account => account.IsClosed);
    }
  },

  created() {
    this.$root.$on('show-email-for-pay-modal', this.onOpenEmailForPayModal);
  },

  destroyed() {
    this.$root.$off('show-email-for-pay-modal')
  },

  watch: {
    content: {
      deep: true,
      handler() {
        this.getTotalSum();
      },
    },
  },

  methods: {
    ...mapActions({
      deleteAccountByIdent: 'account/deleteAccountByIdent',
      getSumWithCommission: 'account/getSumWithCommission',
    }),

    /**
     * Удаление адреса по номеру лицевого счета.
     * @param {number} ident - Номер лицевого счета.
     */
    deleteAccountInfo(ident) {
      // console.log('--- deleteAccountInfo ---')
      this.openDialog({
        title: this.$t('deleting_personal_account'),
        text: `${this.$t('really_delete_address')} (${this.$t('short_personal_account')} ${ident})?`,
        buttons: [
          {
            title: this.$t('cancel'),
            type: 'additional',
            handler: () => {
              this.cancelDialog();
            },
          },
          {
            title: this.$t('delete'),
            type: 'base',
            handler: () => {
              this.deleteAccountByIdent(ident);

              this.cancelDialog();
            },
          },
        ],
      });
    },

    getTotalSum() {
      if (this.accounts) {
        if (this.canPayForAllAccounts) {
          this.isLoadingTotalSum = true;

          this.getSumWithCommission({
            sum: this.uniqueAccounts
              .reduce((accumulator, current) => {
                if (current.Sum > 0) {
                  return parseFloat((accumulator + current.Sum).toFixed(2));
                } else {
                  return accumulator;
                }
              }, 0),
            accountID: null
          })
            .then((result) => {
              this.totalSum = result.TotalSum;
              this.totalComission = result.Commission;
              this.hideComissionInfo = result.HideComissionInfo;

              this.isLoadingTotalSum = false;
            })
            .catch((error) => {
              throw error;
            });
        } else {
          this.totalSum = this.uniqueAccounts.reduce((accumulator, current) => {
            return parseFloat((accumulator + current.Sum).toFixed(2));
          }, 0);
          this.isLoadingTotalSum = false;
        }
      } else {
        this.isLoadingTotalSum = false;
      }
    },

    handlePayAccount(account) {
      console.log('--- handlePayAccount ---');
      console.log('account:', account);

      if (typeof account !== 'undefined') {
        if (account.PaymentMode === 'Invoice') {
          this.openPayInvoicesModal(account.AccountID)
        } else {
          this.openPayModal(account.AccountID)
        }
      } else {
        this.openPayModal(null)
      }
    },
    onOpenEmailForPayModal (payLink) {
      this.currentPayLink = payLink;
      this.$modal.show('emailForPayModal');
    },
    onCloseEmailForPayModal () {
      this.$modal.hide('emailForPayModal');
    },
    onRedirectByEmailForPayModal () {
      this.onCloseEmailForPayModal();
      location.href = this.currentPayLink;
      this.currentPayLink = ''
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/sass/variables";
@import "src/sass/mixins";

.accounts__add {
  width: 100%;
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24);
  margin-bottom: 16px;
}

.accounts__pay-alert {
  display: flex;
  gap: 13px;
  background-color: #C69A00;
  padding: 16px;
  border-radius: 16px;
}

.accounts__pay-alert-icon {
  flex-shrink: 0;
}

.accounts__pay-alert-text {
  color: white;
  font-size: 13px;
  margin: 0;
}

.accounts__total {
  padding: 24px 0;
}

.accounts__alert {
  margin-bottom: 16px;
}

.accounts__header {
  @include TextHeader(2);
  padding-top: 20px;
  margin-bottom: 10px;
}

.accounts__item {
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.accounts {
  .total {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .total__content {
    width: 100%;
    margin-bottom: 16px;

    &--no-button {
      margin-left: auto;
      margin-right: auto;
    }

    @media screen and (min-width: 576px) {
      width: auto;
      margin-bottom: 0;
    }
  }

  .total__amount {
    @include TextMain($cTextMain);

    text-align: center;
    margin-bottom: 4px;

    span {
      color: $cLabel;
    }

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  .total__tax {
    @include TextMini();

    text-align: center;

    @media screen and (min-width: 768px) {
      text-align: left;
    }
  }

  .total__button {
    width: 100%;

    @media screen and (min-width: 576px) {
      width: 216px;
      margin-left: 24px;
    }
  }

  .card {
    @include Size(100%, auto);

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 16px;

    background: $cBgMain;
    box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24);
    border-radius: 16px;

    @media screen and (min-width: 768px) {
      padding: 16px 24px;
    }
  }

  .card__header {
    @include Size(100%, auto);

    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
      margin-bottom: 24px;
    }
  }

  .card__title {
    flex-grow: 1;
  }

  .card__name {
    @include TextHeader(2);

    margin-bottom: 8px;
  }

  .card__address {
    @include TextMini();

    max-width: 210px;
    margin-bottom: 8px;
    color: #000;
  }

  .card__company-name {
    @include TextMini();

    max-width: 210px;
    margin-bottom: 0;
  }

  .card__delete-icon {
    @include Transition((color));

    flex-shrink: 0;
    color: $cIconNeutral;

    &:last-child {
      margin-bottom: 0;
    }

    &--date {
      @include TextMain($cTextMain);

      span {
        @include TextMini();
      }
    }

    &--amount {
      @include TextSubMain($cTextMain);

      span {
        @include TextMain()
      }
    }

    @media screen and (min-width: 768px) {
      margin-bottom: 4px;
    }
  }

  .amount--red {
    color: $cRed;
  }

  .card__footer {
    @include Size(100%, auto);

    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .card__caption {
    @include TextMini();

    width: 100%;
    margin-bottom: 16px;

    @media screen and (min-width: 576px) {
      width: auto;
      margin-bottom: 0;
    }
  }

  .card__button {
    @include Button('secondary');
    @include Size(100%, 40px !important);

    border-radius: 12px;

    @media screen and (min-width: 576px) {
      @include Size(112px, 40px);
    }
  }
}
</style>
