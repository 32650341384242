<template>
  <div class="poll-results" ref="$root">
    <div class="poll-wrapper" v-if="!detailPoll.HideResults">
      <h3 class="poll-results__name">{{ detailPoll.Name }}</h3>

      <div class="poll-results__body">
        <div v-for="(question, i) in results" :key="`question-${i}`" class="poll-results__question">
          <p class="poll-results__question-title" v-html="`${i + 1}. ${question.Text}`"></p>
          <p class="poll-results__subtitle">{{ question.TotalCount }} {{ $t('votes') }}</p>

          <ul class="answers">
            <li v-for="(answer, j) in question.Answers" :key="`question-${i}-answer-${j}`" class="answers__fieldset">
              <span class="answers__progress" :style="`width:${answer.Percent}%`"></span>
              <p class="answers__title">
                {{ answer.Answer }}
                <span class="answers__count">
                  {{ answer.Count }}
                </span>
              </p>
              <span class="answers__persent"> {{ answer.Percent }} % </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="poll-end__wrapper" v-else>
      <div class="poll-end__descr">Спасибо за участие в опросе!</div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'PollResults',

  computed: {
    ...mapGetters({
      pollResults: 'polls/getPollResults',
      detailPoll: 'polls/detailPoll',
    }),

    results() {
      return this.pollResults.map((question) => ({
        ...question,
        TotalCount: question.Answers.reduce((result, answer) => result + answer.Count, 0),
      }));
    },
  },

  watch: {
    '$route.params'(val, old) {
      // console.log('--- $route.params.id watcher ---')
      if (val.id && val.id !== old.id) {
        this.openPoll(+this.$route.params.id);
        this.getPollResults(this.$route.params.id);
      }
    },
  },

  created() {
    // console.log('--- PollResults created hook ---')
    this.openPoll(+this.$route.params.id);
    this.getPollResults(this.$route.params.id);
  },

  mounted() {
    this.setPollResultsPadding();
  },

  methods: {
    ...mapActions({
      openPoll: 'polls/openPoll',
      getPollResults: 'polls/getPollResults',
    }),

    setPollResultsPadding() {
      this.$nextTick(() => {
        if (this.isCustomView(768)) {
          const $root = this.$refs.$root;
          const height = document.querySelector('footer.footer').clientHeight;

          if ($root) {
            $root.style.paddingBottom = `${height}px`;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/mixins';

.poll-results {
  height: 100%;
  position: relative;
  padding: 0;

  background: #ffffff;
  border: 1px solid $cBorder;
  border-radius: 16px 16px 0 0;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    height: auto;
    padding: 24px;
    position: initial;
    border-radius: 16px;
  }
}
.poll-results__name {
  @include TextHeader(1);

  padding: 16px;
  margin-bottom: 16px;
  background-color: $cWhite;
  border-bottom: 1px solid $cBorder;

  position: sticky;
  top: 0;
  z-index: 2;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    position: initial;
    padding: 0;
    margin-bottom: 40px;
    border-bottom: none;
  }
}
.poll-results__body {
  padding: 0 16px 16px;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    padding: 0;
  }
}
.poll-results__question:not(:last-child) {
  margin-bottom: 40px;
}
.poll-results__question-title {
  @include TextMain($cTextMain);

  margin-bottom: 12px;
}
.poll-results__subtitle {
  @include TextMini();

  margin-bottom: 16px;
}
.poll-end__wrapper {
  padding: 30px;
  display: flex;
  justify-content: center;
}

.poll-end__descr {
  font-size: 27px;
}

.answers__fieldset {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 32px;
  padding: 0 12px;
  background: $cElmLightest;
  border-radius: 8px;

  position: relative;
  z-index: 1;
  overflow: hidden;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
}
.answers__progress {
  display: block;
  height: 100%;
  background: $cElmNote;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  pointer-events: none;
}
.answers__title {
  @include TextMini($cTextMain);

  width: auto;
  margin-bottom: 0;
}
.answers__count {
  @include TextMini();

  margin-left: 8px;
}
.answers__persent {
  @include TextMini();
}
</style>
