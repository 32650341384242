import axios from 'axios';
//import * as authService from '../services/auth.service'

export function getEventBlockData(params) {
  return axios.get('/Common/EventBlockData', {
    params,
  });
}

export function getBillsDownload(id) {
  return axios.get('/Bills/Download/' + id, { responseType: 'blob' });
}

export function getBillsDownloadLink(id) {
  let address = axios.defaults.baseURL;
  let token = axios.defaults.headers.common['acx'];
  return address + '/Bills/Download/' + id + '?acx=' + encodeURIComponent(token);
}

export function getCheckDownloadLink(id) {
  console.log('--- getCheckDownloadLink api function ---');
  // FIXME: axios не содержить этих данных из-за изменения конфига.
  let address = axios.defaults.baseURL;
  let token = axios.defaults.headers.common['acx'];
  return address + '/Accounting/Check/' + id + '?acx=' + encodeURIComponent(token);
}

export function getCheckDownload(id) {
  console.log('--- getCheckDownload api function ---');
  return axios.get('/Accounting/Check/' + id, { responseType: 'blob' });
}

// ? TODO: А почему это нельзя сделать в хранилище?
// Тут же нет запроса на сервер.
export function getMockupDownloadLink(number) {
  // let address = axios.defaults.baseURL; // ! Returns "undefined"
  let address = window.appSettings.apiUrl;
  return address + '/Public/DownloadMockup/' + number;
}

export function techSupportAppeal({ login, phone, mail, text, address, os, info, appVersion, idents }) {
  return axios.post('/Public/TechSupportAppeal', {
    login,
    phone,
    mail,
    text,
    address,
    os,
    info,
    appVersion,
    idents,
  });
}

export function getPersonalDataPolicy(host) {
  return axios.get('/Public/WebPersonalDataPolicy?host=' + encodeURIComponent(host));
}

export function getPaymentSystemImageDownloadLink(id) {
  let address = window.appSettings.apiUrl;
  return address + '/Public/PaymentSystemImage/' + id;
}

// TECH SUPPORT
export function registerNewDevice() {
  return axios.get('/TechSupport/RegisterNewDevice');
}

export function getMessages(params) {
  return axios.get('/TechSupport/GetMessages', {
    params,
  });
}

export function addMessage(data) {
  return axios.post('/TechSupport/AddMessage', data);
}

export function addFile(data) {
  return axios.post('/TechSupport/AddFile', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function getFile(params) {
  return axios.get('/TechSupport/File', {
    params,
    responseType: 'blob',
  });
}

export function getSupportHelpTree() {
  return axios.get('/TechSupport/GetSupportHelpTree');
}

export function clickableLink(params) {
  return axios.post('/Common/ClickableLink', params);
}
