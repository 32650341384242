<template>
  <div class="new-request-page">
    <page-header :title="$t('new_request')" :back="{
      title: $t('requests'),
      to: { name: 'Requests' },
    }" />

    <section class="new-request">
      <!-- Form -->
      <form v-loading="isSending" v-on:submit.prevent class="new-request__form">
        <!-- Account -->
        <form-box-select v-model="form.ident" :label="$t('address')" :list="groupAccounts" valuePropName="Ident"
          keyPropName="AccountID" :maxShowOptions="6">
          <!-- @input="setForm" -->
          <template #selectedOption="{ item }">
            {{ item.Address ? item.Address : item.AccountType }}
          </template>
          <template #option="{ item }">
            {{ item.Address ? item.Address : item.AccountType }}
          </template>
        </form-box-select>

        <alert
          v-if="emergencyWorkList.length"
          type="danger"
          critical
          class="emergency-works"         
        >
          <div class="emergency-works__content">
            <h3 class="emergency-works__header">
              {{ $t('emergency_works_title') }}
            </h3>
            <span>
              {{ $t('emergency_works_alert_description') }}
            </span>
            <div class="emergency-works__dates">
              <div
                v-for="(work, index) in emergencyWorkList"
                :key="index"
                class="emergency-works__item"
              >
                <div class="emergency-works__row">
                  <span>{{ $t('emergency_works_header_2') }}:</span>
                  <span>{{ work.DateStart }}</span>
                </div>
                <div class="emergency-works__row">
                  <span>{{ $t('emergency_works_header_3') }}:</span>
                  <span>{{ work.DateEnd }}</span>
                </div>
              </div>
            </div>
          </div>
        </alert>

        
        <h4 class="new-request__emergency-header">
          {{ $t('emergency_works_title') }}
        </h4>

        <!-- Type -->
        <form-box-select v-model="form.typeID" :label="$t('request_type')" :list="types" valuePropName="ID" :maxShowOptions="6"
          keyPropName="Name" @input="handleSelectRequestType" />

        <!-- Subtype -->
        <form-box-select v-if="subTypes.length > 0" v-model="form.subTypeID" :label="$t('malfunction')" :list="subTypes"
          valuePropName="ID" keyPropName="Name" @input="handleSelectRequestSubType" :maxShowOptions="6"/>

        <!-- Detail -->
        <form-box-select v-if="detailSubTypes.length > 0" v-model="form.detailSubTypeID" :label="$t('detail_view')"
          :list="detailSubTypes" valuePropName="ID" keyPropName="Name" :maxShowOptions="6"/>

        <alert v-if="creationDeniedMessage" :caption="creationDeniedMessage" type="warning" />

        <form-box-textarea v-model="form.text" :label="$t('description_of_request')" height="84px" />

        <form-box-file v-model="files" accept="
            application/pdf, 
            image/jpeg, 	
            image/png, 
            application/excel,
            application/vnd.ms-excel,
            application/x-excel,
            application/x-msexcel, 
            application/msword" class="new-request__file" @input="selectFile">
          <div class="file">
            <p class="file__title">{{ $t('drag_or_upload_file') }}</p>
            <p class="file__text">pdf, jpg, png, xlsx, doc {{ $t('no_more_10_mb') }}</p>
            <div v-if="file" class="file__list">
              <p class="file__name">
                {{ file.name }}
              </p>
              <button type="button" class="file__remove" @click.stop="resetFile">
                <trash-icon />
              </button>
            </div>
          </div>
        </form-box-file>

        <base-button type="submit" :disabled="!isValidate" class="new-request__submit" @click="newRequest">
          {{ $t('create') }}
        </base-button>
      </form>
    </section>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { TrashIcon } from '@vue-hero-icons/outline';
import clientTimezone from '@/mixins/clientTimezone';

import * as requestApi from '@/api/request';
import * as authService from '@/services/auth.service';
import PageHeader from '@/components/pages/common/PageHeader';
import FormBoxSelect from '@/components/form/FormBoxSelect';
import FormBoxTextarea from '@/components/form/FormBoxTextarea';
import FormBoxFile from '@/components/form/FormBoxFile';
import Alert from '@/components/ui/Alert';
import BaseButton from '@/components/ui/BaseButton';


export default {
  name: 'newRequest',

  components: {
    PageHeader,
    FormBoxSelect,
    FormBoxTextarea,
    FormBoxFile,
    Alert,
    BaseButton,
    TrashIcon,
  },

  mixins: [clientTimezone],

  data() {
    return {
      form: {
        ident: null,
        typeID: null,
        subTypeID: null,
        detailSubTypeID: null,
        text: '',
      },

      selectedIdent: null,
      files: null,
      file: null,
      types: [],
      accountInfoIDs: [],
      isSending: false,
      emergencyWorksByIdents: [],
    };
  },

  computed: {
    ...mapGetters({
      accounts: 'account/getAllInfo',
      getAccountSettings: 'account/getAccountSettings',
    }),

    emergencyWorkList() {
      const selectedIdentId = this.form.ident;
      if(!selectedIdentId) {
        return []
      }
      const account = this.groupAccounts.find((item) => {
        console.log('item.Ident:', item.Ident);
        console.log('selectedIdentId:', selectedIdentId);
        return item.Ident === selectedIdentId
      })

      return this.emergencyWorksByIdents.filter((item) => {
        if (selectedIdentId) {
          return item.AccountIds.includes(account.AccountID)
        }
        return true;
      }).map(item => {
        const dateKeys = ['DateStart', 'DateEnd'];
        const processedItem = { ...item };

        dateKeys.forEach(key => {
          if (processedItem[key]) {
            processedItem[key] = moment(processedItem[key], 'YYYY-MM-DDTHH:mm:ss')
            .set(
              'hour',
              moment(processedItem[key], 'YYYY-MM-DDTHH:mm:ss').hour() - this.clientTimeZone,
            )
            .format('DD.MM.YYYY HH:mm');
          }
        })

        return {
          DateStart: processedItem.DateStart,
          DateEnd: processedItem.DateEnd,
        }
      })
    },

    isValidate() {
      const detailSubTypeID = this.detailSubTypes.length > 0 ? this.form.detailSubTypeID : true;
      const subTypeID = this.subTypes.length > 0 ? this.form.subTypeID : true;
      return (
        this.form.text.length > 0 && this.form.typeID   && subTypeID  && this.form.ident && detailSubTypeID  && !this.isCreationDenied
      );
    },

    groupAccounts() {
      return this.accounts
        .filter((account, i, self) => {
          return self.findIndex((text) => text.Address == account.Address) === i;
        })
        .filter((account) => !this.getAccountSettings(account.AccountID).HouseCantCreateRequests)
        .sort((a, b) => a.Ident - b.Ident);
    },

    selectedAccountInfo() {
      if (!this.selectedIdent) {
        return null;
      }

      const info = authService.getUserData();

      if (info && info.accounts && info.accounts.length) {
        var infos = info.accounts.filter((x) => x.ident == this.form.ident);
        if (infos.length) {
          return infos[0];
        }
      }

      return null;
    },

    isCreationDenied() {
      const info = this.accounts.find((account) => {
        return account.Ident === this.form.ident;
      });

      return info && info.denyRequestCreation;
    },

    creationDeniedMessage() {
      const info = this.accounts.find((account) => {
        return account.Ident === this.form.ident;
      });

      if (!info) {
        return null;
      }

      return info.denyRequestCreationMessage;
    },

    subTypes() {
      if (this.form.typeID) {
        const type = this.types.find((type) => {
          return type.ID == this.form.typeID && type.HasSubTypes;
        });
        if (typeof type !== 'undefined' && type.SubTypes.length > 0) {
          return type.SubTypes;
        }
      }

      return [];
    },

    detailSubTypes() {
      if (this.form.subTypeID) {

        const type = this.subTypes.find((type) => {
          return type.ID == this.form.subTypeID && type.HasSubTypes;
        });

        if (typeof type !== 'undefined' && type.SubTypes.length > 0) {
          return type.SubTypes;
        }
      }

      return [];
    },
  },

  watch: {
    emergencyWorksByIdents(value) {
      const currentTableBody = value.map(work => {
        const startDate = work.DateStart ? moment(work.DateStart).format('DD.MM.YYYY HH:mm') : '-';
        const endDate = work.DateEnd ? moment(work.DateEnd).format('DD.MM.YYYY HH:mm') : '-';

        return [
          { value: work.Description || '-' },
          { value: startDate, textColor: '#A1A3B1' },
          { value: endDate || '-', textColor: '#A1A3B1' },
        ]
      });

      this.$set(this.emergencyWorksTable, 'body', currentTableBody);
    },
  },

  created() {
    this.getEmergencyWorks().then(data => this.emergencyWorksByIdents = data);
  },

  mounted() {
    this.getRequestTypes();
    this.getAccountInfo();
  },

  methods: {
    ...mapActions({
      newRequestAction: 'request/newRequest',
      getInfo: 'account/getInfo',
      getRequestTypesAction: 'request/getRequestTypes',
      getEmergencyWorks: 'emergencyWorks/getList',
    }),

    getRequestTypes() {
      this.getRequestTypesAction().then((result) => {
        this.types = [...result];
        this.types = this.types.map((el) => {
          return {
            ...el,
            Name: el?.Name.charAt(0).toUpperCase() + el?.Name.substr(1),
          };
        });
      });
    },

    selectFile() {
      if (this.files.length > 0) {
        this.file = this.files[0];
      } else {
        this.file = null;
      }
    },

    resetFile() {
      this.file = null;
    },

    newRequest() {
      if (!this.isValidate) {
        return;
      }
      if(this.subTypes.length === 0) {
        this.form.subTypeID = null;
      }
      if(this.detailSubTypes.length === 0) {
        this.form.detailSubTypeID = null;
      }
      this.isSending = true;

      this.newRequestAction(this.form)
        .then((result) => {
          if (result.id) {
            if (this.file) {
              let formData = new FormData();
              formData.append('requestId', result.id);
              formData.append('file', this.file);

              // TODO: refactor
              requestApi
                .addFile(formData)
                .then(() => {
                  this.isSending = false;
                  // this.$root.$emit("new-request", result.id);
                })
                .catch((error) => {
                  this.isSending = false;
                  console.error(error);
                  // this.$root.$emit("new-request", result.id);
                });
            }
            this.$notify.success(this.$t('request_created'));
            this.$router.push(`/requests/${result.id}`);
          } else {
            this.$notify.error(this.$t('error_while_creating_request'));
          }
          // this.$root.$emit("new-request", result.id);

          this.isSending = false;
        })
        .catch((error) => {
          this.isSending = false;
          console.error(error);
        });
    },

    getAccountInfo() {
      this.getInfo().then((result) => {
        this.accountInfoIDs = [...result.keys];
      });
    },

    handleSelectRequestType() {
      this.form.subTypeID = null;
      this.form.detailSubTypeID = null;
    },

    handleSelectRequestSubType() {
      this.form.detailSubTypeID = null;
    }
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.new-request-page
  .page-header
    display: block
.new-request
  +ContentContainer()
  .file
    +Size(100%, auto)
    display: flex
    justify-content: center
    flex-direction: column
    padding: 24px
    border: 1px dashed $cBorder
    border-radius: 16px
    cursor: pointer

  .file__title
    +TextMain()
    text-align: center
    margin-bottom: 8px

  .file__text
    +TextMini()
    text-align: center
    margin-bottom: 0

  .file__list
    display: inline-flex
    justify-content: center
    align-items: center
    margin-top: 8px

  .file__name
    +TextMain()
    margin-bottom: 0

  .file__remove
    +ButtonIcon()
    flex-shrink: 1
    margin-left: 16px

.new-request__form
  display: flex
  flex-direction: column

  @media screen and (min-width: 992px)
    display: block

.new-request__submit
  width: 200px
  align-self: center

.new-request__emergency-header
  padding: 12px 0 24px

.emergency-works
  margin: 24px 0 12px
  align-items: flex-start

.emergency-works__content
  display: flex
  flex-direction: column
  gap: 15px

.emergency-works__dates
  display: flex
  flex-wrap: wrap
  gap: 15px

.emergency-works__item
  display: flex
  flex-direction: column
  width: fit-content

.emergency-works__row
  display: flex
  gap: 5px
  justify-content: space-between
</style>
