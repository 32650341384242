import axios from 'axios';

export function saveResult(PollingResult) {
  return axios.post('/Polls/SaveResult', PollingResult);
}

export function setReadedFlag(ID) {
  return axios.post('/Polls/SetReadedFlag', { ID });
}

export function results(ID) {
  return axios.post('/Polls/Results/', { ID });
}
