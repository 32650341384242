<template>
  <div class="chat">
    <!-- Chat container -->
    <div ref="chatContainer" class="chat__content">
      <perfect-scrollbar ref="dialogListScroll" class="chat__wrapper">
        <template v-for="(messagesArray, date) in messages">
          <div v-loading="isLoadingMessages" :key="`list-date-${date}`">
            <div
              v-for="(message, i) in messagesArray"
              :key="i"
              :class="['chat__message', { 'chat__message--user': message.IsSelf }]"
            >
              <div class="message">
                <div class="message__header">
                  <p class="message__name">
                    {{ message.IsSelf ? $t('you') : $t('operator') }}
                  </p>
                  <span class="message__date">{{ convertDate(date, message.Added) }}</span>
                </div>

                <template v-if="message.FileID > 0">
                  <a class="message__text--file" @click="getFile(message.FileID)">
                    <download-icon class="message__download-icon" />
                    {{ message.Text }}
                  </a>
                </template>
                <template v-else>
                  <p class="message__text">{{ message.Text }}</p>
                </template>
              </div>
            </div>
          </div>
        </template>
        <div 
          class="chat__help-tree"
        >
          <template v-if="showSupportHelpTree">
            <button 
              v-if="selectedSupportHelpNodes.length"
              :disabled="isSendingMessage"
              @click="onClearSelectedSupportHelpNodes(true)"
              class="chat__help-bth"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="m112 160l-64 64l64 64"/><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M64 224h294c58.76 0 106 49.33 106 108v20"/></svg>
              Назад
            </button>
            <button 
              v-for="(helpNode, index) in currentSupportHelpTree" 
              :key="index" 
              :disabled="isSendingMessage"
              @click="onSelectSupportHelpNode(index)"
              class="chat__help-bth"
            >
              {{ helpNode.Name }}
            </button>
            <template v-if="!currentSupportHelpTree.length || selectedNodeIsConclusion">
              <button 
                :disabled="isSendingMessage"
                @click="onCallOperator()"
                class="chat__help-bth"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 14"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"><circle cx="5" cy="2.75" r="2.25"/><path d="M4.5 12.5h-4V11A4.51 4.51 0 0 1 7 7m3.5.5v6m-3-3h6"/></g></svg>
                Позвать оператора
              </button>
              <button
                :disabled="isSendingMessage" 
                @click="onEndHelp()"
                class="chat__help-bth"
              >
                Спасибо, проблема решена
              </button>
            </template>
          </template>
          <button 
            v-else
            :disabled="isSendingMessage"
            @click="onStartChangeSupportHelpTreeProcces()"
            class="chat__help-bth"
          >
            Другой вопрос
          </button>
        </div>
      </perfect-scrollbar>
    </div>

    <!-- Message form -->
    <form :class="{'chat__form--disabled': showSupportHelpTree}" ref="modalMessageForm" class="chat__form" v-on:submit.prevent>
      <!-- Attach -->
      <paper-clip-icon :class="{'chat__attach-icon--disabled': showSupportHelpTree}" class="chat__attach-icon" @click="$refs.fileControl.click()" />
      <input :disabled="showSupportHelpTree" ref="fileControl" type="file" @change="addFile" name="file" style="display: none" />

      <!-- Message textarea -->
      <input
        type="text"
        :placeholder="$t('message')"
        :disabled="isSendingMessage || showSupportHelpTree"
        class="chat__input"
        v-model="form.message"
        @keyup.enter="addMessage"
      />

      <!-- Send button -->
      <button
        type="submit"
        :title="$t('send')"
        :disabled="showSupportHelpTree"
        class="chat__send-button"
        v-loading="isSendingMessage"
        @click="addMessage"
      >
        <paper-airplane-icon class="chat__send-icon icon" />
      </button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { PaperClipIcon, PaperAirplaneIcon, DownloadIcon } from '@vue-hero-icons/outline';
import moment from 'moment';
import {cloneDeep} from 'lodash'

export default {
  name: 'supportChat',

  components: {
    PaperClipIcon,
    PaperAirplaneIcon,
    DownloadIcon,
  },

  emits: ['close-chat'],

  props: {
    phone: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      dialogListScroll: null,
      isLoadingMessages: false,
      isSendingMessage: false,
      refreshTimer: null,
      form: {
        phone: this.phone,
        message: null,
        file: null,
      },
      supportHelpTree: [],
      currentSupportHelpTree: [],
      selectedSupportHelpNodeNames: [],
      selectedSupportHelpNodes: [],
      selectedNodeIsConclusion: false,
      changeSupportHelpTreeProcces: false,
    };
  },

  created() {
    this.isLoadingMessages = true;
    const messages = this.getMessages(true);
    const tree = this.getSupportHelpTree().then((response) => {
      this.supportHelpTree = response?.data || [];
      this.currentSupportHelpTree = this.supportHelpTree;
    });

    Promise.all([messages, tree]).finally(() => this.isLoadingMessages = false)

    this.resizeThrottlerFunctionList.push(this.setChatHeight);
  },

  mounted() {
    this.scrollToBottom();

    this.timer = setInterval(() => {
      this.update();
    }, 5000);
  },

  updated() {
    this.setChatHeight();
    this.scrollToBottom();
  },

  destroyed() {
    clearInterval(this.timer);
  },

  computed: {
    ...mapState({
      shouldStartNewIssue: (state) => state.common.shouldStartNewIssue,
    }),

    ...mapGetters({
      getSortedMessages: 'common/getSortedMessages',
    }),

    messages() {
      const getSortedMessages = this.getSortedMessages
      const supportHelpTreeMessages = this.supportHelpTreeMessages;
      const sortedMessages = cloneDeep(getSortedMessages);
      const currentDate = moment().format('DD.MM.YYYY');

      if (!supportHelpTreeMessages.length) return sortedMessages;

      if (!(currentDate in sortedMessages)) {
        sortedMessages[currentDate] = [...supportHelpTreeMessages];
      } else sortedMessages[currentDate].push(...supportHelpTreeMessages);

      return sortedMessages;
    },

    supportHelpTreeMessages() {
      const selectedSupportHelpNodes = this.selectedSupportHelpNodes;
      const basePropsOfAuthorMessage = {
        IsSelf: true
      };
      const basePropsOfOperatorMessage = {
        IsSelf: false
      };
      let messages = [];

      for (let i = 0; i < selectedSupportHelpNodes.length; i++) {
        const currentSupportTreeNode = selectedSupportHelpNodes[i];
       
        messages.push({
          ...cloneDeep(basePropsOfAuthorMessage),
          Added: currentSupportTreeNode.Added,
          Text: currentSupportTreeNode.Name
        });
        
        if (currentSupportTreeNode.Conclusion) {
          messages.push({
           ...cloneDeep(basePropsOfOperatorMessage),
           Added: currentSupportTreeNode.Added,
           Text: currentSupportTreeNode.Conclusion
          });
        }
      }

      return messages;
    },

    showSupportHelpTree() {
      return this.shouldStartNewIssue || this.changeSupportHelpTreeProcces;
    }
  },

  methods: {
    ...mapActions({
      getMessagesAction: 'common/getMessages',
      addMessageAction: 'common/addMessage',
      addFileAction: 'common/addFile',
      getFileAction: 'common/getFile',
      getSupportHelpTree: 'common/getSupportHelpTree',
      updateMessagesAction: 'common/updateMessages',
    }),

    getMessages(showLoading = true) {
      if (showLoading) this.isLoadingMessages = true;
      
      this.getMessagesAction({
        phone: this.form.phone,
        deviceID: localStorage.getItem('deviceID'),
        info: navigator.userAgent,
      })
        .then(() => (this.isLoadingMessages = false))
        .catch(() => {
          if (showLoading) this.isLoadingMessages = false;
          this.$notify.error(this.$t('loading_messages_error'));
        });
    },

    addMessage(calledFromTree = false) {
      if ((calledFromTree || !this.showSupportHelpTree) && this.form.message && this.form.message.length > 0) {
        this.isSendingMessage = true;
        this.addMessageAction({
          text: this.form.message,
          phone: this.form.phone,
          deviceID: localStorage.getItem('deviceID'),
        })
          .then(() => {
            this.form.message = '';
            this.isSendingMessage = false;
            this.getMessages();
          })
          .catch(() => {
            this.isSendingMessage = false;
            this.$notify.error(this.$t('file_sending_error'));
          });
      }
    },

    addFile(event) {
      if (this.showSupportHelpTree) return;
      
      let fd = new FormData();
      fd.append('file', event.target.files[0]);
      fd.append('deviceID', localStorage.getItem('deviceID'));
      this.addFileAction(fd)
        .then(() => {
          this.getMessages();
        })
        .catch(() => {
          this.$notify.error(this.$t('file_download_error'));
        });
    },

    getFile(id) {
      this.getFileAction({
        id,
        deviceID: localStorage.getItem('deviceID'),
      });
    },

    update() {
      this.updateMessagesAction({
        deviceID: localStorage.getItem('deviceID'),
        info: navigator.userAgent,
      }).catch(() => {
        this.$notify.error(this.$t('loading_messages_error'));
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.dialogListScroll) {
          const $dialog = this.$refs.dialogListScroll;

          $dialog.$el.scrollTop = $dialog.$el.scrollHeight;
        }
      });
    },

    convertDate(date, time) {
      const timeArray = time.split(':');

      return `${date} ${timeArray[0]}:${timeArray[1]}`;
    },

    setChatHeight() {
      this.$nextTick(() => {
        if (this.$refs.dialogListScroll) {
          const $modal = this.$parent.$refs.modal;
          const $modalHeader = this.$parent.$refs.modalHeader;
          const $modalMessageForm = this.$refs.modalMessageForm;
          const $chat = this.$refs.dialogListScroll;
          const height = $modal.clientHeight - ($modalHeader.clientHeight + $modalMessageForm.clientHeight);

          $chat.$el.style = `max-height: ${height}px`;
        }
      });
    },

    onCallOperator() {
      const getNodesHistoryMessage =  this.getNodesHistoryMessage();

      this.form.message = getNodesHistoryMessage ? 
        getNodesHistoryMessage 
        : 'Позвать оператора';
      this.addMessage(true);
      this.changeSupportHelpTreeProcces = false;
      this.onClearSelectedSupportHelpNodes();
    },

    onStartChangeSupportHelpTreeProcces() {
      this.changeSupportHelpTreeProcces = true;
    },

    onEndHelp() {
      this.onClearSelectedSupportHelpNodes();
      this.$emit('close-chat');
    },

    onSelectSupportHelpNode(index) {
      const selectedNode = this.currentSupportHelpTree[index];
      
      if (!selectedNode) return;

      this.selectedNodeIsConclusion = Boolean(selectedNode?.IsConclusion);
      this.selectedSupportHelpNodeNames.push(selectedNode.Name);
      this.currentSupportHelpTree = selectedNode?.Childrens || [];
      this.selectedSupportHelpNodes.push({...selectedNode, Added: moment().format('HH:mm:ss')});
    },

    onClearSelectedSupportHelpNodes(one = false) {
      if (one) {
        this.selectedSupportHelpNodes.pop();
        this.selectedSupportHelpNodeNames.pop();
        
        let findedCurrentSupportHelpTree = cloneDeep(this.supportHelpTree);

        for (let i = 0; i < this.selectedSupportHelpNodeNames.length; i++) {
          const currentNodeName = this.selectedSupportHelpNodeNames[i];
          const nestedNodes = findedCurrentSupportHelpTree;
          const findedNode = nestedNodes.find(
            (node) => node.Name === currentNodeName
          );

          this.selectedNodeIsConclusion = Boolean(findedNode?.IsConclusion);
          findedCurrentSupportHelpTree = findedNode?.Childrens || [];
        }

        this.currentSupportHelpTree = findedCurrentSupportHelpTree;
      } else {
        this.selectedSupportHelpNodes = [];
        this.selectedSupportHelpNodeNames = [];
        this.selectedNodeIsConclusion = false;
        this.currentSupportHelpTree = cloneDeep(this.supportHelpTree);
      }
      
    },

    getNodesHistoryMessage() {
      let message = '';
      const selectedNodesLength = this.selectedSupportHelpNodes.length;

      if (!selectedNodesLength) return message;

      for (let i = 0; i < this.selectedSupportHelpNodes.length; i++) {
        const currentNode = this.selectedSupportHelpNodes[i];

        if (currentNode?.Name) message += `[Пользователь]: ${currentNode.Name}`;
        if (currentNode?.Conclusion) message += ` [Бот]: ${currentNode.Conclusion}`;
        if (i + 1 < this.selectedSupportHelpNodes.length) message += ' => ';
        else message += ' => [Пользователь]: позвать оператора';
      }

      return message;
    }
  },
};
</script>

<style lang="sass" rel="stylesheets/sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.chat
  display: flex
  height: 100%
  flex:
    direction: column
    basis: 100%

  .message
    +Size(90%, auto)
    max-width: 400px
    background-color: $cElmLight
    border-radius: 16px 16px 16px 4px
    padding: 12px

    @media screen and (min-width: 992px)
      padding: 16px

  .message__header
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 0 10px

    @media screen and (min-width: 992px)
      margin-bottom: 14px

  .message__name
    +TextSubMain()
    margin: 0

  .message__date
    +TextMini()
    flex-shrink: 0

  .message__text
    +TextMain()
    margin-bottom: 0

    &--file
      +TextMain($cAccentBlue)
      display: inline-flex
      align-items: center

      &:hover
        text-decoration: underline
        cursor: pointer

  .message__download-icon
    margin-right: 8px

.chat__content
  flex:
    shrink: 1
    basis: 100%
  display: flex;
  flex-direction: column;
  justify-content: space-between;

.chat__wrapper
  padding: 12px 16px

  @media screen and (min-width: 992px)
    padding: 12px 24px

.chat__message
  display: flex
  margin: 0 0 16px

  @media screen and (min-width: 992px)
    margin-bottom: 24px

  &--user
    justify-content: flex-end

    .message
      border-radius: 16px 16px 4px 16px
      background-color: $cBgSuccess

.chat__form
  +Transition((opacity))
  flex-shrink: 0
  display: flex
  justify-content: space-between
  align-items: center
  background: $cBgSec
  border-top: 1px solid $cBorder
  padding: 16px
  &--disabled
    opacity: 0.5

.chat__attach-icon
  +Size(24px)
  +Transition((color))
  flex-shrink: 0
  color: $cTextDisabled
  cursor: pointer
  margin-right: 12px

  &:hover, 
  &:focus
    color: $cTextMain

  &--disabled, 
  &--disabled:hover,
  &--disabled:focus 
    color: $cTextDisabled
    cursor: not-allowed

  @media screen and (min-width: 992px)
    margin-right: 17px

.chat__input
  +Size(100%, $sHug)
  flex-shrink: 1
  background-color: $cBgMain
  border: 1px solid $cBorder
  border-radius: 16px
  padding: 18px 16px
  &:disabled 
    cursor: not-allowed
    
.chat__send-button
  +ButtonIcon()
  flex-shrink: 0
  margin-left: 12px

  @media screen and (min-width: 992px)
    margin-left: 17px

.chat__send-icon
  transform: rotate(45deg)

.chat__help-tree 
  display: flex
  justify-content: flex-end
  flex-wrap: wrap
  gap: 10px
  padding: 12px 24px

.chat__help-bth
  display: flex
  justify-content: center
  align-items: center
  gap: 5px
  padding: 5px 10px
  border: none
  border-radius: 50px
  color: white
  background-color: #0094E6
  &:hover 
    background-color: #267ABF
  &:disabled
    opacity: 0.8
    cursor: not-allowed
  
</style>
