<template>
  <div class="error-page">
    <span class="error-page__code">404</span>

    <div class="error-page__content">
      <h1 class="error-page__title">{{ $t('page_does_not_exist') }}</h1>

      <router-link :to="{ name: 'Home' }" class="error-page__link">{{ $t('return_to_account') }}</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'notFound',
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.error-page
  display: flex
  flex-direction: column
  justify-content: space-around
  align-items: center
  height: 100%

.error-page__code
  +Typography(120px, 700, 200px, $cError)

  @media screen and (max-width: 767px)
    font-size: 72px
    line-hegiht: 120px

.error-page__title
  +TextHeader(0)
  text-align: center
  margin-bottom: 24px

  @media screen and (min-width: 768px)
    margin-bottom: 72px

.error-page__link
  +Button('text')
</style>
