<template>
  <div class="user-cart-page">
    <page-header :back="{
      title: $t('back'),
      to: previousRouteParams,
    }" />

    <section class="user-cart-page__header">
      <div class="user-cart-page__header-wrap">
        <breadcrumbs
          :items="currentBreadcrumbs"
          class="user-cart-page__breadcrumbs"
        />

        <h1 class="user-cart-page__title">{{ $t('placing_order') }}</h1>
      </div>
    </section>

    <div class="user-cart-page__container">
      <section class="user-cart-page__column user-cart-page__column--left">
        <div class="user-cart-page__goods-list">
          <cart-item
            v-for="good in goods"
            :key="`good-${good.ID}`"
            :good="good"
          />
        </div>
      </section>

      <section
        id="cart"
        class="user-cart-page__column user-cart-page__column--right"
      >
        <div class="amount-card">
          <ul class="prices">
            <li
              v-if="isGoodsType"
              class="prices__field"
            >
              <label class="prices__title">{{ $t('weight') }}:</label>
              <span class="prices__value">{{ cartWeight }} кг</span>
            </li>
            <li class="prices__field">
              <label class="prices__title">{{ $t('cost') }} {{ typeShopText }}:</label>
              <span class="prices__value">{{ cartCost }} ₽</span>
            </li>
            <!-- <li class="prices__field">
              <label class="prices__title">Общая стоимость:</label>
              <span class="prices__value">{{ cartCost }} ₽</span>
            </li> -->
          </ul>

          <form-box-select
            v-model="selectedIdent"
            :label="$t('personal_account')"
            :list="uniqueAccounts"
            valuePropName="Ident"
            keyPropName="AccountID"
            :maxShowOptions="4"
            class="amount-card__account-selector"
          >
            <!-- @input="setForm" -->
            <template #selectedOption="{ item }"> ({{ $t('short_personal_account') }}: {{ item.Ident }}) {{ item.Address
            }} </template>
            <template #option="{ item }"> {{ item.Ident }} ({{ item.AccountType }}) {{ item.Address }} </template>
          </form-box-select>

          <div class="amount-card__buttons">
            <base-button
              v-loading="isLoading"
              :disabled="cartIsEmpty"
              class="amount-card__button"
              @click="onPay"
            >
              {{ $t('confirm_order') }}
            </base-button>

            <base-button
              style-class="secondary-text"
              class="amount-card__continue"
              @click="$router.push(previousRouteParams)"
            >
              {{ $t('continue_shopping') }}
            </base-button>
          </div>
        </div>
      </section>
    </div>

    <modal
      name="on-pay"
      :addaptive="true"
      height="auto"
      :scrollable="true"
    >
      <modal-wrapper
        :title="$t('select_address')"
        @close="$modal.hide('on-pay')"
      >
        <div class="user-cart-page__account-selector">
          <form-box-select
            v-model="selectedIdent"
            :label="$t('address')"
            :list="uniqueAccounts"
            valuePropName="Ident"
            keyPropName="AccountID"
          >
            <template #selectedOption="{ item }">
              {{ item.Address ? item.Address : item.AccountType }}
            </template>
            <template #option="{ item }">
              {{ item.Address ? item.Address : item.AccountType }}
            </template>
          </form-box-select>
        </div>
        <div class="user-cart-page__modal-buttons">
          <base-button
            style-class="additional"
            class="user-cart-page__modal-button"
            @click="$modal.hide('on-pay')"
          >
            {{ $t('cancel') }}
          </base-button>
          <base-button
            class="user-cart-page__modal-button"
            :disabled="!selectedIdent"
            @click="onPay"
          >
            {{ $t('continue') }}
          </base-button>
        </div>
      </modal-wrapper>
    </modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import cartMixin from '@/mixins/cart';
import additionalServiceMixin from '@/mixins/additionalService';

import CartItem from '@/components/pages/services/CartItem';
import PageHeader from '@/components/pages/common/PageHeader';
import Breadcrumbs from '@/components/ui/Breadcrumbs';
import BaseButton from '@/components/ui/BaseButton';
import ModalWrapper from '@/components/ui/Modal';
import FormBoxSelect from '@/components/form/FormBoxSelect';

export default {
  name: 'UserCart',

  components: {
    Breadcrumbs,
    CartItem,
    FormBoxSelect,
    BaseButton,
    PageHeader,
    ModalWrapper,
  },

  mixins: [additionalServiceMixin, cartMixin],

  props: {
    breadcrumbs: Array,
  },

  data() {
    return {
      accountInfoIDs: null,
      selectedIdent: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapState(['additionalService', 'account']),

    currentBreadcrumbs() {
      if (this.$route.params.breadcrumbs) {
        return this.$route.params.breadcrumbs;
      }
      if (!this.additionalService.servicesByGroups) {
        return [];
      }
      let serviceBreadcrumb = this.defineServiceBreadcrumb();
      return [
        serviceBreadcrumb,
        {
          text: this.getShopName(),
          route: {
            name: 'Shop',
            params: {
              ...this.$route.params,
              breadcrumbs: [serviceBreadcrumb, { text: this.getShopName(), route: '' }],
            },
          },
        },
        { text: 'Оформление заказа', route: '' },
      ];
    },

    previousRouteParams() {
      if (this.$route.params.breadcrumbs) {
        let index = this.$route.params.breadcrumbs.length - 2;
        return this.$route.params.breadcrumbs[index].route;
      }

      return {
        name: 'Shop',
        params: this.$route.params,
      };
    },

    accountInfo() {
      if (!this.accountInfoIDs) {
        return null;
      }
      return this.accountInfoIDs.map((ident) => this.account.info[ident] || {});
    },
    uniqueAccounts() {
      if (!this.accountInfoIDs) {
        return null;
      }
      return this.accountInfo
        .filter((x, i, self) => {
          return self.findIndex((t) => t.Address == x.Address) === i;
        })
        .sort((a, b) => a.Ident - b.Ident);
    },
    goods() {
      return this.additionalService.goods.filter((item) => {
        if (this.selectedCategory) {
          return item.Categories.includes(this.selectedCategory);
        }
        return true;
      });
    },
    wordEnding() {
      switch (this.goods.length % 10) {
        case 1:
          return '';
        case 2:
        case 3:
        case 4:
          return 'а';
        default:
          return 'ов';
      }
    },
  },

  mounted() {
    this.getLocalStorageCart();
    this.getAccounts()
      .then((result) => {
        this.accountInfoIDs = [...result.keys];
      })
      .then(() => {
        if (this.accountInfo.length > 0 && this.accountInfo[0].Bills.length > 0) {
          this.ident = this.accountInfo[0].Bills[0].Ident;
        }
      });

    if (!this.additionalService.servicesByGroups) {
      this.getEventBlockData().then(this.getShopName);
    }
  },

  methods: {
    ...mapActions({
      addMessage: 'request/addMessage',
      getLocalStorageCart: 'additionalService/getLocalStorageCart',
      getAccounts: 'account/getInfo',
      newRequest: 'request/newShopRequest',
    }),

    onPay() {
      if (this.selectedIdent) {
        this.isLoading = true;
        const sourceCost = this.additionalService.goods.reduce((prevValue, currentValue) => {
          return prevValue + currentValue.Quantity * currentValue.Price;
        }, 0);

        let goods = this.additionalService.goods
          .filter((item) => {
            return item.Quantity > 0;
          })
          .map((item) => {
            let Amount = item.Quantity * item.Price;
            if (this.cartCost > sourceCost && this.isGorserv) {
              Amount = ((Amount / sourceCost) * 1000).toFixed(0);
            }
            return {
              ID: null,
              GoodID: item.ID,
              Name: item.Name,
              Price: item.Price,
              Quantity: item.Quantity,
              Amount,
              BonusAmount: 0,
            };
          });

        let request = {
          AdditionalServiceId: +this.$route.params.AdditionalServiceId,
          Ident: this.selectedIdent,
          TypeID: +this.$route.params.id_RequestType,
          ShopId: +this.$route.params.id,
          IsPaid: true,
          SubTypeID: null,
          PaidSum: +this.cartCost,
          Text: `${this.currentBreadcrumbs[0].text} - ${this.currentBreadcrumbs[1].text} на сумму: ${+this
            .cartCost} руб.`,
          PaidServiceText: 'Покупка в магазине',
          ReceiptItems: goods,
        };

        this.newRequest(request)
          .then(this.pushMessage)
          .then((requestId) => {
            this.$router.push({
              name: 'Requests',
              params: { requestID: requestId },
            });
          });
      } else {
        if (this.isMobileView()) {
          this.$modal.show('on-pay');
          return;
        }

        this.openDialog({
          title: this.$t('error'),
          text: this.$t('please_select_personal_account'),
          buttons: [
            {
              type: 'base',
              title: this.$t('fine'),
              handler: () => {
                this.cancelDialog();
              },
            },
          ],
        });
      }
    },
    async pushMessage(response) {
      const sourceCost = this.additionalService.goods.reduce((prevValue, currentValue) => {
        return prevValue + currentValue.Quantity * currentValue.Price;
      }, 0);

      let message = this.additionalService.goods
        .filter((item) => {
          return item.Quantity > 0;
        })
        .map((item, index) => {
          let Amount = item.Quantity * item.Price;
          if (this.cartCost > sourceCost && this.isGorserv) {
            Amount = ((Amount / sourceCost) * 1000).toFixed(0);
          }
          return `${index + 1}) ${item.Name} кол-во: ${item.Quantity} ${item.Units != null ? `${item.Units}` : ''
            }.\nЦена: ${Amount} руб.`;
        });

      const totalMessage = `Ваш заказ: \n${message.join(`\r\n`)}\n
        Итого цена: ${this.cartCost} руб. 
        ${this.isGoodsType ? this.cartWeight + ' кг.' : ''}`;

      await this.addMessage({
        RequestId: response.id,
        Text: totalMessage,
      });
      return response.id;
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.user-cart-page
  .amount-card
    display: flex
    justify-content: space-between
    align-items: center

    background: $cBgMain
    box-shadow: none
    border-top: 1px solid $cBorder
    border-radius: 0
    padding: 16px

    @media screen and (min-width: 992px)
      flex-direction: column
      padding: 24px
      box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
      border: none
      border-radius: 16px

    .prices
      margin-bottom: 0

      @media screen and (min-width: 992px)
        width: 100%
        margin-bottom: 16px

    .prices__field
      display: flex
      flex-direction: column
      flex-wrap: wrap
      justify-content: space-between

      &:not(:last-child)
        margin-bottom: 8px

      @media screen and (min-width: 768px)
        flex-direction: row
        justify-content: flex-start

      @media screen and (min-width: 992px)
        justify-content: space-between

    .prices__title
      +TextHeader(2)

      white-space: nowrap

      @media screen and (min-width: 768px)
        margin-right: 8px

      @media screen and (min-width: 992px)
        margin-right: 0

    .prices__value
      +TextHeader(2)

    .prices__sub
      +TextMain()

      display: inline-block
      width: 100%
      margin-top: 8px
      margin-bottom: 0

  .amount-card__account-selector
    display: none

    @media screen and (min-width: 992px)
      display: block
      width: 100%

  .amount-card__buttons
    display: flex
    flex-direction: column

    @media screen and (min-width: 768px)
      flex-direction: row
      align-items: center

    @media screen and (min-width: 992px)
      display: block
      width: 100%

  .amount-card__button
    width: auto

    @media screen and (min-width: 768px)
      flex-shrink: 0

    @media screen and (min-width: 992px)
      width: 100%

  .amount-card__continue
    width: 100%
    margin-top: 8px

    @media screen and (min-width: 768px)
      margin-top: 0
      margin-left: 16px

    @media screen and (min-width: 992px)
      margin-top: 16px
      margin-left: 0

.user-cart-page__header-wrap
  width: 100%
  max-width: 600px
  display: flex
  flex-direction: column
  justify-content: space-between

  @media screen and (min-width: 1200px)
    max-width: 640px

  @media screen and (min-width: 1400px)
    max-width: 730px

.user-cart-page__header
  width: 100%
  margin-bottom: 16px

.user-cart-page__title
  +TextHeader(0)
  margin-bottom: 0

.user-cart-page__breadcrumbs
  margin-bottom: 8px

  @media screen and (min-width: 768px)
    margin-bottom: 24px

.user-cart-page__container
  display: flex
  flex-wrap: wrap
  flex-direction: column-reverse

  @media screen and (min-width: 992px)
    flex-wrap: nowrap
    flex-direction: row

.user-cart-page__column
  &--left
    width: 100%
    max-width: 600px
    flex-shrink: 0

    @media screen and (min-width: 1200px)
      max-width: 640px

    @media screen and (min-width: 1400px)
      max-width: 730px

  &--right
    flex-shrink: 1
    width: 100%
    min-width: 0
    padding-left: 0
    position: fixed
    left: 0
    right: 0
    bottom: 0
    z-index: 10

    @media screen and (min-width: 992px)
      padding-left: 24px
      position: static

.user-cart-page__modal-buttons
  display: flex

.user-cart-page__modal-button
  width: 100%

  &:not(:last-child)
    margin-right: 16px

    @media screen and (min-width: 768px)
      margin-right: 24px
</style>
