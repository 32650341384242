<template>
  <div class="profile-page">
    <page-header :title="$t('profile')" />

    <section class="form-box">
      <form class="form-box__form" @submit.prevent="save">
        <div class="form-box__card">
          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">{{ $t('info') }}</legend>

            <form-box-input
              v-model="form.fio"
              :label="$t('fio')"
              :horizontal="true"
              :edit-button="true"
              :message="errors.fio"
              @form-box:save="validation"
            />

            <form-box-input
              v-model="form.email"
              label="E-mail"
              :horizontal="true"
              :edit-button="true"
              :message="errors.email"
              @form-box:save="validation"
            />
          </fieldset>

          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">{{ $t('personal_accounts') }}</legend>

            <accounts-list v-if="activeAccounts.length" />

            <div class="form-box__field">
              <div class="field field--horizontal">
                <base-button
                  style-class="text-mini"
                  class="field__button field__button--add-account"
                  @click="openAddAccountIdentModal"
                >
                  <plus-icon />
                  {{ $t('connect_personal_account') }}
                </base-button>
              </div>
            </div>
          </fieldset>

          <fieldset 
            v-if="closedAccounts.length" 
            class="form-box__fieldset"
          >
            <legend class="form-box__fieldset-title">Закрытые лицевые счета</legend>

            <accounts-list closed />
          </fieldset>

          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">{{ $t('settings') }}</legend>
            <div class="form-box__field">
              <div class="field field--horizontal">
                <div class="field__group">
                  <div class="field__control">
                    <label class="field__label field__label--full-width">{{ $t('avoid_paper_bills') }}</label>
                  </div>
                  <toggler v-model="form.onlyElectronicBills" @input="validation" />
                </div>
                <p v-show="errors.onlyElectronicBills" class="field__message">
                  {{ errors.onlyElectronicBills }}
                </p>
              </div>
            </div>
            <div class="form-box__field">
              <div class="field field--horizontal">
                <div class="field__group">
                  <div class="field__control">
                    <label class="field__label field__label--full-width">{{ $t('dont_save_password') }}</label>
                  </div>
                  <toggler v-model="form.dontSavePassword" @input="validation" />
                </div>
                <p v-show="errors.dontSavePassword" class="field__message">
                  {{ errors.dontSavePassword }}
                </p>
              </div>
            </div>
            <div class="form-box__field">
              <div class="field field--horizontal">
                <div class="field__group">
                  <div class="field__control">
                    <label class="field__label field__label--full-width">{{ $t('dont_show_retired_devices') }}</label>
                  </div>
                  <toggler v-model="form.dontShowRetiredDevices" @input="validation" />
                </div>
                <p v-show="errors.dontShowRetiredDevices" class="field__message">
                  {{ errors.dontShowRetiredDevices }}
                </p>
              </div>
            </div>
          </fieldset>

          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">{{ $t('language') }}</legend>
            <form-box-radioset
              v-model="form.language"
              :list="[
                { title: 'русский', value: 'ru' },
                { title: 'english', value: 'en' },
              ]"
              :classModificators="['language']"
            />
          </fieldset>

          <fieldset class="form-box__fieldset form-box__fieldset--buttons">
            <base-button style-class="text-mini" modificator="error" class="form-box__button" @click="removeUser">
              <trash-icon class="form-box__button-icon" />
              {{ $t('delete_account') }}
            </base-button>
            <base-button style-class="secondary-text-mini" class="form-box__button" @click="logout">
              <logout-icon class="form-box__button-icon" />
              {{ $t('log_out_of_account') }}
            </base-button>
          </fieldset>
        </div>
        <!-- /.form-box__card -->

        <base-button type="submit" width="200px" :disabled="!isValidate" class="form-box__save">
          {{ $t('save') }}
        </base-button>
      </form>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PageHeader from '@/components/pages/common/PageHeader';
import FormBoxInput from '@/components/form/FormBoxInput';
import FormBoxRadioset from "@/components/form/FormBoxRadioset.vue";
import BaseButton from '@/components/ui/BaseButton';
import Toggler from '@/components/ui/Toggler';
import { TrashIcon, LogoutIcon, PlusIcon } from '@vue-hero-icons/outline';
import AccountsList from '@/components/pages/profile/AccountsList.vue';

export default {
  name: 'Profile',

  components: {
    PageHeader,
    FormBoxInput,
    FormBoxRadioset,
    BaseButton,
    Toggler,
    TrashIcon,
    LogoutIcon,
    PlusIcon,
    AccountsList,
  },

  data() {
    return {
      form: {
        email: '',
        fio: '',
        onlyElectronicBills: false,
        dontSavePassword: false, 
        dontShowRetiredDevices: false,
        language: '',
      },
      errors: {
        email: '',
        fio: '',
        onlyElectronicBills: '',
        dontSavePassword: '',
        dontShowRetiredDevices: '',
        language: '',
      },
      saveBankCard: false,
      dontSavePassword: true,
      showSuccessAlert: false,
      showDangerAlert: false,
      initialLanguage: 'ru',
    };
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo',
    }),

    accounts() {
      let accounts = this.getAllInfo;

      if (this.isDgservice) {
        accounts = this.getAllInfo.filter(
          (account) => {
            if (/^\d+$/.test(account.Ident)) return true;
          }
        );
      }

      return accounts;
    },

    isValidate() {
      return !this.errors.fio && !this.errors.email && !this.errors.onlyElectronicBills;
    },

    activeAccounts() {
      const accounts = this.accounts || [];

      return accounts.filter(account => !account.IsClosed);
    },

    closedAccounts() {
      const accounts = this.accounts || [];

      return accounts.filter(account => account.IsClosed);
    }
  },

  created() {
    this.getInfo();
  },

  mounted() {
    this.$root.$on('updated-account-info', this.getAllInfo);

    this.cloneData();
    this.validation();
  },

  destroyed() {
    this.$root.$off('updated-account-info');
  },

  watch: {
    authUser() {
      this.cloneData();
    },
  },

  methods: {
    ...mapActions({
      updateProfile: 'account/updateProfile',
      deleteAccountByIdent: 'account/deleteAccountByIdent',
      getInfo: 'account/getInfo',
      deleteMobileUser: 'account/deleteMobileUser',
      logoutAction: 'account/logout',
    }),

    cloneData() {
      this.form = {
        email: this.authUser.email,
        fio: this.authUser.fio,
        onlyElectronicBills: !!this.authUser.onlyElectronicBills,
        dontSavePassword: !!this.authUser.dontSavePassword,
        dontShowRetiredDevices: !!this.authUser.dontShowRetiredDevices,
        language: localStorage.getItem('language') ? localStorage.getItem('language') : 'ru',
      };

      this.initialLanguage = this.form.language;
    },

    save() {
      if (this.isValidate) {
        this.updateProfile(this.form)
          .then((result) => {
            if (result.isSucceed) {
              if (this.initialLanguage !== this.form.language) {
                this.initialLanguage = this.form.language;

                localStorage.setItem('language', this.form.language);
                this.$i18n.locale = this.form.language;
              }

              this.$notify.success(this.$t('data_updated_successfully'));
            }
          })
          .catch((error) => {
            this.$notify.error(error);
          });
      } else {
        this.$notify.error(this.$t('error_updating_profile'));
      }
    },

    logout() {
      this.logoutAction()
        .then(() => {
          this.$router.push({ name: 'Login' });
        })
        .catch((error) => {
          this.$notify.error(error);
        });
    },

    removeAccount(id) {
      const account = this.accounts.find((account) => account.Id === id);

      this.openDialog({
        title: 'Удаление лицевого счета',
        text: `Вы уверены, что хотите удалить лицевой счет №${account.Ident} (${account.Address})?`,
        buttons: [
          {
            type: 'additional',
            title: 'Отмена',
            handler: () => {
              this.cancelDialog();
            },
          },
          {
            type: 'base',
            title: 'Подтвердить',
            handler: () => {
              this.deleteAccountByIdent(account.Ident)
                .then((result) => {
                  if (result && result.error) {
                    this.$notify.error(result.error);
                    return;
                  }

                  this.$notify.success(`Лицевой счет №${account.Ident} удален.`);
                })
                .catch((error) => {
                  this.$notify.error(error);
                });

              this.cancelDialog();
            },
          },
        ],
      });
    },

    removeUser() {
      this.openDialog({
        title: this.$t('deleting_account'),
        text: this.$t('sure_delete_account'),
        buttons: [
          {
            type: 'additional',
            title: this.$t('cancel'),
            handler: () => {
              this.cancelDialog();
            },
          },
          {
            type: 'base',
            title: this.$t('confirm'),
            handler: () => {
              this.deleteMobileUser()
                .then((result) => {
                  if (result.isSucceed) {
                    this.$notify.success(this.$t('account_deleted'));
                    this.logout();
                  }
                })
                .catch((error) => {
                  console.error(error);
                  this.$notify.error(error);
                });

              this.cancelDialog();
            },
          },
        ],
      });
    },

    testEmail() {
      /* eslint-disable */
      const regexp = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
      /* eslint-enable */
      return regexp.test(this.form.email);
    },

    validation() {
      // console.log('--- validation method ---');

      this.errors.fio = '';
      this.errors.email = '';
      this.errors.onlyElectronicBills = '';
      this.errors.dontSavePassword = '';
      this.errors.dontShowRetiredDevices = '';
      this.errors.language = '';

      if (!this.form.fio) {
        this.errors.fio = this.$t('field_required');
      }

      if (this.form.email && !this.testEmail()) {
        this.errors.email = this.$t('wrong_format');
      }

      if (this.form.onlyElectronicBills && !this.form.email) {
        this.errors.email = this.$t('field_required');
        this.errors.onlyElectronicBills = `${this.$t('fill_field')} E-mail`;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'
@import '../sass/components/form/form'
@import '../sass/components/form/input'

.profile-page
  .form-box
    +ContentContainer()

  .form-box__card
    margin-bottom: 24px

  .form-box__fieldset--buttons
    flex-direction: column
    justify-content: flex-end
    padding: 16px

    @media screen and (min-width: 768px)
      flex-direction: row
      padding: 16px 24px

  .form-box__field:last-of-type
      margin-bottom: 0

  .form-box__button
    margin-right: 0
    margin-bottom: 16px

    &:last-child
      margin-right: 0
      margin-bottom: 0

    @media screen and (min-width: 768px)
      margin-right: 24px
      margin-bottom: 0 !important

  .form-box__button-icon
    +Size(16px)
    flex-shrink: 0
    margin-right: 4px

  .form-box__alert
    margin-bottom: 24px

  .form-box__save
    margin-left: auto

  .field__label
    &--main
      color: $cTextMain

      span
        color: $cLabel

    &--full-width
      max-width: 100%

  .field__button
    flex-shrink: 0

    &--add-account
      width: 100%
      margin-left: 0

      @media screen and (min-width: 768px)
        width: auto

  ::v-deep .field__message
    padding: 0
    color: $cError
</style>
