<template>
  <modal title="Оплата" class="modal--request pay-modal" @close="$emit('close')"> Здесь форма </modal>
</template>

<script>
import Modal from '@/components/ui/Modal';

export default {
  name: 'PrivilegeClubAmoModal',

  components: {
    Modal,
  },

  props: {
    fields: {
      type: Array,
      reqiered: true,
    },
  },
};
</script>
