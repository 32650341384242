import * as authService from '../services/auth.service';
import router from '../router';
import store from '../store/index';

const promiseGetMobileAppSettings = store.dispatch('config/getMobileAppSettings');
/**
 * Redirect to home page
 */

/**
 * Check access permission to auth routes
 */
export function checkAccessMiddleware(to, from, next) {
  const isAuthRoute = to.matched.some((item) => item.meta.isAuth === true);

  if (isAuthRoute && !authService.isAuthUser()) {
    return next({ name: 'Login' });
  }

  return next();
}

export function redirectToHomeMiddleware(to, from, next) {
  if (authService.isAuthUser() && to.path === '/') {
    let availableRoutes = router.options.routes.filter((route) => {
      return route.meta.isAuth === true;
    });

    console.log('availableRoutes:', availableRoutes);

    if (availableRoutes.length > 0) {
      return next({ name: 'Payment' });
    }
  }

  return next();
}

export async function checkMobileSettingsMiddleware(to, from, next) {
  await promiseGetMobileAppSettings;
  const menuVisibilitySettings = store.getters['config/getMenuVisibilitySettings'];

  if (typeof menuVisibilitySettings[to.name] === 'boolean' && !menuVisibilitySettings[to.name]) {
    if (from.name == 'Home') {
      return next(false);
    } else {
      return next({ name: 'Home' });
    }
  }

  return next();
}

export function setPageTitleMiddleware(to, from, next) {
  const pageTitle = to.matched.find((item) => item.meta.title);

  if (pageTitle) window.document.title = pageTitle.meta.title;
  next();
}

export function preloaderStart(_, __, next) {
  // const $preloader = document.querySelector('.app-preloader');

  // $preloader.style = 'display: flex';
  // $preloader.style = 'opacity: 1';

  next();
}

export function preloaderStop() {
  const $preloader = document.querySelector('.app-preloader');

  setTimeout(() => {
    $preloader.style = 'opacity: 0';
    setTimeout(() => {
      $preloader.style = 'display: none';
    }, 300);
  }, 1000);
}
