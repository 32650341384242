function clearPhone(source) {
  if (!source) {
    return '';
  }

  let result = '';

  for (let i = 0; i < source.length; i++) {
    let char = source[i];
    if (char >= '0' && char <= '9') {
      result = result + char;
    }
  }

  if (result.length > 0 && result[0] === '8') {
    result = '7' + result.substr(1);
  }

  if (result.length > 0) {
    result = '+' + result;
  }

  return result;
}

export default {
  computed: {
    config() {
      const defaultBaseConfig = {
        color: '00A0E3',
        main_name: '',
      };

      const config = this.$store.getters['config/getMobileAppSettings'];

      return {
        ...defaultBaseConfig,
        ...config,
        clearPhone: clearPhone(config.phone),
        logoLink() {
          var result = window.appSettings.logoLink;
          if (result && result.length > 0) {
            return result;
          }
          return undefined;
        },
        startScreen: this.$store.getters['config/getStartView'],
      };
    },

    isRostovZKH() {
      // ГУП РО "ИВЦ ЖКХ" Ростовская обл.
      return window.appSettings.apiUrl.split('/').includes('rostov-zkh');
    },

    isGranel() {
      // TODO: Временно убираем у Гранеля Сбербанк на фронте
      return window.appSettings.apiUrl.split('/').includes('komfortnew');
    },

    isDgservice() {
      return window.appSettings.apiUrl.split('/').includes('dgservicnew');
    },

    isNdrcamera() {
      return window.appSettings.apiUrl.split('/').includes('ndrcamera');
    }
  },

  methods: {
    resolveUrl(url) {
      return window.appSettings.virtualPath + url;
    },
  },
};
