<template>
  <div class="log-register">
    <div class="log-register__header">
      <h2 class="log-register__title">{{ content.title }}</h2>
    </div>

    <div class="log-register__form">
      <div class="form-box">
        <form v-loading="isLoading" class="form-box__form" @submit.prevent="onLogin">
          <!-- Login -->
          <form-box-input
            refName="loginControl"
            :label="$t('telephone')"
            type="tel"
            placeholder="+7 (999) 999 99 99"
            autocomplete="username"
            :message="errors.login ? errors.login : ''"
            :focused="loginIsFocus"
            :hasError="errors.form || errors.login"
            v-mask="`+7 (###) ### ## ##`"
            v-model="form.login"
          />

          <!-- Password -->
          <form-box-input
            refName="passwordControl"
            :label="$t('password')"
            type="password"
            autocomplete="current-password"
            :disableBrowserPasswordMemorization="authUser?.dontSavePassword"
            :message="errors.password ? errors.password : ''"
            :focused="passwordIsFocus"
            :hasError="errors.form || errors.password"
            v-model="form.password"
          >
          </form-box-input>

          <base-button
            role="link"
            tabindex="0"
            style-class="text"
            class="form-box__button form-box__button--forgot-password"
            @click="showPopover = true"
            @clickout="showPopover = false"
            @focus="showPopover = true"
            @blur="showPopover = false"
          >
            <span class="underline">{{ $t('forgot_password') }}</span>
            <div v-if="showPopover" class="form-box__popover">
              <popover
                :closeIcon="false"
                :allowCloseByClickout="true"
                :bodyText="$t('to_recover_password')"
              />
            </div>
          </base-button>

          <base-button type="submit" tabindex="0" class="form-box__button" :disabled="isDisabled">{{ $t('enter') }}</base-button>

          <base-button
            tabindex="0"
            style-class="text"
            class="form-box__button"
            @click="$router.push({ name: 'Register' })"
          >
            {{ $t('register') }}
          </base-button>
        </form>
      </div>
    </div>

    <p class="log-register__note">
      {{ content.note }}
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

import FormBoxInput from '@/components/form/FormBoxInput';
import Popover from '@/components/ui/Popover';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'Login',

  components: {
    FormBoxInput,
    Popover,
    BaseButton,
  },

  data() {
    return {
      isLoading: false,
      form: {
        login: '',
        password: '',
      },
      content: {
        title: this.$t('login_to_account'),
        // eslint-disable-next-line max-len
        note: this.$t('register_using_phone_number'),
      },
      errors: {
        form: false,
      },
      loginIsFocus: false,
      passwordIsFocus: false,
      error: '',
      showPopover: false,
    };
  },

  computed: {
    isDisabled() {
      return this.form.login.length <= 17 || this.form.password.length === 0 || this.errors.length > 0;
    },
  },

  methods: {
    ...mapActions({
      login: 'account/login',
    }),

    onLogin() {
      // console.log('--- login methods ---');
      this.isLoading = true;

      if (this.isValidate()) {
        this.error = '';
        this.errors['form'] = false;

        this.login({
          phone: this.form.login.match(/(\d+)/g).join(''),
          password: this.form.password,
        })
          .then(() => {
            this.$router.push({ name: this.config.startScreen });
          })
          .catch((error) => {
            this.errors['form'] = true;

            if (error.response?.status === 401) {
              this.error = this.$t('incorrect_login_password_pair');

              this.$notify.error({
                position: 'top-left',
                title: this.$t('error'),
                message: this.$t('incorrect_login_password_pair'),
              });
            } else {
              this.error = this.$t('error') + '.';
              this.$notify.error({
                position: 'top-left',
                title: this.$t('error'),
                message: '',
              });
            }

            this.isLoading = false;
          });
      }
    },

    isValidate() {
      if (this.form.login.length === 0) {
        this.errors['login'] = this.$t('telephone_required');
        return false;
      }

      if (this.form.password.length === 0) {
        this.errors['password'] = this.$t('password_required');
        return false;
      }

      return true;
    },
  },

  mounted() {
    if (this.$route.query.phone && this.$route.query.phone.length === 10) {
      this.form.login = this.$route.query.phone;
      this.passwordIsFocus = true;
    } else {
      this.loginIsFocus = true;
    }
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"
@import "../sass/pages/login-register"
@import "../sass/components/form/form"

.form-box__button--forgot-password
  position: relative
  z-index: 2

  .underline
    position: relative

    &::after
      +Size(100%, 0)
      display: block
      border-bottom: 1px dashed $cAccent

      position: absolute
      left: 0
      bottom: 0

      content: ''
</style>
