<template>
  <div class="open-poll-layout layout--page" ref="layout">
    <main ref="main" class="page">
      <div class="page__container">
        <slot />
      </div>
    </main>
    <footer-block ref="footer" />
  </div>
</template>

<script>
import FooterBlock from '@/layouts/Footer';

export default {
  name: 'OpenPollLayout',

  components: {
    FooterBlock,
  },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    loading(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$root.$emit('page-loading', newValue);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"

.page
  +Size(100%)

  display: flex
  max-width: $sMWContainer
  flex-grow: 1
.page__container
  +PageLayoutContainer(false)
  flex-grow: 1

.open-poll-layout .page__container
  padding-top: 30px
</style>
