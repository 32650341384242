<template>
  <div class="home-page">
    <div v-loading="!accounts">
      <template v-if="accounts">
        <template v-if="accounts.length">
          <div v-loading="isLoading">
            <!-- Уведомления -->
            <notifications-section v-if="!isLoading && !authUser.accessOSS" :items="announcements"
              class="home-page__section" />

            <checkup-notifications v-if="config?.allowMeterVerificationRequest" class="home-page__section" />

            <!-- TODO: Собрания -->

            <!-- Клуб привелегий -->
            <privilege-club-section v-if="!isLoading && showPrivilegeClubSection" class="home-page__section" />

            <!-- Новости -->
            <news-section v-if="!isLoading && !authUser.accessOSS" :items="news" class="home-page__section" />

            <!-- Опросы -->
            <polls-section v-if="!isLoading && getMenuVisibilitySettings.Polls" :items="polls"
              class="home-page__section" />

            <!-- Специальные предложения -->
            <!-- <special-offers-section
              :items="additionalServices"
              class="home-page__section"
            /> -->
            <!-- <alert 
              v-if="!announcements && !news"
              type="warning"
            >
              Нет событий
            </alert> -->
          </div>
        </template>

        <section v-else>
          <add-account-ident :text="$t('be_aware_of')" />
        </section>
      </template>
    </div>
  </div>
</template>

<script>
//vuex
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';
import clientTimezone from '@/mixins/clientTimezone';

import NotificationsSection from '@/components/pages/home/NotificationsSection';
import NewsSection from '@/components/pages/home/NewsSection';
import PollsSection from '@/components/pages/home/PollsSection';
// import SpecialOffersSection from '@/components/pages/home/SpecialOffersSection';
import AddAccountIdent from '@/components/pages/common/AddAccountIdent';
// import Alert from '@/components/ui/Alert';
import PrivilegeClubSection from "@/components/pages/home/PrivilegeClubSection";
import CheckupNotifications from "@/components/pages/home/CheckupNotifications.vue";

export default {
  name: "Home",

  components: {
    NotificationsSection,
    NewsSection,
    PollsSection,
    // SpecialOffersSection,
    AddAccountIdent,
    // Alert,
    PrivilegeClubSection,
    CheckupNotifications
  },

  mixins: [clientTimezone],

  data() {
    return {
      newsIDs: [],
      ossIDs: [],
      announcementIDs: [],
      additionalServiceIDs: [],
      isLoading: true,
      isLoadingAccounts: true,
      isLoadingOss: false,
    };
  },

  computed: {
    ...mapState({
      emergencyWorks: ({ emergencyWorks }) => emergencyWorks.list
    }),

    ...mapGetters({
      getMenuVisibilitySettings: 'config/getMenuVisibilitySettings',
      polls: 'polls/getNonPassedPolls',
      getNewsList: 'news/getNewsList',
      getOss: 'oss/getOss',
      getAnnouncements: 'announcement/getAnnouncements',
      getServices: 'additionalService/getServices',
      accounts: 'account/getAccounts',
      user: 'account/getUser',
    }),

    news() {
      return this.getNewsList(this.newsIDs);
    },
    oss() {
      return this.getOss(this.ossIDs);
    },

    announcements() {
      const emergencyAnnouncements = this.emergencyWorks.map((work) => {
        if (work && work.AccountIds && Array.isArray(work.AccountIds)) {
          const addresses = this.accounts
            .filter((account) => work.AccountIds.includes(account.Id))
            .map((account) => account.Address);

          const notMatchingAddresses = [...new Set(addresses)];

          if (notMatchingAddresses.length > 0) {
            const header = `
          Аварийные работы по адрес${(notMatchingAddresses.length > 1) ? 'у' : 'ам'}
          ${notMatchingAddresses.join(', ')}
          с ${this.formatDate(work.DateStart)} 
          по ${this.formatDate(work.DateEnd)}!`;

            return {
              ...work,
              Header: header,
              Text: work.Description,
              IsEmergencyWork: true
            };
          }
        }

        return null;
      });

      const additionalAnnouncements = (this.announcementIDs.length > 0)
        ? this.getAnnouncements(this.announcementIDs)
        : [];

      const allAnnouncements = [
        ...emergencyAnnouncements,
        ...additionalAnnouncements
      ];

      const filteredAnnouncements = allAnnouncements.filter((announcement) => !!announcement);

      return filteredAnnouncements;
    },


    additionalServices() {
      return this.getServices(this.additionalServiceIDs);
    },

    showPrivilegeClubSection() {
      const account = this.user?.accounts.find((account) => account.houseId === 1361);

      return account;
    },
  },

  // ? FIXME: Зачем это тут?
  // watch: {
  //   announcementIDs(newValue) {
  //     if (newValue.length > 0) {
  //       localStorage.setItem("announcementIDs", JSON.stringify(newValue));
  //     }
  //   },
  //   newsIDs(newValue) {
  //     if (newValue.length > 0) {
  //       localStorage.setItem("newsIDs", JSON.stringify(newValue));
  //     }
  //   },
  // },

  created() {
    // console.log('--- Home component page ---')
    // console.log('--- created hook ---')
    this.getAccountInfo();
    this.setEventBlockData();
    this.getOSS();
    this.getEmergencyWorks();

    this.$root.$on('updated-account-info', this.onAccountInfoChanged);
    this.$root.$on('deleted-account-info', this.onAccountInfoChanged);
  },

  destroyed() {
    this.$root.$off('updated-account-info');
    this.$root.$off('deleted-account-info');
  },

  methods: {
    ...mapActions({
      getUserInfo: 'account/getUserInfo',
      getEventBlockData: 'news/getEventBlockData',
      getEmergencyWorks: 'emergencyWorks/getList'
    }),

    onAccountInfoChanged() {
      this.getAccountInfo();
      this.getOSS();
    },

    getAccountInfo() {
      this.isLoadingAccounts = true;

      this.getUserInfo()
        .finally(() => {
          this.isLoadingAccounts = false;
        });
    },

    // TODO: Have to remove
    // getCacheData() {
    //   let announcementIDs = localStorage.getItem("announcementIDs");

    //   this.announcementIDs =
    //     announcementIDs
    //       ? JSON.parse(announcementIDs)
    //       : this.announcementIDs;

    //   let newsIDs = localStorage.getItem("newsIDs");
    //   this.newsIDs = newsIDs !== null ? JSON.parse(newsIDs) : this.newsIDs;
    // },

    setEventBlockData() {
      this.isLoading = true;

      this.getEventBlockData()
        .then((result) => {
          if (result) {
            this.newsIDs = result.news.keys;
            this.announcementIDs = result.announcements.keys;
            this.additionalServiceIDs = result.services.keys;

            this.isLoading = false;
          }
        })
        .catch((error) => {
          throw error;
        });
    },

    getOSS() {
      this.isLoadingOss = true;
      this.$store
        .dispatch('oss/getOSS')
        .then((result) => {
          this.ossIDs = result.keys;
          this.isLoadingOss = false;
        })
        .catch(() => {
          this.$nextTick(() => {
            this.isLoadingOss = false;
          });
        });
    },

    formatDate(date) {
      return moment(date, 'YYYY-MM-DDTHH:mm:ss')
        .set(
          'hour',
          moment(date, 'YYYY-MM-DDTHH:mm:ss').hour() - this.clientTimeZone,
        )
        .format('DD.MM.YYYY HH:mm');
    }
  },
};
</script>

<style lang="sass" scoped>
.home-page
  padding: 24px 0 0

  @media screen and (min-width: 768px)
    padding: calc(40px - 12px) 0 0

.home-page__section
  margin-bottom: 20px
</style>
