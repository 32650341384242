<template>
  <perfect-scrollbar v-if="items" tag="div" class="polls-list" ref="scrollSection">
    <article
      v-for="(item, i) in items"
      :key="`poll-${i}`"
      class="poll-card"
      :class="{ 'poll-card--active': item.ID == $route.params.id }"
    >
      <h3 class="poll-card__title">{{ item.Name }}</h3>

      <!-- <p class="poll-card__subtitle"></p> -->

      <!-- Если опрос пройден -->
      <template v-if="(item.Passed || item.PartiallyPassed) && !item.HideResults">
        <span v-if="item.ID == $route.params.id" class="poll-card__label">{{ $t('viewing_answers') }}</span>

        <router-link
          v-else
          :to="{
            name: 'Polls',
            params: {
              id: item.ID,
              subpage: 'results',
            },
          }"
          class="poll-card__link"
        >
          {{ $t('show_answers') }}
          <arrow-right-icon size="12" class="poll-card__link-icon" />
        </router-link>
      </template>

      <!-- Если опрос не пройден или частично пройден -->
      <template v-else-if="!item.Passed">
        <span v-if="item.ID == $route.params.id" class="poll-card__label">{{ $t('you_are_passing_now') }}</span>

        <router-link
          v-else
          :to="{
            name: 'Polls',
            params: {
              id: item.ID,
              subpage: 'form',
            },
          }"
          class="poll-card__link poll-card__link--go"
        >
          {{ item.PartiallyPassed ? $t('continue_passing') : $t('pass_poll') }}
          <arrow-right-icon size="12" class="poll-card__link-icon" />
        </router-link>
      </template>
    </article>
  </perfect-scrollbar>
</template>

<script>
import { mapGetters } from 'vuex';
import { ArrowRightIcon } from '@vue-hero-icons/outline';

export default {
  name: 'PollsList',

  components: {
    ArrowRightIcon,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      detailPassedPoll: 'polls/getDetailPoll',
    }),
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/mixins';

.polls-list {
  height: 100%;
  padding-right: 0;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    padding-right: 12px;
  }
  @media screen and (min-width: map-get($bp, 'tablet-big')) {
    padding-right: 16px;
  }
}
.poll-card {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  width: 100%;
  padding: 24px;
  background-color: $cWhite;
  border: 1px solid $cBorder;
  border-radius: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &--active {
    background-color: $cElmLight;
    border: none;
  }
}
.poll-card__title {
  @include TextHeader(2);

  width: 100%;
  margin-bottom: 26px;
}
.poll-card__label {
  @include TextMini();
}
.poll-card__link {
  @include TextButtonMini();

  &--go {
    color: $cAccent;

    .poll-card__link-icon {
      color: $cAccent;
    }
  }
}
.poll-card__link-icon {
  margin-left: 6px;
}
</style>
