import * as configApi from '../../api/config';

const state = {
  mobileAppSettings: {},
};

const getters = {
  getMobileAppSettings(state) {
    return state.mobileAppSettings;
  },
  getMenuVisibilitySettings(state) {
    if (!state.mobileAppSettings?.menu) {
      // default
      return {
        Requests: false,
        Payment: false,
        Meters: false,
        Polls: false,
        Bills: false,
        Services: false,
      };
    }
    return {
      Requests: !!state.mobileAppSettings.menu.find((x) => x.name_app === 'Заявки' && x.visible),

      Payment: !!state.mobileAppSettings.menu.find((x) => x.name_app === 'Оплата ЖКУ' && x.visible),
      Meters: !!state.mobileAppSettings.menu.find(
        (x) => ['Показания счетчиков', 'Показания приборов'].includes(x.name_app) && x.visible,
      ),
      Polls: !!state.mobileAppSettings.menu.find((x) => x.name_app === 'Опросы' && x.visible),
      Bills: !!state.mobileAppSettings.menu.find((x) => x.name_app === 'Квитанции' && x.visible),
      Services: state.mobileAppSettings.showOurService,
    };
  },
  getDisableCommentingRequests(state) {
    if (!state.mobileAppSettings) {
      // default
      return false;
    }

    return !!state.mobileAppSettings.disableCommentingRequests;
  },

  getStartView({ mobileAppSettings }) {
    if (mobileAppSettings.startScreen === 'События') {
      return 'Home';
    }

    if (mobileAppSettings.startScreen === 'Оплата') {
      return 'Payment';
    }

    if (mobileAppSettings.startScreen === 'Показания') {
      return 'Meters';
    }

    if (mobileAppSettings.startScreen === 'Наши услуги') {
      return 'Services';
    }

    if (mobileAppSettings.startScreen === 'Главная') {
      return 'Home';
    }

    if (mobileAppSettings.startScreen === 'Заявки') {
      return 'Requests';
    }

    return 'Home';
  },
};

const mutations = {
  SET_MOBILE_APP_SETTINGS(state, data) {
    state.mobileAppSettings = {
      ...data,
    };
  },
};
const actions = {
  async getMobileAppSettings({ commit }) {
    return await configApi
      .getMobileAppSettings()
      .then((response) => {
        if (response.status === 200) {
          commit('SET_MOBILE_APP_SETTINGS', response.data);
        }

        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  async checkAppLogo(_, url) {
    return configApi
      .checkAppLogo(url)
      .then((response) => {
        return !!(response.status === 200);
      })
      .catch((error) => {
        throw error;
      });
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
