<template>
  <section class="page-header">
    <slot name="top-content">
      <router-link
        v-if="back"
        :to="back.to"
        :class="['page-header__back-link', { 'page-header__back-link--no-title': !title }]"
      >
        <arrow-left-icon size="16" class="page-header__back-icon" />
        {{ back.title }}
      </router-link>
    </slot>

    <slot name="left-content">
      <h1 v-if="title" class="page-header__title">
        {{ title }}
      </h1>
    </slot>

    <slot name="right-content" />
  </section>
</template>

<script>
import { ArrowLeftIcon } from '@vue-hero-icons/outline';

export default {
  name: 'PageHeader',

  components: {
    ArrowLeftIcon,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },

    back: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
};
</script>

<style lang="sass">
@import "../../../sass/variables"
@import "../../../sass/mixins"

.page-header
  +Size(100%, auto)
  display: flex
  justify-content: space-between
  align-items: center
  flex-wrap: wrap
  padding: 24px 0

  @media screen and (min-width: 768px)
    padding: 40px 0

.page-header__back-link
  +Transition((color))
  +TextMini($cAccentBlue)

  font-weight: 600

  display: inline-flex
  align-items: center

  &:not(&--no-title)
    margin-bottom: 22px

  &:hover
    color: $cAccent
    text-decoration: none

.page-header__back-icon
  margin-right: 4px

.page-header__title
  +TextHeader(1)
  margin-bottom: 0
</style>
