/**
 * import and init global mixins
 */

import Vue from 'vue';

import currentUser from '../mixins/currentUser';
import config from '../mixins/config';
import modals from '../mixins/modals';
import utils from '../mixins/utils';
import detectScreenMixin from '@/mixins/detectScreen';
import resizeThrottler from './resizeThrottler';
// import form from "./form"

Vue.mixin(currentUser);
Vue.mixin(config);
Vue.mixin(modals);
Vue.mixin(utils);
Vue.mixin(detectScreenMixin);
Vue.mixin(resizeThrottler);
// Vue.mixin(form)
