export default {
  created() {
    this.resizeThrottlerFunctionList.push(this.isTabletView);
  },

  computed: {
    isAndroidPhone() {
      // return /Android/i.test(navigator.userAgent)
      return (
        (navigator.userAgentData && navigator.userAgentData.mobile && navigator.userAgentData.platform === 'Android') ||
        /Android/i.test(navigator.userAgent)
      );
    },

    isApplePhone() {
      // return (/iPhone|iPad|iPod/i.test(navigator.userAgent));
      return (
        (navigator.userAgentData &&
          navigator.userAgentData.mobile &&
          ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.userAgentData.platform,
          )) ||
        /iPhone|iPad|iPod/i.test(navigator.userAgent)
      );
    },
  },

  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },

    isMobileView() {
      return window.matchMedia('screen and (max-width: 991px)').matches;
    },

    isTabletView() {
      return window.matchMedia('screen and (max-width: 1199px)').matches;
    },

    isCustomView(size) {
      return window.matchMedia(`screen and (max-width: ${size}px)`).matches;
    },
  },
};
