<template>
  <section v-if="isLoadingMessages" class="request-chat">
    <h2 class="text-center">{{ $t('request') }} №{{ info.RequestNumber }}</h2>

    <!-- Messages -->
    <perfect-scrollbar ref="chatContainer" class="request-chat__chat-wrapper">
      <div v-for="(messagesArray, date) in messages" :key="`list-date-${date}`" class="request-chat__chat">
        <time class="request-chat__date">- {{ date }} -</time>

        <div
          v-for="message in messagesArray"
          :key="`message-${message.ID}`"
          :class="['request-chat__message', `${message.IsSelf ? 'request-chat__message--user' : 'operator'}`]"
        >
          <article class="message">
            <div class="message__header">
              <p class="message__name">{{ message.AuthorName }}</p>
              <time class="message__date">{{ message.Added }}</time>
            </div>

            <div class="message__body">
              <a v-if="message.FileID > 0" class="message__text--file" @click="onGetFile(message.FileID)">
                <download-icon class="message__download-icon" />
                {{ message.Text }}
              </a>
              <pre v-else class="message__text">
                {{ message.Text }}
              </pre>
            </div>
          </article>
        </div>
      </div>
    </perfect-scrollbar>

    <div v-if="!info.IsClosed" class="request-chat__pane">
      <base-button
        v-if="canCancelingRequest"
        style-class="additional"
        class="request-chat__button"
        @click="cancelRequest"
      >
        Отменить заявку
      </base-button>

      <base-button
        v-if="info.StatusID === 5"
        style-class="additional"
        class="request-chat__button"
        @click="openReopenOpenRequestModal(accessCode, info.RequestNumber)"
      >
        {{ $t('reopen') }}
      </base-button>

      <base-button v-if="info.CanClose && info.StatusID === 5" class="request-chat__button" @click="closeRequest">
        {{ $t('confirm') }}
      </base-button>
    </div>

    <!-- Form -->
    <form
      v-if="!config.disableCommentingRequests && !info.IsClosed && info.StatusID !== 5"
      v-show="accessCode"
      v-on:submit.prevent
      class="request-chat__form"
    >
      <!-- Attach -->
      <form-box-file class="request-chat__attach" @input="onAddFile">
        <paper-clip-icon class="request-chat__attach-icon" />
      </form-box-file>

      <input
        v-model="form.message"
        type="text"
        :placeholder="$t('message')"
        :disabled="isSendingMessage"
        class="request-chat__input"
        @keyup.enter="onAddMessage"
      />

      <button
        v-loading="isSendingMessage"
        type="submit"
        :title="$t('send')"
        :disabled="form.message.length === 0"
        class="request-chat__send"
        @click="onAddMessage"
      >
        <paper-airplane-icon class="request-chat__send-icon" />
      </button>
    </form>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PaperClipIcon, PaperAirplaneIcon, DownloadIcon } from '@vue-hero-icons/outline';
import download from 'downloadjs';
import FormBoxFile from '@/components/form/FormBoxFile';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'RequestChatPage',

  components: {
    FormBoxFile,
    PaperClipIcon,
    PaperAirplaneIcon,
    DownloadIcon,
    BaseButton,
  },

  data() {
    return {
      accessCode: null,
      info: null,
      messageIDs: null,
      templateMessageFiles: [],
      isLoadingMessages: false,
      isSendingMessage: false,
      form: {
        message: '',
        file: null,
      },
      timer: null,
    };
  },

  computed: {
    ...mapGetters({
      getMessages: 'openRequest/getMessages',
    }),

    messages() {
      if (this.isLoadingMessages) {
        let messages = this.getMessages(this.messageIDs);

        return messages.reduce((newEl, item) => {
          let date = item.Added.split(' ');

          if (!Array.isArray(newEl[date[0]])) {
            newEl[date[0]] = [];
          }

          newEl[date[0]].push({
            ...item,
            Added: date[1],
          });

          return newEl;
        }, {});
      }

      return null;
    },

    canCancelingRequest() {
      return (this.info.CanCancelingRequest && this.info.StatusID === 1);
    },
  },

  created() {
    this.accessCode = this.$route.params.code;
    this.getRequest();

    this.$root.$on('requests:update', this.getRequest);
    this.$root.$on('requests:sent-message', this.getRequest);
    this.$root.$on('requests:closed-request', this.getRequest);
  },

  mounted() {
    const target = this;
    this.timer = setInterval(() => {
      target.update();
    }, 30000);
  },

  updated() {
    this.scrollToBottom();
  },

  destroyed() {
    this.$root.$off('requests:update');
    this.$root.$off('requests:sent-message');
    this.$root.$off('requests:closed-request');

    clearInterval(this.timer);
  },

  methods: {
    ...mapActions({
      getRequestDetails: 'openRequest/getRequestDetails',
      addMessage: 'openRequest/addMessage',
      getFile: 'openRequest/getFile',
      addFile: 'openRequest/addFile',
      cancelRequestAction: 'openRequest/cancelRequest',
      refreshRequest: 'openRequest/update',
    }),

    getRequest() {
      this.isLoadingMessages = false;

      this.getRequestDetails(this.accessCode)
        .then((result) => {
          if (result) {
            this.messageIDs = result.Messages;
            this.templateMessageFiles = [...result.Files];
            this.info = {
              ...result,
              CanClose: !result.IsClosed,
            };
            this.isLoadingMessages = true;
            this.scrollToBottom();
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    update() {
      this.refreshRequest(this.accessCode)
        .then((result) => {
          if (result && result.current) {
            this.$root.$emit('requests:update');
          }
      });
    },

    onGetFile(fileID) {
      this.getFile({
        AccessCode: this.accessCode,
        FileID: fileID,
      }).then((response) => {
        const file = this.templateMessageFiles.find((el) => el.ID === fileID);

        if (file.Name) {
          download(response.data, file.Name, response.headers['content-type']);
        } else {
          this.$notify.error(this.$t('file_name_error'));
        }
      });
    },

    onAddMessage() {
      if (this.form.message.length > 0) {
        this.isSendingMessage = true;

        this.addMessage({
          AccessCode: this.accessCode,
          Text: this.form.message,
        }).then(() => {
          this.form.message = '';
          this.$root.$emit('requests:sent-message');
        })
        .finally(() => {
          this.isSendingMessage = false;
        });
      }
    },

    onAddFile(files) {
      let formData = new FormData();
      formData.append('accessCode', this.accessCode);
      formData.append('file', files[0]);

      this.addFile(formData)
        .then(this.getRequest)
        .catch(() => {
          this.$notify.error(this.$t('file_download_error'));
        });
    },

    /**
     * Закрытие выполненной (или нет) заявки
     */
    closeRequest() {
      this.closeOpenRequestModal(this.accessCode, this.info.RequestNumber);
    },

    /**
     * Отмена (невыполненной) заявки
     */
    cancelRequest() {
      this.openDialog({
        title: 'Отмена заявки',
        text: `Вы уверены, что ходите отменить заявку №${this.info.RequestNumber}`,
        buttons: [
          {
            type: 'additional',
            title: 'Отмена',
            handler: () => {
              this.cancelDialog();
            },
          },
          {
            type: 'base',
            title: 'Подтвердить',
            handler: () => {
              this.$modal.hide('dialog');
              this.cancelRequestAction({
                AccessCode: this.accessCode,
              })
                .then((response) => {
                  if (response.Error) {
                    this.$notify.error({
                      position: 'top-right',
                      title: 'Ошибка',
                      message: response.Error,
                    });
                  } else {
                    this.$notify.success({
                      position: 'top-left',
                      title: 'Успех',
                      message: `Заявка №${this.info.RequestNumber} отменена.`,
                    });

                    this.getRequest();
                  }
                })
                .catch((error) => {
                  throw error;
                });
            },
          },
        ],
      });
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.chatContainer) {
          const $container = this.$refs.chatContainer;
          $container.$el.scrollTop = $container.$el.scrollHeight;
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.request-chat
  +Size(100%)
  display: flex
  flex:
    direction: column
    basis: 100%

  .message
    +Size(90%, auto)
    max-width: 400px
    background-color: $cElmLight
    border-radius: 16px 16px 16px 4px
    padding: 12px

    @media screen and (min-width: 992px)
      padding: 16px

  .message__header
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 0 10px

    @media screen and (min-width: 992px)
      margin-bottom: 14px

  .message__name
    +TextSubMain()
    margin: 0

  .message__date
    +TextMini()
    flex-shrink: 0

  .message__text
    +TextMain()
    white-space: pre-line
    word-wrap: break-word
    overflow: visible
    margin-bottom: 0

    &--file
      +TextMain($cAccentBlue)
      display: inline-flex
      align-items: center
      word-break: break-word; /* Chrome, Safari */
      word-wrap: break-word; /* IE11, Firefox */
      overflow-wrap: break-word;
			
      &:hover
        text-decoration: underline
        cursor: pointer

  .message__download-icon
    margin-right: 8px
    flex-shrink: 0

.request-chat__chat-wrapper
  flex:
    shrink: 1
    basis: 100%
  padding: 0 24px

  @media screen and (min-width: 1200px)
    padding: 0 60px

.request-chat__date
  +Size(100%, auto)
  +TextMini()
  text-align: center
  display: inline-block
  margin-bottom: 16px

.request-chat__message
  display: flex
  margin: 0 0 16px

  @media screen and (min-width: 992px)
    margin-bottom: 24px

  &--user
    justify-content: flex-end

    .message
      background-color: $cBgSuccess

.request-chat__pane
  flex-shrink: 0
  display: flex
  justify-content: space-between
  align-items: center
  padding: 12px 24px

  @media screen and (min-width: 1200px)
    padding: 16px 60px

.request-chat__button
  width: 100%
  margin: 0 calc(16px / 2)

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0

.request-chat__form
  flex-shrink: 0
  display: flex
  justify-content: space-between
  align-items: center
  background: $cBgSec
  border-top: 1px solid $cBorder
  padding: 16px 24px

  @media screen and (min-width: 1200px)
    padding: 16px 60px

.request-chat__attach:last-of-type
  margin-bottom: 0

.request-chat__attach-icon
  +Size(24px)
  +Transition((color))
  flex-shrink: 0
  color: $cIconNeutral
  cursor: pointer
  margin-right: 12px

  &:hover, &:focus
    color: $cTextMain

  @media screen and (min-width: 992px)
    margin-right: 17px

.request-chat__input
  +Size(100%, $sHug)
  flex-shrink: 1
  background-color: $cBgMain
  border: 1px solid $cBorder
  border-radius: 16px
  padding: 18px 16px

.request-chat__send
  +ButtonIcon()
  flex-shrink: 0
  margin-left: 12px

  @media screen and (min-width: 992px)
    margin-left: 17px

.request-chat__send-icon
  transform: rotate(45deg)
  color: $cIconNeutral
</style>
