import { normalizeData } from '../../services/common';
import * as requestApi from '@/api/openRequest';

const state = {
  messages: {},
  updateKey: null,
};

const actions = {
  getRequestDetails({ commit }, accessCode) {
    return requestApi
      .getRequestDetails(accessCode)
      .then((response) => {
        let data = response.data;
        commit('setUpdateKey', data.updateKey);

        if (!data.request) return null;

        let result = normalizeData(data.request?.Messages || [], 'ID');
        commit('setMessages', result.list);

        return {
          ...data.request,
          Messages: result.keys,
        };
      })
      .catch((error) => {
        throw error;
      });
  },

  addMessage(context, { AccessCode, Text }) {
    return requestApi
      .addMessage({ AccessCode, Text })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getFile(context, { AccessCode, FileID }) {
    return requestApi
      .getFile({ AccessCode, FileID })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  addFile(context, formData) {
    return requestApi
      .addFile(formData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  },

  closeRequest(context, { AccessCode, Text, Mark }) {
    return requestApi
      .closeRequest({ AccessCode, Text, Mark })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  cancelRequest(context, { AccessCode }) {
    return requestApi
      .cancelRequest({ AccessCode })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  update({ getters, commit }, accessCode) {
    const updateKey = getters['getUpdateKey']();
    if (!updateKey) {
      return null;
    }
    return (
      requestApi
        .getUpdates({
          UpdateKey: updateKey,
          AccessCode: accessCode,
        })
        .then((response) => {
          return response.data;
        })
        .then((result) => {
          if (result.NewUpdateKey) {
            commit('setUpdateKey', result.NewUpdateKey);
          }

          return {
            current: result.CurrentRequestUpdates,
          };
        })
        .catch((error) => {
          throw error;
        })
    );
  },
};

const getters = {
  getMessages: ({ messages }) => (list) => list.map((id) => messages[id] || {}),
  getUpdateKey: ({ updateKey }) => () => updateKey,
};

const mutations = {
  setMessages(state, data) {
    state.messages = {
      ...state.messages,
      ...data,
    };
  },

  setUpdateKey(state, data) {
    state.updateKey = data;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};

