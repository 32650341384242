<template>
  <modal
    :title="$t('payment')"
    class="modal--ident pay-modal"
    @close="$emit('close')"
  >
    <div class="form-box">
      <form
        v-loading="isSending"
        class="form-box__form pay-modal__form"
        @submit.prevent
      >
        <!-- ЛС -->
        <form-box-select
          v-model="form.invoiceUid"
          :label="$t('invoice')"
          :list="invoices"
          valuePropName="InvoiceUid"
          keyPropName="InvoiceUid"
          @input="setForm($event)"
        >
          <template #selectedOption="{ item }">{{ item.DisplayName }}</template>

          <template #option="{ item }">
            {{ item.DisplayName }} ({{ item.Debt }} ₽)
          </template>
        </form-box-select>

        <!-- Сумма к оплате (Sum) -->
        <form-box-input
          v-model="sum"
          type="amount"
          :label="`${$t('payment_amount')} (₽)`"
          :message="!validate ? $t('invalid_amount') : ''"
          readonly
        />

        <!-- Пени (SumFine) -->
        <form-box-input
          v-if="config.useSeparatedFinePayment"
          v-model="sumFine"
          type="amount"
          :label="`${$t('penalty_incl')} (₽)`"
          :readonly="!allowEditSum"
          :min="0"
        />

        <!-- Save card  -->
        <form-box-checkbox
          v-if="showSavwCardCheckbox"
          v-model="form.allowCardSaving"
          :label="$t('save_card')"
          refName="saveCardControl"
          inputId="save-card"
          :classModificators="['pay-modal']"
        />

        <alert
          v-if="showSberPaymentSystemAlert"
          type="danger"
          critical
          class="pay-modal__alert"
        >
          {{ $t('when_paying_through_sber') }}
        </alert>

        <!-- Payment system -->

        <template v-if="showPaymentSystemChanger">
          <form-box-radioset
            v-model="form.paymentSystem"
            :label="$t('payment_system')"
            :list="paymentSystemsRadiosetList"
            :message="$t('select_payment_system')"
            class="pay-modal__payment-system"
            :classModificators="['pay-modal']"
            @change="handleChangePaymentSystem"
          >
            <template #label="{ item }">
              <div class="payment-system">
                <img
                  class="payment-system__icon"
                  :src="getPaymentSystemImage(item.value)"
                />
                {{ item.title }}
              </div>
            </template>
          </form-box-radioset>
        </template>

        <!-- Totals -->
        <div class="pay-modal__totals">
          <p
            v-loading="commissionIsLoading"
            class="pay-modal__total pay-modal__total--sum"
          >
            {{ $t('total') }}:
            <strong>{{ totalSum }} ₽</strong>
          </p>

          <template v-if="!hideComissionInfo">
            <p
              v-if="commission > 0"
              class="pay-modal__total pay-modal__total--commission"
            >
              *{{ $t('commission') }}: {{ commission }} ₽
            </p>
            <p
              v-else
              class="pay-modal__total pay-modal__total--commission"
            >*{{ $t('no_commission_charged') }}</p>
          </template>
        </div>

        <base-button
          :disabled="!validate"
          class="pay-modal__button"
          @click="onPay"
        >{{ $t('pay') }}</base-button>
      </form>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import modal from '@/components/ui/Modal';
import * as commonApi from '@/api/common';
import FormBoxInput from '@/components/form/FormBoxInput';
import FormBoxSelect from '@/components/form/FormBoxSelect';
import FormBoxCheckbox from '@/components/form/FormBoxCheckbox';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';
import BaseButton from '@/components/ui/BaseButton';
import Alert from '@/components/ui/Alert';

export default {
  name: 'payModal',

  components: {
    modal,
    FormBoxInput,
    FormBoxSelect,
    FormBoxCheckbox,
    FormBoxRadioset,
    BaseButton,
    Alert,
  },

  props: {
    id: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      form: {
        invoiceUid: null,
        paymentSystem: null,
        allowCardSaving: false,
      },
      sum: 0,
      sumFine: 0,

      totalSum: 0,
      commission: 0,
      hideComissionInfo: true,
      commissionIsLoading: false,
      //accountInfoIDs: [],
      accountInfo: [],
      paymentSystems: [],
      isSending: false,
      isPaymentSystemSelectionMode: false,
      allowEditSum: true,
      sumControlIsFocus: false,
      showSberPaymentSystemAlert: false,

      invoices: [],
      showSavwCardCheckbox: true
    };
  },

  computed: {
    ...mapGetters({
      getAllInfo: 'account/getAllInfo',
    }),

    paymentSystemsRadiosetList() {
      return this.paymentSystems.map((item) => {
        return {
          title: item.displayName || item.name,
          value: item.name,
        };
      });
    },

    validate() {
      return this.form.invoiceUid;
    },

    // NOTE: Дмитрий говорит, что выбор должен быть, если ПС больше одной
    showPaymentSystemChanger() {
      return this.paymentSystems.length > 1
    }
  },

  mounted() {
    this.isSending = true;

    this.getInvoices(this.id)
      .then((result) => {
        this.invoices = result;

        if (this.invoices.length === 1) {
          this.form.invoiceUid = this.invoices[0].InvoiceUid
          this.setForm(this.form.invoiceUid)
        }
      });

    this.getPaymentSystems(this.id)
      .then((result) => {
        this.paymentSystems = result;

        if (this.paymentSystems) {
          this.form.paymentSystem = this.paymentSystems[0].name;
        } else {
          this.form.paymentSystem = 'Tinkoff'
        }

        this.handleChangePaymentSystem(this.form.paymentSystem);

        this.isSending = false;
      })
  },

  watch: {
    // TODO: Возможно это не нужно. Достаточно будет одного вычисления я setForm.
    // На уточнении
    sumFine(val) {
      if (this.config.useSeparatedFinePayment) {
        this.sum = parseFloat((+this.sum + +val)).toFixed(2);
        this.getCommission();
      }
    },
  },

  methods: {
    ...mapActions({
      getSumWithCommission: 'account/getSumWithCommission',
      disableAccountInsurance: 'account/disableAccountInsurance',
      getPaymentSystems: 'account/getPaymentSystems',
      getPayLink: 'account/getPayLink',
      getInvoices: 'account/getInvoices',
      getPayInvoiceLink: 'account/getPayInvoiceLink',
      getPaymentParameters: 'account/getPaymentParameters',
    }),

    setForm(e) {
      console.log('--- setForm ---');

      this.allowEditSum = false;

      const selectedInvoice = this.invoices.find((item) => item.InvoiceUid === e);

      this.form.paymentSystem = this.form ? this.form.paymentSystem : null;

      this.form.allowCardSaving = false;

      if (this.config.useSeparatedFinePayment) {
        this.sumFine = selectedInvoice.DebtFine
        this.sum = parseFloat((selectedInvoice.Debt + selectedInvoice.DebtFine).toFixed(2));
      } else {
        this.sum = selectedInvoice.Debt;
      }

      this.getCommission();
    },

    // Оплатить
    onPay() {
      if (this.form.invoiceUid) {
        this.isSending = true;

        this.getPayInvoiceLink(this.form)
          .then((result) => {
            this.isPaymentSystemSelectionMode = false;

            const isResultPayLink = result.payLink && result.payLink.length > 0;
            if (!isResultPayLink) return;
            if (!this.authUser.email) {
              this.$root.$emit("show-email-for-pay-modal", result.payLink);
              this.$emit('close');
            } else {
              location.href = result.payLink;
            }

            this.isSending = false;
          })
          .catch(() => {
            this.isSending = false;
            this.isPaymentSystemSelectionMode = false;
          });
      }
    },

    getPaymentSystemImage(name) {
      return commonApi.getPaymentSystemImageDownloadLink(name);
    },

    getCommission() {
      if (!this.sum) {
        this.commission = 0;
        this.totalSum = 0;
        return;
      }

      this.commissionIsLoading = true;

      let sum = parseFloat(this.sum);

      this.getSumWithCommission({
        sum: sum,
        accountID: this.id,
      })
        .then((result) => {
          this.commission = result.Commission;
          this.totalSum = result.TotalSum;
          this.hideComissionInfo = result.HideComissionInfo;

          this.commissionIsLoading = false;
        })
    },

    handleChangePaymentSystem(e) {
      if (this.isGranel && e === 'Sber') {
        this.showSberPaymentSystemAlert = true;
        const newPaymentSystem = this.paymentSystemsRadiosetList.find(system => system.value !== e);
        this.form.paymentSystem = newPaymentSystem.value
        return
      }

      this.getPaymentParameters({
        accountId: this.id,
        paymentSystem: this.paymentSystem
      })
        .then((result) => {
          this.showSavwCardCheckbox = result.CanSaveCard
        })
    }
  },
};
</script>

<style
  lang="sass"
  scoped
>
@import '../../sass/variables'
@import '../../sass/mixins'
@import '../../sass/components/form/form'

.pay-modal__alert
  margin-bottom: 12px

.pay-modal
  .payment-system
    display: flex
    align-items: center

.pay-modal__form
  margin-bottom: 0

.pay-modal__insurance
  display: flex
  justify-content: space-between
  margin-bottom: 12px

.pay-modal__insurance-link
  +TextButtonMini()

.pay-modal__totals
  margin-bottom: 24px

.pay-modal__total
  +TextMain()

  &--commission
    +TextMini()

  &:not(:last-child)
    margin-bottom: 2px

  strong
    +TextHeader(2)
    display: inline-block
    margin-left: 8px

.pay-modal__button
  margin-bottom: 0
</style>
