export default {
  ru: {
    welcome_to: 'Приветствуем вас',
    homeowners_meeting: 'на собрании собственников жилья!',
    general_information: 'Общая информация',
    title_of_document: 'Наименование документа',
    details_confirming_ownership: 'Реквизиты, подтверждающие собственность',
    document_from: 'от',
    property_number: 'Номер собственности',
    area: 'Площадь',
    share: 'Доля',
    meeting_agenda: 'Повестка собрания',
    discussion_file: 'Файл для обсуждения',
    administrator_information: 'Сведения об администраторе',
    passport_details: 'Паспортные данные',
    place_of_permanent_residence: 'Место постоянного проживания',
    contact_phone_number: 'Контактный номер телефона',
    email_address: 'Адрес электронной почты',
    brand_name: 'Фирменное наименование',
    organizational_legal_form: 'Организационно-правовая форма',
    location: 'Место нахождения',
    mailing_address: 'Почтовый адрес',
    official_site: 'Официальный сайт',
    participate: 'Принять участие',
    voting_results: 'Результаты голосования',
    property_details: 'Сведения о собственности',
    total_area_of_premises: 'Общая площадь помещений',
    residential: 'Жилые',
    non_residential: 'Нежилые',
    procedure_for_making_decisions: 'Порядок приёма решений',
    electronic_samples: 'Электронные образцы',
    handwritten_solutions: 'Рукописные решения',
  },

  en: {
    welcome_to: 'Welcome to',
    homeowners_meeting: 'the homeowners meeting!',
    general_information: 'General information',
    title_of_document: 'Title of the document',
    details_confirming_ownership: 'Details confirming ownership',
    document_from: 'from',
    property_number: 'Property number',
    area: 'Area',
    share: 'Share',
    meeting_agenda: 'Meeting agenda',
    discussion_file: 'Discussion File',
    administrator_information: 'Administrator Information',
    passport_details: 'Passport details',
    place_of_permanent_residence: 'Place of permanent residence',
    contact_phone_number: 'Contact phone number',
    email_address: 'E-mail address',
    brand_name: 'Brand name',
    organizational_legal_form: 'Organizational and legal form',
    location: 'Location',
    mailing_address: 'Mailing address',
    official_site: 'Official site',
    participate: 'Participate',
    voting_results: 'Voting results',
    property_details: 'Property Details',
    total_area_of_premises: 'Total area of premises',
    residential: 'Residential',
    non_residential: 'Non-residential',
    procedure_for_making_decisions: 'Procedure for making decisions',
    electronic_samples: 'Electronic samples',
    handwritten_solutions: 'Handwritten solutions',
  },
}