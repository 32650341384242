<template>
  <div v-show="show" class="c-modal-backdrop vm--overlay" @click="onClickOutside">
    <div class="c-modal" @click.stop="">
      <button @click="$emit('close')" class="close popup-form_close" type="button">
        <span class="glipf-remove"></span>
      </button>
      <div class="popup-title" v-if="title">{{ title }}</div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'modalWrapper',

  props: {
    show: {
      type: Boolean,
      default: false,
    },

    title: {
      type: String,
      default: '',
    },

    clickOtsitde: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    show: {
      immediate: true,
      handler: (show) => {
        if (show) {
          document.body.style.setProperty('overflow', 'hidden');
        } else {
          document.body.style.removeProperty('overflow');
        }
      },
    },
  },

  methods: {
    onClickOutside() {
      if (this.clickOtsitde) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss">
// .c-modal-backdrop {
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   z-index: 999;

//   overflow: auto;

//   border-top: 1px solid var(--base-color);
//   border-bottom: 1px solid var(--base-color);
//   border-radius: 50px;
// }

// .c-modal {
//   position: relative;

//   max-width: 33.75rem;
//   padding: 2em 4em;

//   background-color: #fff;
// }
</style>
