<template>
  <div class="add-account">
    <div class="add-account__img">
      <img :src="resolveUrl('/img/icons/add-account-ident.svg')" />
    </div>
    <div class="add-account__content">
      <p class="add-account__text">
        {{ $t('you_dont_have_personal_accounts') }},<br />
        {{ $t('by_which_you_can') }} {{ text }}
      </p>
      <base-button style-class="text" class="add-account__button" @click="openAddAccountIdentModal">
        <plus-icon size="16" />
        {{ $t('connect_personal_account') }}
      </base-button>
    </div>
  </div>
</template>

<script>
import i18n from '@/plugins/i18n';
import { PlusIcon } from '@vue-hero-icons/outline';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'addAccountIdent',

  components: {
    PlusIcon,
    BaseButton,
  },

  props: {
    text: {
      type: String,
      required: false,
      default: i18n.t('carry_out_operations'),
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../../sass/variables"
@import "../../../sass/mixins"

.add-account
  display: inline-flex
  align-items: center

.add-account__img
  +ResponsiveImg(160px, 160px)

.add-account__content
  padding-left: 40px

.add-account__text
  // +TextMain($cText2)
  +TextMain()
  margin-bottom: 26px

.add-account__button
  width: 100%
  justify-content: flex-start
</style>
