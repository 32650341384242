import * as ossApi from '../../api/oss';
import { normalizeData } from '../../services/common';

const state = {
  oss: {},
};

const actions = {
  getOSS({ commit }) {
    return ossApi
      .getOSS()
      .then((response) => normalizeData(response.data.Data.reverse(), 'ID'))
      .then((result) => {
        commit('setOss', result.list);

        return result;
      })
      .catch((error) => {
        throw error;
      });
  },
  getOssById({ commit }, id) {
    if (!commit) {
      return;
    }
    return ossApi
      .getOSSByID(id)
      .then((response) => response.data)
      .catch((error) => {
        throw error;
      });
  },
  setAcquainted({ commit }, id) {
    if (!commit) {
      return;
    }
    return ossApi.setAcquainted(id).catch((error) => {
      throw error;
    });
  },
  startVote({ commit }, id) {
    if (!commit) {
      return;
    }
    return ossApi.startVote(id).catch((error) => {
      throw error;
    });
  },
  completeVote({ commit }, id) {
    if (!commit) {
      return;
    }
    return ossApi.completeVote(id).catch((error) => {
      throw error;
    });
  },
  saveAnswer({ commit }, ossId, questionId, answer) {
    if (!commit) {
      return;
    }

    return ossApi.saveAnswer(ossId, questionId, answer).catch((error) => {
      throw error;
    });
  },
};

const getters = {
  //getOssById: (state) => (id) => state.oss[id] || {},
  getOss: (state) => (list) => list.map((id) => state.oss[id] || {}),
  getOssByCompletion: (state) => (list, isCompleted) =>
    list
      .map((id) => state.oss[id] || {})
      .filter((x) => (isCompleted ? x.Status.startsWith('Завершено') : !x.Status.startsWith('Завершено'))) || {},
  getStateParameters: (state) => (oss) => {
    if (!state) {
      return;
    }

    if (!oss.Status) {
      return {
        glipf: '',
        statusClass: 'unpublished',
        listStatusClass: 'unpublished',
        statusText: oss.Status,
        canVote: false,
        canViewResults: false,
      };
    }

    if (oss.Status === 'Не опубликовано') {
      return {
        glipf: '',
        statusClass: 'unpublished',
        listStatusClass: 'unpublished',
        statusText: oss.Status,
        canVote: false,
        canViewResults: false,
      };
    } else if (oss.Status.startsWith('Активно')) {
      return {
        glipf: 'glipf-smartphone',
        statusClass: 'notification',
        listStatusClass: 'notification',
        statusText: oss.Status,
        canVote: false,
        canViewResults: false,
      };
    } else if (oss.Status === 'Идет голосование') {
      if (oss.IsComplete) {
        return {
          glipf: 'glipf-check glipf-check_green',
          statusClass: 'vote-counted',
          listStatusClass: 'vote-counted',
          statusText: 'Ваш голос учтён',
          canVote: false,
          canViewResults: true,
        };
      }
      return {
        glipf: 'glipf-checklist',
        statusClass: 'there-vote',
        listStatusClass: 'voting',
        statusText: oss.Status,
        canVote: true,
        canViewResults: false,
      };
    } else if (oss.Status.startsWith('Завершено')) {
      return {
        glipf: 'glipf-start-and-finish',
        statusClass: 'complete',
        listStatusClass: 'complete',
        statusText: oss.Status,
        canVote: false,
        canViewResults: true,
      };
    } else {
      return {
        glipf: '',
        statusClass: 'unpublished',
        listStatusClass: 'unpublished',
        statusText: oss.Status,
        canVote: false,
      };
    }
  },
};

const mutations = {
  setOss(state, data) {
    state.oss = {
      ...state.oss,
      ...data,
    };
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
