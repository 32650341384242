// import { normalizeData } from '../../services/common';
// import * as announcementsApi from "@/api/announcement";

const state = {
  announcements: null,
};

const actions = {};

const getters = {
  getAnnouncementById: (state) => (id) => {
    const announcements = JSON.parse(localStorage.getItem('announcements'));

    if (announcements) {
      return announcements[id];
    }

    return state.announcements[id] || [];
  },
  getAnnouncements: (state) => (list) => {
    if (list.length) {
      const announcements = JSON.parse(localStorage.getItem('announcements'));

      if (announcements) {
        return list.map((id) => announcements[id] || []) || [];
      }

      return list.map((id) => state.announcements[id] || []) || [];
    }

    return null;
  },
};

const mutations = {
  setAnnouncements(state, data) {
    state.announcements = {
      ...state.announcements,
      ...data,
    };

    localStorage.setItem('announcements', JSON.stringify(state.announcements));
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  state,
  mutations,
};
