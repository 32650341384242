<template>
  <section class="polls">
    <section-header
      :title="$t('polls')"
      :moreLink="{
        name: 'Polls',
      }"
    >
      <template v-slot:icon>
        <chat-icon class="icon" />
      </template>
    </section-header>

    <div v-if="items.length" class="polls__list">
      <div v-for="(item, i) in items" :key="`poll-${i}`" class="polls__item">
        <card
          :title="item.Name"
          :detail-link="{
            name: 'Polls',
            params: {
              id: item.ID,
              subpage: 'form',
            },
          }"
          class="polls__card"
        />
      </div>
    </div>

    <template v-else>
      <alert type="warning">
        {{ $t('no_polls_to_complete_at_this_time') }}
        <router-link
          :to="{
            name: 'Polls',
          }"
          class="polls__link"
        >
          {{ $t('all_polls') }}
        </router-link>
      </alert>
    </template>
  </section>
</template>

<script>
import { ChatIcon } from '@vue-hero-icons/outline';
import SectionHeader from '@/components/pages/common/SectionHeader';
import Card from '@/components/ui/Card';
import Alert from '@/components/ui/Alert';

export default {
  name: 'PollsSection',

  components: {
    SectionHeader,
    ChatIcon,
    Card,
    Alert,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.polls__list
  display: flex
  flex-wrap: wrap

.polls__item
  flex-grow: 1
  flex-shrink: 0
  flex-basis: 100%
  max-width: 100%
  padding-left: calc(16px / 2)
  padding-right: calc(16px / 2)
  margin-bottom: 16px

  @media screen and (min-width: 768px)
    flex-basis: calc(100% / 2)
    max-width: calc(100% / 2)
    margin-bottom: 20px

    &:nth-child(1), &:nth-child(2n) + .polls__item
      padding-left: 0

    &:nth-child(2n)
      padding-right: 0

  @media screen and (min-width: 992px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

  @media screen and (min-width: 1200px)
    flex-basis: calc(100% / 4)
    max-width: calc(100% / 4)
    padding-left: calc(16px / 2)
    padding-right: calc(16px / 2)

    &:nth-child(2n) + .polls__item
      padding-left: calc(16px / 2)

    &:nth-child(2n)
      padding-right: calc(16px / 2)

    &:nth-child(1), &:nth-child(4n) + .polls__item
      padding-left: 0

    &:nth-child(4n)
      padding-right: 0

  @media screen and (min-width: 1400px)
    padding-left: calc(20px / 2)
    padding-right: calc(20px / 2)

    &:nth-child(2n) + .polls__item
      padding-left: calc(20px / 2)

    &:nth-child(2n)
      padding-right: calc(20px / 2)

    &:nth-child(4n) + .polls__item
      padding-left: 0

    &:nth-child(4n)
      padding-right: 0

.polls__card
  height: 100%

.polls__link
  text-decoration: underline

  &::hover
    text-decoration: none
</style>
