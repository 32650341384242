import axios from 'axios';

/**
 * Получение информацию о голосовании
 *
 * @param   {string}  Code  Код доступа
 *
 * @return  {Object}         Информацию о голосовании
 */
export function getInfo(Code) {
  return axios.post('/OpenPolls/GetInfo', {Code});
}

/**
 * Сохранение результата опроса
 *
 * @param   {Object}  PollingResult
 * @param   {string}  PollingResult.Code       Код доступа
 * @param   {string}  PollingResult.ExtraInfo  Дополнительная информация
 * @param   {Array}  PollingResult.Answers     Ответы на вопросы
 *
 * @return  {Object}                           Статус сохранения результата опроса
 */
export function saveResult(PollingResult) {
  return axios.post('/OpenPolls/SaveResult', PollingResult);
}

/**
 * Получение результата опроса
 *
 * @param   {string}  Code  Код доступа
 *
 * @return  {Object}        Результата опроса
 */
export function results(Code) {
  return axios.post('/OpenPolls/Results', {Code});
}

/**
 * Обновление информации о прочтении
 *
 * @param   {string}  Code  Код доступа
 *
 * @return  {Object}        Статус обновления информации о прочтении
 */
export function setReadedFlag(Code) {
  return axios.post('/OpenPolls/SetReadedFlag', {Code});
}