<template>
  <div class="mobile-app-page">
    <figcaption
      v-if="logoIsExist"
      class="mobile-app-page__logo"
    >
      <img
        :src="config.appIconFile"
        :alt="config.main_name"
      />
    </figcaption>
    <template v-if="appLink">
      <p
        v-if="isNdrcamera"
        class="mobile-app-page__text"
        style="font-weight: normal;"
        v-html="$t('install_mobile_application_alt')"
      ></p>
      <p
        v-else
        class="mobile-app-page__text"
      >
        {{ $t('install_mobile_application') }} <strong>{{ config.main_name }}</strong> {{ $t('for_phone') }} {{ platform
        }}
      </p>
      <a
        role="button"
        :href="appLink"
        class="mobile-app-page__button"
      >{{ $t('upload') }}</a>
    </template>
    <template v-else>
      <p
        v-if="isNdrcamera"
        class="mobile-app-page__text"
        style="font-weight: normal;"
        v-html="$t('install_mobile_application_alt')"
      ></p>
      <p
        v-else
        class="mobile-app-page__text"
      >
        {{ $t('install_mobile_application') }} <strong>{{ config.main_name }}</strong>
      </p>
      <div class="mobile-app-page__links">
        <a
          role="button"
          :href="config.appLinkAndroid"
          class="mobile-app-page__app-link"
        >
          <img
            :src="resolveUrl('/img/static/application/google-play-gray.svg')"
            alt="Google Play"
          />
        </a>
        <a
          role="button"
          :href="config.appLinkIOS"
          class="mobile-app-page__app-link"
        >
          <img
            :src="resolveUrl('/img/static/application/app-store-gray.svg')"
            alt="App Store"
          />
        </a>
      </div>
    </template>
  </div>
</template>

<script type="javascript">

export default {
  name: 'MobileAppPage',

  computed: {
    platform() {
      if (this.isAndroidPhone) {
        return 'Android';
      }

      if (this.isApplePhone) {
        return 'Apple';
      }

      return null;
    },

    appLink() {
      if (this.isAndroidPhone) {
        return this.config.appLinkAndroid;
      }

      if (this.isApplePhone) {
        return this.config.appLinkIOS;
      }

      return false;
    }
  },

  mounted() {
    if (this.config?.appIconFile) {
      this.checkAppLogo();
    }
  },
}
</script>

<style lang="sass" scope>
@import '../sass/variables';
@import '../sass/mixins';

.mobile-app-page
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 46px 26px 46px

  &__logo
    width: 100%
    max-width: 128px
    border: 1px solid $cAccent
    border-radius: 10%
    overflow: hidden
    margin-bottom: 46px

    img
      width: 100%
      height: 100%
      object-fit: contain

  &__text
    +TextHeader(2)
    text-align: center
    margin-bottom: 46px
    max-width: 700px;

    strong
      white-space: nowrap

  &__button
    +Button()

  &__app-link
    display: flex
    align-items: center
    padding: 10px
    border: 1px solid $cStyle
    border-radius: 10px

    &:not(:last-child)
      margin-bottom: 46px
</style>
