<template>
  <svg
    ref="svg"
    width="942"
    height="731"
    viewBox="0 0 942 731"
    fill="none"
    version="1.1"
    id="svg270"
    sodipodi:docname="full.svg"
    inkscape:version="1.2.1 (9c6d41e410, 2022-07-14, custom)"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_5102_4333" style="mask-type: alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="970" height="731">
      <rect width="970" height="731" fill="#D9D9D9" id="rect2" />
    </mask>
    <g mask="url(#mask0_5102_4333)" id="main" inkscape:label="#main">
      <g clip-path="url(#clip0_5102_4333)" id="content" inkscape:label="#content">
        <g id="hand" inkscape:label="#hand">
          <path
            d="M779.779 573.221C779.779 573.221 766.571 565.108 733.725 588.376C703.903 609.424 645.789 673.103 637.271 687.301C629.787 699.758 637.692 705.575 637.692 705.575C637.692 705.575 697.146 740.017 699.634 740.017C702.122 740.017 842.658 720.882 842.658 720.882L838.486 626.951L779.779 573.221Z"
            fill="#ACE3E8"
            id="path5"
          />
          <path
            d="M355.53 341.309C355.53 341.309 350.553 350.035 354.918 355.66C359.282 361.286 380.491 374.986 390.463 380.535C400.436 386.084 414.16 374.297 414.16 374.297L417.989 345.595L372.47 336.258L355.53 341.309Z"
            fill="#EDD0C5"
            id="path7"
          />
          <path
            d="M355.53 398.712C355.53 398.712 350.553 407.437 354.918 413.063C359.282 418.688 380.491 432.388 390.463 437.937C400.436 443.487 414.16 431.7 414.16 431.7L417.989 402.998L372.47 393.66L355.53 398.712Z"
            fill="#EDD0C5"
            id="path9"
          />
          <path
            d="M397.394 454.489C390.751 456.154 377.448 452.001 376.606 461.129C375.764 470.256 357.465 472.762 361.637 479.421C365.81 486.08 369.964 489.39 377.448 496.049C384.932 502.708 390.847 506.439 395.728 507.683C398.236 511.835 414.027 525.555 424 528.463C428.154 534.203 438.146 542.183 443.141 543.426C445.649 548.401 463.106 562.561 468.025 562.561C469.691 565.89 516.262 608.273 532.895 619.084C549.529 629.895 562.622 639.864 577.591 644.227C595.048 649.202 597.746 648.169 606.054 650.656C614.361 653.144 628.775 668.145 636.833 679.76C644.317 690.552 718.931 784.809 734.11 794.452C743.259 800.193 984.383 791.142 982.718 786.971C975.751 769.558 830.353 643.175 815.595 628.154C795.113 607.393 711.025 538.222 705.417 514.476C702.163 500.699 685.05 469.395 675.078 450.279C665.105 431.164 566.986 431.145 561.167 430.322C555.348 429.499 478.859 438.626 478.859 438.626L397.394 454.489Z"
            fill="#F7D9CB"
            id="path11"
          />
          <path
            d="M638.824 682.935C638.824 682.935 646.309 692.273 668.761 687.298C691.214 682.323 714.911 667.341 733 651.765C745.365 641.107 774.786 609.994 779.15 595.031C784.127 600.025 778.518 618.088 778.518 618.088C778.518 618.088 741.728 674.21 739.24 676.697C736.752 679.185 671.881 724.075 671.881 724.075L654.424 704.117L638.824 682.935Z"
            fill="#EDD0C5"
            id="path13"
          />
          <path
            d="M724.176 823.194C721.669 819.367 635.207 702.646 635.207 702.646C635.207 702.646 643.61 708.061 673.49 697.25C685.166 693.021 708.921 680.66 728.579 664.205C747.28 648.572 761.904 629.169 772.853 611.852C783.055 595.703 784.338 577.41 779.61 573.086C803.268 590.556 1058.02 809.895 1058.02 809.895C1058.02 809.895 917.925 828.188 890.075 826.925C886.112 826.753 724.176 823.194 724.176 823.194Z"
            fill="#D5F9FF"
            id="path15"
          />
          <path
            d="M394.061 230.978C382.423 225.984 365.081 221.2 358.42 230.347C351.759 239.493 352.984 248.084 357.482 254.973C362.153 262.148 368.297 266.721 378.27 271.696C385.857 275.806 393.092 280.535 399.899 285.837L404.876 251.758L394.061 230.978Z"
            fill="#F7D9CB"
            id="path17"
          />
          <path
            d="M588.846 493.427C588.846 493.427 583.697 519.068 595.086 543.904C601.939 558.867 610.667 577.581 610.667 577.581C610.667 577.581 592.578 558.255 581.955 541.417C571.332 524.578 563.866 505.884 565.742 490.921C567.618 475.957 588.846 493.427 588.846 493.427Z"
            fill="#EDD0C5"
            id="path19"
          />
          <path
            d="M370.174 475.539C370.174 475.539 365.178 484.264 369.542 489.871C373.907 495.477 382.807 504.911 394.062 506.958C396.972 512.794 418.181 525.461 427.33 525.251C434.987 525.078 580.327 518.19 581.993 506.556C582.758 501.122 425.244 496.166 425.244 496.166L370.174 475.539Z"
            fill="#EDD0C5"
            id="path21"
          />
          <path
            d="M615.355 215.078C606.895 213.662 597.056 215.384 593.209 226.291C589.246 237.523 590.318 255.414 591.964 265.574C593.515 275.141 592.271 291.138 601.937 304.953C601.937 313.257 607.679 348.178 603.602 363.964C599.525 379.75 591.811 414.804 589.055 441.268C586.145 469.108 586.145 490.711 586.968 501.522C588.098 516.026 602.779 543.905 628.544 549.741C654.308 555.577 700.879 550.143 704.209 539.351C707.54 528.559 708.363 507.76 705.875 492.797C703.386 477.834 697.625 454.413 691.748 440.426C685.087 424.64 670.961 383.921 665.965 366.452C661.591 352.432 659.074 337.9 658.481 323.227C657.734 309.029 656.815 294.142 653.485 287.502C650.154 280.863 634.343 260.905 631.051 250.094C627.759 239.283 620.332 215.901 615.355 215.078Z"
            fill="#F7D9CB"
            id="path23"
          />
          <path
            d="M387.841 321.16C382.635 318.041 378.271 317.122 370.365 316.586C364.144 316.165 360.393 319.916 355.052 329.062C350.497 336.716 348.391 341.519 350.899 348.197C353.406 354.875 354.727 356.922 360.469 361.074C366.036 365.43 372.626 368.289 379.611 369.379C388.76 371.043 393.335 368.135 397.489 362.318C401.177 356.933 403.724 350.851 404.973 344.446C407.366 331.129 391.995 323.647 387.841 321.16Z"
            fill="#F7D9CB"
            id="path25"
          />
          <path
            d="M352.087 392.227C347.933 400.531 350.422 407.535 353.752 411.362C359.399 417.451 366.172 422.389 373.697 425.904C380.933 429.061 389.7 431.376 394.485 425.904C400.227 419.264 404.458 407.209 402.811 400.55C400.897 393.203 396.15 389.759 388.245 384.764C379.114 379.024 371.63 374.795 364.969 377.283C358.308 379.77 354.575 387.233 352.087 392.227Z"
            fill="#F7D9CB"
            id="path27"
          />
          <path
            d="M362.477 483.649C371.627 493.619 379.513 492.375 386.174 488.223C392.836 484.07 397.43 475.881 396.97 464.955C396.568 454.986 391.572 452.077 386.576 449.647C383.134 447.772 379.102 447.288 375.313 448.296C371.524 449.303 368.266 451.725 366.21 455.062C362.879 459.961 353.328 473.623 362.477 483.649Z"
            fill="#F7D9CB"
            id="path29"
          />
          <path
            d="M388.608 331.53C389.05 331.67 389.446 331.927 389.756 332.272C390.065 332.618 390.277 333.04 390.369 333.494C390.461 333.949 390.429 334.42 390.278 334.858C390.126 335.297 389.86 335.687 389.507 335.988C386.765 338.294 384.563 341.175 383.057 344.426C381.071 348.799 379.308 353.27 377.774 357.82C377.654 358.195 377.451 358.538 377.18 358.823C376.909 359.108 376.576 359.328 376.208 359.466C375.839 359.604 375.444 359.657 375.052 359.62C374.661 359.584 374.282 359.459 373.945 359.255C369.773 356.94 362.059 353.209 362.059 349.191C362.059 343.565 365.504 337.021 367.992 333.29C369.907 330.458 373.735 329.463 375.649 328.277C375.975 328.084 376.339 327.964 376.715 327.925C377.092 327.885 377.472 327.927 377.831 328.047L388.608 331.53Z"
            fill="#F9E7E1"
            id="path31"
          />
          <path
            d="M372.76 396.492C367.285 402.806 367.151 409.58 369.64 412.699C372.474 415.792 375.498 418.706 378.694 421.424C380.3 416.948 382.393 412.662 384.934 408.642C387.52 404.846 390.325 401.204 393.337 397.735C393.337 397.735 380.876 387.135 372.76 396.492Z"
            fill="#F9E7E1"
            id="path33"
          />
          <path
            d="M388.664 457.265C383.748 459.333 379.548 462.8 376.586 467.234C373.857 471.859 372.527 477.176 372.757 482.542C372.757 482.542 364.814 473.204 371.36 462.91C372.984 459.943 375.678 457.708 378.893 456.659C382.108 455.61 385.603 455.827 388.664 457.265Z"
            fill="#F9E7E1"
            id="path35"
          />
          <path
            d="M618.149 217.891C615.282 219.939 613.196 222.901 612.234 226.291C610.99 231.591 614.417 253.079 616.273 253.73C618.13 254.38 628.026 252.065 631.05 250.094C630.323 246.879 622.839 223.191 618.149 217.891Z"
            fill="#F9E7E1"
            id="path37"
          />
        </g>
        <g id="phone-mockup" inkscape:label="#phone-mockup" style="display: inline">
          <path
            d="M 570.489,102.969 H 416.401 c -14.452,0 -26.167,11.711 -26.167,26.157 v 352.668 c 0,14.447 11.715,26.157 26.167,26.157 h 154.088 c 14.451,0 26.166,-11.71 26.166,-26.157 V 129.126 c 0,-14.446 -11.715,-26.157 -26.166,-26.157 z"
            fill="#727589"
            id="path39"
            style="display: inline"
          />
          <path
            d="m 570.471,111.637 c 2.303,0.002 4.583,0.459 6.709,1.343 2.127,0.885 4.058,2.18 5.683,3.812 1.625,1.631 2.912,3.567 3.788,5.697 0.875,2.13 1.322,4.411 1.315,6.713 v 352.612 c -0.005,4.635 -1.851,9.078 -5.131,12.354 -3.281,3.275 -7.728,5.115 -12.364,5.115 H 416.382 c -4.635,0 -9.08,-1.84 -12.357,-5.116 -3.278,-3.277 -5.119,-7.72 -5.119,-12.353 V 129.202 c 0,-4.635 1.841,-9.08 5.118,-12.36 3.277,-3.279 7.722,-5.124 12.358,-5.129 z"
            fill="#ffffff"
            id="path41"
            style="display: inline"
          />
          <g
            clip-path="url(#clip1_5102_4333)"
            id="screens-container"
            inkscape:label="#screens-container"
            style="display: inline"
          >
            <mask id="path-39-inside-3_5102_4333" fill="#ffffff">
              <path d="m 399,227.5 h 189 v 55.65 H 399 Z" id="path107" />
            </mask>
            <g id="carousel-container" ref="carousel-container">
              <svg
                v-for="(slide, i) in displayedSlides"
                :key="`slide-${i}`"
                ref="slide"
                :id="`${i}`"
                class="slide-container"
                style="overflow: visible"
                :data-index="i"
                width="189"
                height="387"
                viewBox="0 0 189 387"
                :x="slide.slideXPosition"
                y="112.39453"
              >
                <use
                  preserveAspectRatio="none"
                  inkscape:svg-dpi="96"
                  width="189"
                  height="387"
                  :xlink:href="`${slide.img}#666`"
                  :inkscape:label="`#${i}`"
                />
              </svg>
            </g>
          </g>
          <path
            d="M 596.711,240.833 V 150.25 c 0.909,0 1.78,0.361 2.423,1.003 0.642,0.643 1.003,1.514 1.003,2.422 v 83.79 c -0.015,0.899 -0.382,1.755 -1.023,2.385 -0.641,0.63 -1.504,0.983 -2.403,0.983 z"
            fill="#727589"
            id="path124"
          />
          <path
            d="m 596.711,278.261 v -26.788 c 0.91,0.005 1.782,0.37 2.424,1.015 0.642,0.646 1.002,1.519 1.002,2.429 v 19.957 c -0.01,0.902 -0.375,1.763 -1.017,2.397 -0.641,0.634 -1.507,0.99 -2.409,0.99 z"
            fill="#727589"
            id="path126"
          />
          <path
            d="m 390.235,173.749 v -26.788 c -0.911,0 -1.784,0.36 -2.43,1.002 -0.646,0.642 -1.011,1.513 -1.016,2.423 v 19.919 c 0.005,0.912 0.37,1.785 1.015,2.43 0.645,0.645 1.518,1.009 2.431,1.014 z"
            fill="#727589"
            id="path128"
          />
          <path
            d="m 445.496,105.992 h 95.707 v 0.804 c 0,3.796 -1.508,7.436 -4.194,10.12 -2.685,2.685 -6.326,4.192 -10.124,4.192 H 459.89 c -1.886,0.011 -3.756,-0.352 -5.502,-1.067 -1.746,-0.715 -3.334,-1.767 -4.671,-3.097 -1.338,-1.33 -2.399,-2.912 -3.124,-4.653 -0.724,-1.742 -1.097,-3.609 -1.097,-5.495 z"
            fill="#727589"
            id="path130"
          />
          <path
            d="m 523.247,491.686 h -59.626 c -0.507,0 -0.994,-0.201 -1.353,-0.56 -0.359,-0.359 -0.561,-0.846 -0.561,-1.353 0,-0.508 0.202,-0.994 0.561,-1.353 0.359,-0.359 0.846,-0.561 1.353,-0.561 h 59.626 c 0.507,0 0.994,0.202 1.353,0.561 0.359,0.359 0.561,0.845 0.561,1.353 0,0.507 -0.202,0.994 -0.561,1.353 -0.359,0.359 -0.846,0.56 -1.353,0.56 z"
            fill="#727589"
            id="path132"
          />
        </g>
      </g>
      <g
        id="button-right-group"
        :class="['button-group', { 'button-group--blocked': block }]"
        inkscape:label="#button-right-group"
        @click="!block ? next($event) : false"
      >
        <circle cx="830" cy="333" r="18" fill="#E9EDF2" id="button-right-mask" inkscape:label="#button-right-mask" />
        <g clip-path="url(#clip8_5102_4333)" id="button-right" inkscape:label="#button-right">
          <path
            d="M827.005 340C826.807 340 826.614 339.941 826.45 339.831C826.285 339.722 826.157 339.566 826.082 339.383C826.006 339.2 825.986 338.999 826.025 338.805C826.063 338.612 826.158 338.433 826.298 338.294L831.588 333.003L826.298 327.712C826.204 327.619 826.129 327.509 826.078 327.387C826.027 327.266 826.001 327.135 826 327.003C825.999 326.871 826.025 326.741 826.075 326.619C826.126 326.497 826.199 326.386 826.293 326.293C826.386 326.199 826.497 326.126 826.619 326.075C826.741 326.025 826.871 325.999 827.003 326C827.135 326.001 827.265 326.027 827.387 326.078C827.509 326.13 827.619 326.204 827.711 326.298L833.707 332.296C833.8 332.389 833.874 332.499 833.924 332.62C833.974 332.741 834 332.871 834 333.003C834 333.134 833.974 333.264 833.924 333.385C833.874 333.506 833.8 333.617 833.707 333.709L827.711 339.707C827.619 339.8 827.508 339.874 827.387 339.924C827.266 339.974 827.136 340 827.005 340Z"
            fill="#4589B1"
            id="path138"
          />
        </g>
      </g>
      <g
        id="button-left-group"
        :class="['button-group', { 'button-group--blocked': block }]"
        inkscape:label="#button-left-group"
        @click="!block ? prev($event) : false"
      >
        <circle
          r="18"
          transform="matrix(-1 0 0 1 113 333)"
          fill="#E9EDF2"
          id="button-left-mask"
          inkscape:label="#button-left-mask"
        />
        <g clip-path="url(#clip9_5102_4333)" id="button-left" inkscape:label="#button-left">
          <path
            d="M115.995 340C116.193 340 116.386 339.941 116.55 339.831C116.715 339.722 116.843 339.566 116.918 339.383C116.994 339.2 117.014 338.999 116.975 338.805C116.937 338.612 116.842 338.433 116.702 338.294L111.412 333.003L116.702 327.712C116.796 327.619 116.871 327.509 116.922 327.387C116.973 327.266 116.999 327.135 117 327.003C117.001 326.871 116.975 326.741 116.925 326.619C116.874 326.497 116.801 326.386 116.707 326.293C116.614 326.199 116.503 326.126 116.381 326.075C116.259 326.025 116.129 325.999 115.997 326C115.865 326.001 115.735 326.027 115.613 326.078C115.491 326.13 115.381 326.204 115.289 326.298L109.293 332.296C109.2 332.389 109.126 332.499 109.076 332.62C109.026 332.741 109 332.871 109 333.003C109 333.134 109.026 333.264 109.076 333.385C109.126 333.506 109.2 333.617 109.293 333.709L115.289 339.707C115.381 339.8 115.492 339.874 115.613 339.924C115.734 339.974 115.864 340 115.995 340Z"
            fill="#4589B1"
            id="path144"
          />
        </g>
      </g>
      <g id="bubble-group" inkscape:label="#bubble-group">
        <path
          d="M473.805 199.812C476.014 202.082 480.564 200.885 480.653 198.037L482 153.542V81.1046C482 68.1443 467.378 57.5366 449.53 57.5366L127.47 56.9999C109.6 56.9999 95 67.6076 95 80.568V153.542C95 166.502 109.6 177.09 127.47 177.09L449.53 177.626C451.761 177.626 465.412 191.247 473.805 199.812Z"
          fill="#ECF4FD"
          id="bubble"
          inkscape:label="#bubble"
        />
        <text
          xml:space="preserve"
          id="text"
          style="
            -inkscape-font-specification: 'sans-serif, Bold';
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            font-variant-east-asian: normal;
            white-space: pre;
            shape-inside: url(#rect2159);
            shape-padding: 0.803115;
            fill: #43576d;
            fill-opacity: 1;
            fill-rule: evenodd;
          "
          transform="translate(-10,-3)"
          inkscape:label="#text"
          v-html="displayedSlides[activeSlide].text"
        ></text>
        <g id="pagination" inkscape:label="#pagination">
          <circle
            v-for="(slide, i) in displayedSlides"
            :key="`bullet-${i}`"
            :ref="`bullet-${i}`"
            :cx="slide.bulletXPosition"
            cy="73"
            r="6"
            :id="`bullet-${i}`"
            :class="['bullet', { 'bullet--active': activeSlide === i }, { 'bullet--blocked': block }]"
            :inkscape:label="`#bullet-${i}`"
            @click="!block ? moveTo(i) : false"
          />
        </g>
      </g>
    </g>
    <defs id="defs268">
      <rect x="125.96063" y="93.627628" width="355.84405" height="72.415698" id="rect2159" />
      <filter
        id="filter0_d_5102_4333"
        x="392.7"
        y="441.201"
        width="201.6"
        height="58.7992"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" id="feFlood178" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
          id="feColorMatrix180"
        />
        <feOffset dy="-6.3" id="feOffset182" />
        <feGaussianBlur stdDeviation="3.15" id="feGaussianBlur184" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.276719 0 0 0 0 0.315513 0 0 0 0 0.4125 0 0 0 0.12 0"
          id="feColorMatrix186"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5102_4333" id="feBlend188" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5102_4333" result="shape" id="feBlend190" />
      </filter>
      <filter
        id="filter1_dd_5102_4333"
        x="395.85"
        y="109.375"
        width="195.3"
        height="63.4992"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" id="feFlood193" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
          id="feColorMatrix195"
        />
        <feOffset dy="0.525" id="feOffset197" />
        <feGaussianBlur stdDeviation="1.05" id="feGaussianBlur199" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.233333 0 0 0 0 0.313725 0 0 0 0 0.466667 0 0 0 0.24 0"
          id="feColorMatrix201"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5102_4333" id="feBlend203" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
          id="feColorMatrix205"
        />
        <feOffset dy="0.525" id="feOffset207" />
        <feGaussianBlur stdDeviation="1.575" id="feGaussianBlur209" />
        <feComposite in2="hardAlpha" operator="out" id="feComposite211" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.233333 0 0 0 0 0.31308 0 0 0 0 0.466667 0 0 0 0.16 0"
          id="feColorMatrix213"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5102_4333"
          result="effect2_dropShadow_5102_4333"
          id="feBlend215"
        />
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5102_4333" result="shape" id="feBlend217" />
      </filter>
      <clipPath id="clip0_5102_4333">
        <rect width="869" height="826" fill="white" transform="translate(189 1)" id="rect220" />
      </clipPath>
      <clipPath id="clip1_5102_4333">
        <rect x="399" y="112" width="189" height="387" rx="18" fill="white" id="rect223" />
      </clipPath>
      <clipPath id="clip2_5102_4333">
        <path d="M399 168.699H588V198.599H399V168.699Z" fill="white" id="path226" />
      </clipPath>
      <clipPath id="clip3_5102_4333">
        <rect width="12.6" height="12.6" fill="white" transform="translate(567 177.098)" id="rect229" />
      </clipPath>
      <clipPath id="clip4_5102_4333">
        <path d="M399 198.102H588V228.002H399V198.102Z" fill="white" id="path232" />
      </clipPath>
      <clipPath id="clip5_5102_4333">
        <rect width="12.6" height="12.6" fill="white" transform="translate(567 206.5)" id="rect235" />
      </clipPath>
      <clipPath id="clip6_5102_4333">
        <rect width="12.6" height="12.6" fill="white" transform="translate(407.398 148.203)" id="rect238" />
      </clipPath>
      <clipPath id="clip7_5102_4333">
        <rect width="172.2" height="21" fill="white" transform="translate(407.398 287.348)" id="rect241" />
      </clipPath>
      <clipPath id="clip8_5102_4333">
        <rect width="24" height="24" fill="white" transform="translate(818 321)" id="rect244" />
      </clipPath>
      <clipPath id="clip9_5102_4333">
        <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 125 321)" id="rect247" />
      </clipPath>
      <clipPath id="clip10_5102_4333">
        <rect width="12" height="12" fill="white" transform="translate(232 67)" id="rect250" />
      </clipPath>
      <clipPath id="clip11_5102_4333">
        <rect width="12" height="12" fill="white" transform="translate(252 67)" id="rect253" />
      </clipPath>
      <clipPath id="clip12_5102_4333">
        <rect width="12" height="12" fill="white" transform="translate(272 67)" id="rect256" />
      </clipPath>
      <clipPath id="clip13_5102_4333">
        <rect width="12" height="12" fill="white" transform="translate(292 67)" id="rect259" />
      </clipPath>
      <clipPath id="clip14_5102_4333">
        <rect width="12" height="12" fill="white" transform="translate(312 67)" id="rect262" />
      </clipPath>
      <clipPath id="clip15_5102_4333">
        <rect width="12" height="12" fill="white" transform="translate(332 67)" id="rect265" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AuthCarousel',

  data() {
    return {
      swiper: null,
      activeSlide: 0,
      slides: [
        {
          id: 0,
          section: 'Requests',
          img: this.resolveUrl('/img/auth-carousel/0.svg'),
          text: `
            <tspan x="126.76367" y="117.52832">Создавайте заявки</tspan>
            <tspan x="126.76367" y="148.52832">в диспетчерскую службу</tspan>`,
        },
        {
          id: 1,
          section: 'Requests',
          img: this.resolveUrl('/img/auth-carousel/1.svg'),
          text: `
            <tspan x="126.76367" y="117.52832">Обсуждайте заявку</tspan>
            <tspan x="126.76367" y="148.52832">в реальном времени</tspan>`,
        },
        {
          id: 2,
          section: 'Meters',
          img: this.resolveUrl('/img/auth-carousel/2.svg'),
          text: `
            <tspan x="126.76367" y="117.52832">Передавайте показания</tspan>
            <tspan x="126.76367" y="148.52832">не выходя из дома</tspan>`,
       
        },
        {
          id: 3,
          section: 'Payment',
          img: this.resolveUrl('/img/auth-carousel/3.svg'),
          text: `
            <tspan x="126.76367" y="117.52832">Оплачивайте квитанцию</tspan>
            <tspan x="126.76367" y="148.52832">одной кнопкой</tspan>`,
        },
        {
          id: 4,
          section: null,
          img: this.resolveUrl('/img/auth-carousel/4.svg'),
          text: `
            <tspan x="126.76367" y="117.52832">Мгновенно получайте</tspan>
            <tspan x="126.76367" y="148.52832">уведомления</tspan>`,
        },
        {
          id: 5,
          section: null,
          img: this.resolveUrl('/img/auth-carousel/5.svg'),
          text: `<tspan x="126.76367" y="117.52832">Много других функций</tspan>`,
        },
      ],
      $slides: null,

      $container: null,
      $currentImage: null,
      block: false,
      intervalId: null,
      timeoutId: null,
    };
  },

  computed: {
    ...mapGetters({
      getMenuVisibilitySettings: 'config/getMenuVisibilitySettings',
    }),

    displayedSlides() {
      const SLIDE_COORDS = [
        [238, 399.49234],
        [258, 588.49234],
        [278, 777.49234],
        [298, 966.49234],
        [318, 1155.49234],
        [338, 1344.49234],
      ];

      const slides = this.slides;
      const menuVisibilitySettings = this.getMenuVisibilitySettings;
      
      const displayedSlides = [...slides];

      const hideInappropriateSlides = (matchingSlides) => {
        for (let i = 0; i< matchingSlides.length; i++) {
          const currentSlide = matchingSlides[i];
          const slideIndex = displayedSlides.findIndex(slide => slide.id === currentSlide.id);

          if (slideIndex !== -1) {
            displayedSlides.splice(slideIndex, 1);
          }
        }
      } 

      if (!menuVisibilitySettings.Requests) {
        const matchingSlides = displayedSlides.filter(slide => slide.section === 'Requests');
        
        hideInappropriateSlides(matchingSlides);
      }

      if (!menuVisibilitySettings.Payment) {
        const matchingSlides = displayedSlides.filter(slide => slide.section === 'Payment');
        
        hideInappropriateSlides(matchingSlides);
      }

      if (!menuVisibilitySettings.Meters) {
        const matchingSlides = displayedSlides.filter(slide => slide.section === 'Meters');
        
        hideInappropriateSlides(matchingSlides);
      }

      if (!menuVisibilitySettings.Polls) {
        const matchingSlides = displayedSlides.filter(slide => slide.section === 'Polls');
        
        hideInappropriateSlides(matchingSlides);
      }

      if (!menuVisibilitySettings.Bills) {
        const matchingSlides = displayedSlides.filter(slide => slide.section === 'Bills');
        
        hideInappropriateSlides(matchingSlides);
      }

      if (!menuVisibilitySettings.Services) {
        const matchingSlides = displayedSlides.filter(slide => slide.section === 'Services');
        
        hideInappropriateSlides(matchingSlides);
      }

      for (let i = 0; i < displayedSlides.length; i++) {
        const currrentSlide = displayedSlides[i];
        const [bulletXPosition, slideXPosition] = SLIDE_COORDS[i];

        currrentSlide.bulletXPosition = bulletXPosition;
        currrentSlide.slideXPosition = slideXPosition;
      }

      return displayedSlides;
    }
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.$container = this.$refs['carousel-container'];

      const $firstImage = this.$container.firstChild;

      this.$currentImage = $firstImage;
      this.$currentImage.classList.add('current');

      this.autostart();
    },

    next() {
      // console.log('--- next method ---')
      // if (e) {
      //   clearInterval(this.intervalId);
      // }
      const $currentImage = this.$container.querySelector(`.current`);

      this.$currentImage.classList.remove('current');

      if ($currentImage.nextSibling) {
        // Если еще есть дальше слайды
        this.move({
          steps: 1,
        });

        this.$currentImage = this.$currentImage.nextSibling;
      } else {
        // Если дальше слайдов нет, то перемотать в начало
        this.move({
          direction: 'toLeft',
          steps: this.$refs['slide'].length - 1,
          moving: 80,
          delay: 1000,
        });

        this.$currentImage = this.$container.firstChild;
      }

      this.$currentImage.classList.add('current');
      this.activeSlide = +this.$currentImage.dataset.index;
    },

    prev() {
      // console.log('--- prev method ---')
      // if (e) {
      //   clearInterval(this.intervalId);
      // }
      // this.block = true;
      const $currentImage = this.$container.querySelector(`.current`);

      this.$currentImage.classList.remove('current');

      if ($currentImage.previousSibling) {
        this.move({
          direction: 'toLeft',
          steps: 1,
        });

        this.$currentImage = this.$currentImage.previousSibling;
      } else {
        this.move({
          steps: this.$refs['slide'].length - 1,
          moving: 80,
          delay: 1000,
        });

        this.$currentImage = this.$container.lastChild;
      }

      this.$currentImage.classList.add('current');

      this.activeSlide = +this.$currentImage.dataset.index;
      // this.block = false;
    },

    moveTo(index) {
      // console.log('--- move method ---')
      const steps = this.activeSlide - index;

      this.$currentImage.classList.remove('current');

      // To right
      if (Math.sign(steps) < 0) {
        this.move({
          direction: 'toRight',
          steps: Math.abs(steps),
          moving: 20 + 10 * Math.abs(steps),
          delay: 400 + 100 * Math.abs(steps),
        });
      }

      // To left
      if (Math.sign(steps) > 0) {
        this.move({
          direction: 'toLeft',
          steps: Math.abs(steps),
          moving: 20 + 10 * Math.abs(steps),
          delay: 400 + 100 * Math.abs(steps),
        });
      }

      if (Math.sign(steps) === 0) {
        return;
      }

      this.$currentImage = this.$refs['slide'].find(($image) => +$image.dataset.index === index);
      this.$currentImage.classList.add('current');

      this.activeSlide = index;
    },

    move(options) {
      const { direction, steps, moving, delay } = Object.assign(
        {},
        {
          direction: 'toRight',
          steps: 0,
          moving: 30,
          delay: 500,
        },
        options,
      );
      const ltr = direction === 'toRight';

      clearTimeout(this.timeoutId);
      this.block = true;

      this.$refs['slide'].map(($image) => {
        let animationStart = null;
        let requestId = null;
        let transition = 0;
        let x = 0;

        if (ltr) {
          transition = parseFloat($image.getAttribute('x')) - parseFloat($image.getAttribute('width')) * steps;
        } else {
          transition = parseFloat($image.getAttribute('x')) + parseFloat($image.getAttribute('width')) * steps;
        }

        const step = (timestamp) => {
          if (!animationStart) {
            animationStart = timestamp;
          }

          if (ltr) {
            x = parseFloat($image.getAttribute('x')) - moving;
            if (x < transition) x = transition;
          } else {
            x = parseFloat($image.getAttribute('x')) + moving;
            if (x > transition) x = transition;
          }

          $image.setAttribute('x', x);

          if ((ltr && $image.getAttribute('x') > transition) || (!ltr && $image.getAttribute('x') < transition)) {
            window.requestAnimationFrame(step);
          } else {
            window.cancelAnimationFrame(requestId);
          }
        };

        requestId = window.requestAnimationFrame(step);
      });

      setTimeout(() => {
        this.block = false;
        this.autostart();
      }, delay);
    },

    autostart() {
      // console.log('--- autostart method ---')
      this.timeoutId = setTimeout(() => {
        this.next();
      }, 5000);
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

svg
  +Size(100%)
  max-height: 731px
  user-select: none

  &:hover
    .button-group
      opacity: 1

#text
  +Typography(26px, 700, 31px)

.bullet
  fill: #DFDFDF
  cursor: pointer

  &--active
    fill: #4589B1

  &--blocked
    filter: grayscale(100%)

.button-group
  +Transition((opacity))

  opacity: 0
  cursor: pointer

  &--blocked
    filter: grayscale(100%)

.slide-container
  color: $cAccent
</style>
