import Vue from 'vue';
import './plugins/axios';
import './plugins/mask';
import './plugins/element';
import './plugins/perfect-scrollbar';
import './plugins/vue-js-modal';
import 'clickout-event';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './plugins/i18n';
import './mixins';
// import "./plugins/element.js";
import './sass/main.sass';

// TODO: Удалить
import VueSlickCarousel from 'vue-slick-carousel';

import AuthLayout from '@/layouts/LoginRegisterLayout';
import PageLayout from '@/layouts/Page';
import EmptyLayout from '@/layouts/EmptyLayout';
import RequestsLayout from '@/layouts/RequestsLayout';
import ServicesLayout from '@/layouts/ServicesLayout';
import PollsLayout from '@/layouts/PollsLayout';
import OpenPollLayout from '@/layouts/OpenPollLayout';

Vue.component('vue-slick-carousel', VueSlickCarousel);

Vue.component('auth-layout', AuthLayout);
Vue.component('page-layout', PageLayout);
Vue.component('empty-layout', EmptyLayout);
Vue.component('requests-layout', RequestsLayout);
Vue.component('services-layout', ServicesLayout);
Vue.component('polls-layout', PollsLayout);
Vue.component('open-poll-layout', OpenPollLayout);

Vue.config.productionTip = false;

new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
