export default {
  ru: {
    no_meters: 'Нет приборов учета',
    no_metering_devices_registered: 'Для выбранного лицевого счета не зарегистрировано ни одного прибора учета.',
    meter_is_out: 'Счетчик выбыл',
    meter_readings: 'Показания прибора учета',

    readings: 'Показания',
    enter_readings: 'внести показания',
    readings_transmitted: 'Показания переданы',
    readings_transmitted_automatically: 'Показания передаются автоматически',
    really_possible_to_delete_transmitted_meter_readings: 'Действительно удалить переданные показания по прибору учета?',
    previous_readings: 'Предыдущие показания',
    delete_readings: 'Удалить показания',
    deleting_meter_readings: 'Удаление показаний по ПУ',
    readings_history: 'История показаний',
    change_readings: 'Изменить показания',
    submit_readings: 'Передать показания',
    new_value_cannot_be_less: 'Новое значение не может быть меньше предыдущего',
    new_readings_submitted: 'Новые показания переданы',
    automatic_withdrawal: 'Автоматическое снятие',

    verification_request_created: 'Заявка на поверку создана',
    verification_request_created_successfully: 'Заявка на поверку успешно создана',
    request_number: 'Номер заявки',
    calibration_interval: 'Межповерочный интервал',
    calibration_interval_for_meter_expired: 'Истек межповерочный интервал у счетчика',
    order_verification: 'Заказать поверку',
    request_for_verification: 'Заявка на поверку',
    create_request_for_verification_of_metering_device: 'Создать заявку на поверку прибора учета',
    next_verification: 'Следующая поверка',
    years: 'лет',

    by_address: 'по адресу',
    metering_out_of_order: 'Приборы учета считаются вышедшими из строя, по ним невозможно передавать показания. Срочно закажите поверку счетчика, или передайте нам акт поверки, если поверка была недавно проведена.',
    specialist_will_contact_you: 'В ближайшее время с вами свяжется специалист для уточнения стоимости, даты и времени поверки.',

    factory: 'Заводской',
    status: 'Статус',
  },

  en: {
    no_meters: 'No meters',
    no_metering_devices_registered: 'There are no metering devices registered for the selected personal account.',
    meter_is_out: 'The meter is out',
    meter_readings: 'Meter readings',

    readings: 'Readings',
    enter_readings: 'enter readings',
    readings_transmitted: 'Readings has been transmitted',
    readings_transmitted_automatically: 'Readings are transmitted automatically',
    really_possible_to_delete_transmitted_meter_readings: 'Is it really possible to delete the transmitted meter readings?',
    previous_readings: 'Previous readings',
    delete_readings: 'Delete readings',
    deleting_meter_readings: 'Deleting meter readings',
    readings_history: 'Readings history',
    change_readings: 'Change readings',
    submit_readings: 'Submit readings',
    new_value_cannot_be_less: 'The new value cannot be less than the previous one',
    new_readings_submitted: 'New readings submitted',
    automatic_withdrawal: 'Automatic withdrawal',

    verification_request_created: 'Verification request has been created',
    verification_request_created_successfully: 'Verification request has been successfully created',
    request_number: 'Request number',
    calibration_interval: 'Calibration interval',
    calibration_interval_for_meter_expired: 'The calibration interval for the meter has expired:',
    order_verification: 'Order verification',
    request_for_verification: 'Request for verification',
    create_request_for_verification_of_metering_device: 'Create a request for verification of a metering device',
    next_verification: 'Next verification',
    years: 'years',

    by_address: 'by the address',
    metering_out_of_order: 'Metering devices are considered out of order and it is impossible to transmit readings through them. Urgently order the meter verification, or give us the verification report if the verification was recently carried out.',
    specialist_will_contact_you: 'A specialist will contact you shortly to clarification of the cost, date and time of verification.',

    factory: 'Factory',
    status: 'Status',
  },
}