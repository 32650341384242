<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.27339 15.4413C6.77339 16.9413 9.27337 20.9414 11.7734 20.9414C16.5829 20.9414 21.7734 15.9414 17.2734 11.4414C15.7734 9.94141 14.2734 5.94134 13.2734 4.44134C11.3568 1.56645 3.44011 13.608 5.27339 15.4413Z"
      :stroke="fill"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SolidFuelIcon',

  props: {
    fill: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
