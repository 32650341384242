export default {
  ru: {
    register: 'Зарегистрироваться',
    registration: 'Регистрация',
    step: 'Шаг',
    date_of_birth: 'Дата рождения',
    date_placeholder: 'ДД.ММ.ГГГГ',
    date_of_birth_incorrect: 'Указана неверная дата рождения. Требуемый формат: ДД.ММ.ГГГГ.',
    year_of_birth_cannot_be_less: 'Год рождения не может быть меньше 1900',
    year_of_birth_cannot_be_greater: 'Год рождения не может быть больше текущего',
    month_of_birth_cannot_be_less: 'Месяц рождения не может быть меньше 1',
    month_of_birth_cannot_be_more: 'Месяц рождения не может быть больше 12',
    day_of_birth_date_cannot_be_less: 'День даты рождения не может быть меньше 1',
    day_of_birth_month_cannot_be_greater: 'День даты рождения в указанном месяце не может быть больше',

    to_continue_must_agree: 'Для продолжения необходимо дать согласие на обработку персональных данных',
    consent_to: 'Согласие на',
    processing_of_personal_data: 'обработку персональных данных',
    operator_policy_regarding_processing_of_personal_data: 'Политика оператора в отношении обработки персональных данных',

    wrong_phone_number: 'Неверный номер телефона? Вернитесь на начальный экран заполнения.',
    return_on_start: 'Вернуться в начало',
    confirm_password: 'Подтвердите пароль',
    help_you_register: 'Напишите в тех.поодержку, поможем зарегистрироваться.',
    to_continue_must_fill_all_fields: 'Для продолжения необходимо заполнить все поля',

    registration_code: 'Код регистрации',
    wait_for_the_call: 'Ожидайте звонка, робот сообщит Вам код регистрации',
    verification_code_sent_via_sms: 'Проверочный код отправлен сообщением в SMS',
    code_incorrect: 'Код введён не корректно',

    agree: 'Согласен',
    dont_agree: 'Не согласен',
  },

  en: {
    register: 'Register',
    registration: 'Registration',
    step: 'Step',
    date_of_birth: 'Date of Birth',
    date_placeholder: 'DD.MM.YYYY',
    date_of_birth_incorrect: 'The date of birth indicated is incorrect. Required format: DD.MM.YYYY.',
    year_of_birth_cannot_be_less: 'Year of birth cannot be less than 1900',
    year_of_birth_cannot_be_greater: 'Year of birth cannot be greater than the current one',
    month_of_birth_cannot_be_less: 'Month of birth cannot be less than 1',
    month_of_birth_cannot_be_more: 'Month of birth cannot be more than 12',
    day_of_birth_date_cannot_be_less: 'Day of birth date cannot be less than 1',
    day_of_birth_month_cannot_be_greater: 'The day of birth in the specified month cannot be greater than',

    to_continue_must_agree: 'To continue, you must agree to the processing of personal data',
    consent_to: 'Consent to',
    processing_of_personal_data: 'processing of personal data',
    operator_policy_regarding_processing_of_personal_data: 'Operator\'s policy regarding the processing of personal data',

    wrong_phone_number: 'Wrong phone number? Return to the initial filling screen.',
    return_on_start: 'Return on start',
    confirm_password: 'Confirm the password',
    help_you_register: 'Write to technical support, we will help you register.',
    to_continue_must_fill_all_fields: 'To continue, you must fill in all fields',

    registration_code: 'Registration code',
    wait_for_the_call: 'Wait for the call, the robot will tell you the registration code',
    verification_code_sent_via_sms: 'Verification code sent via SMS',
    code_incorrect: 'The code was entered incorrectly',

    agree: 'Agree',
    dont_agree: 'Don\'t agree',
  },
}