import axios from 'axios';

export function getRequestDetails(accessCode) {
  return axios.get('/OpenRequests/Details/' + accessCode);
}

export function getFile({ AccessCode, FileID }) {
  return axios.get('/OpenRequests/File/' + FileID, {
    params: {
      accessCode: AccessCode,
    },
    responseType: 'blob'
  });
}

export function addMessage({ AccessCode, Text }) {
  return axios.post('/OpenRequests/AddMessage', { AccessCode, Text });
}

export function addFile(formDataObject) {
  return axios.post('/OpenRequests/AddFile/', formDataObject, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function closeRequest({ AccessCode, Text, Mark }) {
  return axios.post('/OpenRequests/Close', { AccessCode, Text, Mark });
}

export function cancelRequest({ AccessCode }) {
  return axios.post('/OpenRequests/CancelRequest', { AccessCode });
}

export function getUpdates({ UpdateKey, AccessCode }) {
  return axios.post('/OpenRequests/GetUpdates', { UpdateKey, AccessCode });
}