export default {
  ru: {
    events: 'События',
    be_aware_of: 'быть в курсе',
  },

  en: {
    events: 'Events',
    be_aware_of: 'be aware of',
  },
}