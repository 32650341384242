<template>
  <div class="form-box__field">
    <div v-on="triggers">
      <slot/>
    </div>

    <input
      type="file"
      :ref="ref"
      :name="name"
      :accept="accept ? accept : false"
      style="display: none"
      @change="addFile"
    />
  </div>
</template>

<script>
export default {
  name: 'FormBoxFile',

  props: {
    value: {
      type: FileList,
    },

    refName: {
      type: String,
      required: false,
      default: '',
    },

    nameAttr: {
      type: String,
      required: false,
      default: '',
    },

    dropping: {
      type: Boolean,
      required: false,
      default: false,
    },

    accept: {
      type: String,
      required: false,
      default: '',
    },
  },

  computed: {
    ref() {
      if (!this.refName) {
        return `${this.name}-control`;
      }

      return this.refName;
    },

    name() {
      if (!this.nameAttr) {
        return `form-data-file-${this._uid}`;
      }

      return this.nameAttr;
    },

    // TODO: Перетаскивание файлов не работает! :(
    triggers() {
      let returnData = {};

      const clickHandler = () => {
        this.$refs[this.ref].click();
      };

      // const dropHandler = (eventName) => {
      //   this.$refs[this.ref].addEventListener(eventName, (e) => {
      //     e.stopPropagation();
      //     e.preventDefault();
      //     console.log('check trigger ' + eventName)
      //     // this.$refs[this.ref][eventName]();
      //   }, false);
      // };

      returnData['click'] = () => clickHandler();

      // if (this.dropping && this.determineDragAndDropCapable) {
      //   ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].map((eventName) => {
      //     returnData[eventName] = () => dropHandler(eventName)
      //   });
      // }

      // console.log('returnData:', returnData)

      return returnData;
    },
  },

  methods: {
    addFile(e) {
      this.$emit('input', e.target.files);
    },

    determineDragAndDropCapable() {
      const div = document.createElement('div');

      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
  },
};
</script>
