import account from './account';
import config from './config';
import news from './news';
import polls from './polls';
import openPolls from './openPolls';
import announcement from './announcement';
import additionalService from './additionalService';
import request from './request';
import openRequest from './openRequest';
import meter from './meter';
import oss from './oss';
import common from './common';
import amo from './amo';
import emergencyWorks from './emergencyWorks';

export default {
  account,
  config,
  news,
  polls,
  openPolls,
  announcement,
  additionalService,
  request,
  openRequest,
  meter,
  oss,
  common,
  amo,
  emergencyWorks,
};
