<template>
  <article class="cart-item">
    <div class="cart-item__card">
      <div class="cart-item__body">
        <h3 class="cart-item__title">{{ good.Name }}</h3>
        <ul class="price-list price-list--tablet">
          <li class="price-list__field">
            <label class="price-list__title">{{ $t('price') }}:</label>
            <span class="price-list__value">{{ good.Price ? good.Price.toFixed(2) : 0 }} ₽</span>
          </li>
          <li v-if="isGoodsType" class="price-list__field">
            <label class="price-list__title">{{ $t('weight') }}:</label>
            <span class="price-list__value">{{ weight }} {{ $t('kg') }}</span>
          </li>
          <li class="price-list__field">
            <label class="price-list__title">{{ $t('cost') }}:</label>
            <span class="price-list__value">{{ amount }} ₽</span>
          </li>
        </ul>
        <div class="cart-item__counter">
          <div class="counter">
            <base-button style-class="secondary-text" :disabled="!isEqualZero" @click="addToCart(-1)">
              <minus-sm-icon />
            </base-button>
            <form-box-input
              v-model="quantity"
              type="number"
              placeholder="0"
              :min="0"
              step="1"
              class="counter__input"
              @input="changeCart"
            />
            <base-button style-class="secondary-text" :disabled="!isLessRest" @click="addToCart(1)">
              <plus-sm-icon />
            </base-button>
          </div>
        </div>
      </div>
      <div class="cart-item__footer">
        <ul class="price-list">
          <li class="price-list__field">
            <label class="price-list__title">{{ $t('price') }}:</label>
            <span class="price-list__value">{{ good.Price ? good.Price.toFixed(2) : 0 }} ₽</span>
          </li>
          <li v-if="isGoodsType" class="price-list__field">
            <label class="price-list__title">{{ $t('weight') }}:</label>
            <span class="price-list__value">{{ weight }} {{ $t('kg') }}</span>
          </li>
          <li class="price-list__field">
            <label class="price-list__title">{{ $t('cost') }}:</label>
            <span class="price-list__value">{{ amount }} ₽</span>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script>
import cartMixin from '@/mixins/cart';
import { PlusSmIcon, MinusSmIcon } from '@vue-hero-icons/outline';
import BaseButton from '@/components/ui/BaseButton';
import FormBoxInput from '@/components/form/FormBoxInput';

export default {
  name: 'CartItem',

  components: {
    PlusSmIcon,
    MinusSmIcon,
    BaseButton,
    FormBoxInput,
  },

  mixins: [cartMixin],

  props: {
    good: {
      type: Object,
    },
  },

  computed: {
    weight() {
      if (this.good.Weight) {
        return this.good.Quantity > 0
          ? (this.good.Weight * this.good.Quantity).toFixed(1)
          : this.good.Weight.toFixed(2);
      }

      return 0;
    },

    amount() {
      if (this.good.Price) {
        return this.good.Quantity > 0 ? (this.good.Price * this.good.Quantity).toFixed(2) : this.good.Price.toFixed(2);
      }

      return 0;
    },

    quantity: {
      get() {
        return this.good.Quantity || 0;
      },
      set(quantity) {
        return quantity;
      },
    },
  },

  methods: {
    changeCart(e) {
      this.changeGoodCount({
        ID: this.good.ID,
        count: +e,
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

.cart-item
  margin-bottom: 16px

.cart-item__card
  padding: 16px
  background: $cBgMain
  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16), 0px 1px 2px rgba(60, 80, 119, 0.24)
  border-radius: 16px

  @media screen and (min-width: 768px)
    padding: 24px

.cart-item__body
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  align-items: center
  margin-bottom: 0

  @media screen and (min-width: 768px)
    flex-wrap: nowrap
    align-items: flex-start
  margin-bottom: 16px

.cart-item__title
  +TextMain($cTextMain)
  width: 100%
  flex-shrink: 0
  margin-bottom: 8px

  @media screen and (min-width: 768px)
    width: auto
    flex-shrink: 1
    margin-bottom: 0

.cart-item__counter
  margin-left: 0

  @media screen and (min-width: 768px)
    margin-left: 34px

.cart-item__footer
  display: none

  @media screen and (min-width: 768px)
    display: block

.cart-item
  .counter
    display: flex
    flex-wrap: nowrap
    align-items: center
    flex-shrink: 0

  .counter__input
    width: 70px
    margin: 0 8px

  .price-list
    display: flex
    flex-direction: column

    @media screen and (min-width: 768px)
      flex-direction: row
      justify-content: space-between

      &--tablet
        display: none

  .price-list__field
    display: inline-flex
    flex-direction: column

    @media screen and (min-width: 768px)
      display: inline

  .price-list__title
    +Typography($height: 24px, $color: $cLabel)
    margin-right: 8px

  .price-list__value
    +Typography($weight: 700, $height: 24px, $color: $cTextMain)
</style>
