export default {
  ru: {
    profile: 'Профиль',
    info: 'Информация',
    fio: 'ФИО',
    settings: 'Настройки',
    language: 'Язык',
    city: 'Город',
    managing_organization: 'Управляющая организация',

    avoid_paper_bills: 'Отказаться от бумажной квитанции',
    dont_save_password: 'Не сохранять пароль',
    dont_show_retired_devices: 'Не показывать выбывшие приборы',

    data_updated_successfully: 'Данные успешно обновлены!',
    error_updating_profile: 'Ошибка обновления данных профиля! Проверьте правильность заполнения полей.',
    field_required: 'Поле обязательно к заполнению',
    wrong_format: 'Неверный формат',
    fill_field: 'Заполните поле',
    email_set_successfully: 'Адрес электронной почты сохранен. Перенаправляем вас нас страницу оплаты.',
    email_modal_message: 'Укажите адрес электронной почты, если хотите получать подтверждения об оплате.',
    email_modal_email_error: 'Адрес электронной почты не будет сохранен.',

    connect: 'Подключить',
    connected: 'подключен',
    not_found: 'не найден',
  },

  en: {
    profile: 'Profile',
    info: 'Information',
    fio: 'Full name',
    settings: 'Settings',
    language: 'Language',
    city: 'City',
    managing_organization: 'Managing organization',

    avoid_paper_bills: 'Avoid paper bills',
    dont_save_password: 'Don\'t save password',
    dont_show_retired_devices: 'Don\'t show retired devices',

    data_updated_successfully: 'Data updated successfully!',
    error_updating_profile: 'Error updating profile data! Please check that the fields are filled in correctly.',
    field_required: 'This field is required',
    wrong_format: 'Wrong format',
    fill_field: 'Fill in the field',
    email_set_successfully: 'The email address has been saved. We redirect you to the payment page.',
    email_modal_message: 'Please provide your email address if you would like to receive payment confirmations.',
    email_modal_email_error: 'The email address will not be saved.',

    connect: 'Connect',
    connected: 'connected',
    not_found: 'not found',
  },
}