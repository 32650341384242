<template>
  <div class="accordion">
    <div
      v-for="(item, index) in items"
      :key="index"
      :ref="`accordionItem-${index}`"
      class="accordion__item"
      :class="{ 'accordion__item--active': activeItemIndex === index }"
    >
      <div class="accordion__header" @click="onToggleItem(index)">
        <slot name="header" :item="item" :index="index">
          <div class="accordion__title">
            {{ item.title }}
          </div>
        </slot>
        <chevron-down-icon class="accordion__toggle-icon" />
      </div>
      <div class="accordion__content">
        <slot name="content" :item="item" :index="index">
          {{ item.text }}
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from '@vue-hero-icons/outline';

export default {
  name: 'Accordion',

  components: {
    ChevronDownIcon,
  },

  props: {
    items: {
      // [{title: '', text: '' }]
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeItemIndex: null,
    };
  },

  created() {
    console.log('--- Accordion component ---')
    console.log('items:', this.items)
  },

  methods: {
    onToggleItem(index) {
      // console.log('--- onToggleItem methods ---')
      if (this.activeItemIndex === index) {
        return (this.activeItemIndex = null);
      }
      this.activeItemIndex = index;
      this.$emit('sm-accordion:toggle-item', index);

      // FIXME: Почему не работает??? :(
      // this.$nextTick(() => {
      //   const $item = this.$refs[`accordionItem-${index}`].$el;
      //   $item.scrollTop = 0;
      // });
    },
  },
};
</script>

<style lang="scss">
@import '../../sass/variables';
@import '../../sass/mixins';

.accordion__item {
  position: relative;

  margin-bottom: 16px;

  border-radius: 16px;

  box-shadow: 0px 1px 4px rgba(60, 80, 119, 0.16);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.accordion__header {
  display: flex;
  justify-content: space-between;

  width: 100%;
  padding: 12px;
  cursor: pointer;

  @media screen and (min-width: 768px) {
    padding: 12px 24px;
  }
}

.accordion__title {
  @include TextHeader(2);
}

.accordion__toggle-icon {
  // align-self: flex-start;
  align-self: center;
  flex-shrink: 0;
  margin-left: 24px;

  color: $cIconNeutral;

  transition: transform 0.3s;

  @media screen and (min-width: 768px) {
    align-self: center;
  }
}

.accordion__item--active .accordion__toggle-icon {
  transform: rotate(180deg);
}

.accordion__content {
  display: none;
  padding: 12px;

  transition: transform 0.3s;

  @media screen and (min-width: 768px) {
    padding: 12px 24px;
  }
}

.accordion__item--active .accordion__content {
  display: block;
}
</style>
