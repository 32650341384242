import axios from 'axios';

export function getNewsContent(news_id) {
  return axios.get('/News/Content/' + news_id);
}

export function getNewsImage(news_id) {
  return axios.get('/News/Image/' + news_id, { responseType: 'blob' });
}

export function getNewsFile(fileId) {
  return axios.get('/News/File/' + fileId, { responseType: 'blob' })
}
