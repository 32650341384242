import axios from 'axios';

export async function getLogo(id) {
  return await axios.get('/AdditionalServices/Logo/' + id, { responseType: 'blob' });
}

export async function getGoods(id) {
  return await axios.get(`/Shops/Goods?shopId=${id}&acx=${encodeURIComponent(axios.defaults.headers.common['acx'])}`);
}

export async function getGoodsImage(id) {
  return await axios.get(`/GoodsImage/${id}`, { responseType: 'blob' });
}

export async function getDescriptionImage(id) {
  return await axios.get(`/AdditionalServices/DescriptionImage/${id}`, { responseType: 'blob' });
}
