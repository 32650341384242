'use strict';

import Vue from 'vue';
import axios from 'axios';
import * as authService from '../services/auth.service';
import element from 'element-ui';
import qs from 'qs';

// Full config:  https://github.com/axios/axios#request-config

axios.interceptors.request.use((config) => {
  config.baseURL = window.appSettings.apiUrl; //process.env.VUE_APP_HOST || 'http://127.0.0.1:8000/';
  let token = authService.getAccessToken();

  if (token && token.length > 0) {
    config.headers.acx = token;
    config.headers['Content-Type'] = 'application/json';
  }

  config.headers.client = 'web';

  return config;
});

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

/* eslint-disable */
axios.interceptors.response.use(
  function (response) {
    if (response.data !== null && response.data.error && response.data.error.length) {
      element.Notification.error({
        position: 'top-left',
        title: 'Ошибка',
        message: response.data.error,
      });

      throw new Error(response.data.error);
    }
    return response;
  },
  function (error) {
    if (error.response.status !== 200 && error.response && error.response.data.Message) {
      element.Notification.error({
        title: 'Ошибка',
        message: error.response.data.Message,
      });
    }

    if (error.response.status === 401) {
      authService.resetAuthData();
      if (document.location.hash !== '#/login') {
        location.href = location.href.split('#')[0];
      }
    }

    console.error('HTTP error', error);

    throw error;
  },
);

axios.defaults.paramsSerializer = (params) => {
  return qs.stringify(params, { arrayFormat: 'brackets', encode: false });
};

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    return Promise.reject(error);
  },
);

Plugin.install = function (Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      },
    },
    $axios: {
      get() {
        return _axios;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
