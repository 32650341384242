<template>
  <section class="privilege-club">
    <section-header :title="$t('privilege_club') + '<br/>' +  $t('for_new_residents')"></section-header>

    <p class="privilege-club__text">{{ $t('advantageous_offers') }},<br/>{{ $t('discounts_promotions') }}</p>

    <base-button
      @click="
        $router.push({
          name: 'PrivilegeClub',
        })
      "
    >
      {{ $t('more_details') }}
    </base-button>
  </section>
</template>

<script>
import SectionHeader from '@/components/pages/common/SectionHeader';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'NotificationsSection',

  components: {
    SectionHeader,
    BaseButton,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/variables';
@import '../../../sass/mixins';

.privilege-club__text {
  @include TextMain();
}
</style>
