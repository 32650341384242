<template>
  <modal name="emailForPayModal" :addaptive="true" height="auto">
    <div class="email-modal__overlay" @click="onClose"></div>
    <modal-content @close="onClose" class="modal-content_testimony">
      <div class="form-box">
        <form class="form-box__form" @submit.prevent="onContinue">
          <fieldset class="form-box__fieldset">
            <legend class="form-box__fieldset-title">
              {{  $t('email_modal_message') }}
            </legend>
            
            <form-box-input
              class="email-modal__input"
              v-model="form.email"
              label="E-mail"
              autocomplete="off"
              :edit-button="!!form.email.length"
              :message="emailErrors"
              is-started-editable
              @form-box:save="testEmail"
              @input="clearEmailErrors"
            />
          </fieldset>
          <fieldset class="form-box__fieldset email-modal__button-list">
          <base-button type="submit" class="email-modal__button">
            {{ $t('continue') }}
          </base-button>
        </fieldset>
        </form>
      </div>
    </modal-content>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import ModalContent from '@/components/ui/Modal';
import FormBoxInput from '@/components/form/FormBoxInput';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'EmailForPayModal',
  components: { ModalContent, FormBoxInput, BaseButton },
  data() {
    return {
      form: {
        fio: '',
        email: '',
        language: 'ru',
      },
      emailErrors: '',
    };
  },
  computed: {
    isEmailError() {
      return !this.form.email ? false : !!this.emailErrors;
    },
  },
  mounted() {
    this.fillForm();
    const currentLanguage = localStorage.getItem('language');
    if (!currentLanguage) return;
    this.form.language = currentLanguage;
  },
  watch: {
    authUser() {
      this.fillForm();
    },
  },
  methods: {
    ...mapActions({
      updateProfile: 'account/updateProfile',
    }),
    fillForm() {
      this.form.email = this.authUser.email;
      this.form.fio = this.authUser.fio;
    },
    clearEmailErrors() {
      if (this.emailErrors) {
        this.emailErrors = ''
      }
    },
    testEmail() {
      if (!this.form.email) return
      /* eslint-disable */
      const emailPattern = new RegExp(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      /* eslint-enable */
      if (!emailPattern.test(this.form.email)) {
        this.emailErrors = `${this.$t('wrong_format')}. ${this.$t('email_modal_email_error')}`;
        return;
      }
      this.save();
    },
    save() {
        this.updateProfile(this.form)
          .then((result) => {
            if (!result?.isSucceed) return;
            if (this.initialLanguage !== this.form.language) {
              this.initialLanguage = this.form.language;

              localStorage.setItem('language', this.form.language);
              this.$i18n.locale = this.form.language;
            }
            this.$notify.success(this.$t('email_set_successfully'));
            setTimeout(() => {       
              this.onContinue();
            }, 1000);            
          }).catch((error) => {
            this.$notify.error(error);
          });
    },
    updateForm() {
      this.clearEmailErrors()
      this.fillForm()
    },
    onClose() {
      this.updateForm()
      this.$emit('close')
    },
    onContinue() {
      this.updateForm() 
      this.$emit('continue')
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'

.email-modal__overlay
  position: absolute
  top: -50vh
  left: -50vw
  width: 150vw
  height: 150vh
  z-index: -1

.email-modal__input 
  min-height: 110px
  ::v-deep .field__message
    padding: 0
    color: $cError

.email-modal__button-list 
  gap: 20px

  @media screen and (min-width: 576px)
    flex-direction: row

.email-modal__button 
  width: 100%
  
</style>
