<template>
  <div class="log-register">
    <div class="log-register__header">
      <h1 class="log-register__title">{{ content.title }}</h1>
      <div class="log-register__steps">
        <div
          v-for="s in [1, 2, 3]"
          :key="s"
          :class="[
            'step',
            { 'step--prev': s < step },
            { 'step--current': s === step },
            { 'step--next': s === step + 1 },
          ]"
        >
          <span class="step__number">{{ s }}</span>
          {{ $t('step') }} {{ s }}
        </div>
      </div>
    </div>

    <div class="log-register__form">
      <div v-loading="isLoading" class="form-box">
        <!--Шаг 1-->
        <form v-if="step === 1" class="form-box__form" :key="`step1-${step}`" @submit.prevent>
          <form-box-input
            v-mask="`+7 (###) ### ## ##`"
            v-model="form.phone"
            refName="phoneControl"
            :label="$t('telephone')"
            type="tel"
            placeholder="+7 (999) 999 99 99"
            :message="errors.phone"
            :focused="phoneIsFocus"
            @input="delete errors.phone"
          />

          <form-box-input
            refName="fioControl"
            :label="$t('fio')"
            type="text"
            :message="errors.fio"
            :focused="fioIsFocus"
            v-model="form.fio"
            @input="delete errors.fio"
          />

          <form-box-input
            v-if="config.requireBirthDate"
            refName="birthdayControl"
            :label="$t('date_of_birth')"
            type="text"
            :placeholder="$t('date_placeholder')"
            :message="errors.birthday"
            v-mask="`##.##.####`"
            v-model="form.birthday"
            @input="delete errors.birthday"
          />

          <form-box-checkbox
            v-model="consent"
            inputId="consent"
            refName="consentControl"
            :hasError="errors.consent"
            :message="errors.consent ? errors.consent : ''"
          >
            {{ $t('consent_to') }}
            <a tabindex="0" class="form-box__policy-privacy" @click.prevent="openPersonalDataPolitics">
              {{ $t('processing_of_personal_data') }}
            </a>
          </form-box-checkbox>

          <base-button tabindex="0" class="form-box__button" :disabled="disabledNextStep" @click="nextSmsCode">
            {{ $t('next') }}
          </base-button>
        </form>

        <!--Шаг 2-->
        <form v-if="step === 2" @submit.prevent class="form-box__form" :key="`step2-${step}`">
          <!-- Код доступа - form.code -->
          <form-box-input
            v-mask="`####`"
            refName="codeControl"
            :label="$t('access_code')"
            type="text"
            placeholder="0000"
            :message="errors.code"
            :showPopover="showCodePopover"
            :hasError="errors.code"
            v-model="form.code"
            @input="delete errors.code"
          >
            <template #popover>
              <popover
                :title="content.codePopover.title"
                :bodyText="content.codePopover.text"
                @close="showCodePopover = false"
              >
                <template #footer>
                  <base-button
                    style-class="secondary"
                    class="form-box__support-button"
                    @click="[openSupportModalChatWithousAuth(), (showCodePopover = false)]"
                  >
                    {{ content.codePopover.button }}
                  </base-button>
                </template>
              </popover>
            </template>
          </form-box-input>

          <p v-show="!(!isDisabledSendButton || (isDisabledSendButton && !disabledNextStep))" class="form-box__note">
            {{ $t('repeat_code_request_after') }}
            <span>{{ codeResendTimer }}</span>
            <br />
          </p>
          <p v-if="showFlashCodeForm" class="form-box__note">
            {{ $t('enter_last_4_digits') }}
            <strong>{{ form.phone }}</strong
            >.
          </p>
          <p v-else v-show="disabledNextStep" class="form-box__note">
            {{ $t('to_receive_access_code') }} {{ form.phone }} {{ $t('and_tell_you_the_code') }}
          </p>

          <p class="form-box__note">{{ $t('wrong_phone_number') }}</p>
          <a class="form-box__link" @click.stop="step = 1">{{ $t('return_on_start') }}</a>

          <template v-if="!showFlashCodeForm && form.code.length < 4">
            <form-box-radioset
              v-model="confirmType"
              :list="[
                { title: $t('call_with_code'), value: 'call' },
                { title: $t('code_in_sms'), value: 'sms' },
              ]"
              :classModificators="['register']"
            />

            <base-button
              tabindex="0"
              class="form-box__button"
              :disabled="isDisabledSendButton"
              @click="requestConfirmCode"
            >
              {{ $t('request_code') }}
            </base-button>
          </template>

          <base-button
            v-show="!disabledNextStep || (disabledNextStep && showFlashCodeForm)"
            tabindex="0"
            class="form-box__button"
            :disabled="disabledNextStep"
            @click="checkSmsCode"
          >
            {{ $t('next') }}
          </base-button>
        </form>

        <!--Шаг 3-->
        <form v-if="step === 3" @submit.prevent class="form-box__form" :key="`step3-${step}`">
          <form-box-input
            refName="passwordControl"
            :label="$t('password')"
            type="password"
            :message="errors.password"
            v-model="form.password"
            @input="delete errors.password"
          />

          <form-box-input
            refName="repeatPasswordControl"
            :label="$t('confirm_password')"
            type="password"
            :message="errors.repeatPassword"
            v-model="form.repeatPassword"
            @input="delete errors.repeatPassword"
          />

          <base-button :disabled="!isValidateStep3" class="form-box__button" @click="onRegister">
            {{ $t('register') }}
          </base-button>
        </form>

        <base-button
          tabindex="0"
          style-class="text"
          class="form-box__login-link"
          @click="$router.push({ name: 'Login' })"
        >
          {{ $t('enter') }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { yearFromDate, monthFromDate, dayFromDate, daysInMonth } from '@/services/common';
import FormBoxInput from '@/components/form/FormBoxInput';
import FormBoxCheckbox from '@/components/form/FormBoxCheckbox';
import FormBoxRadioset from '@/components/form/FormBoxRadioset';
import Popover from '@/components/ui/Popover';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'Register',

  components: {
    FormBoxInput,
    FormBoxCheckbox,
    FormBoxRadioset,
    Popover,
    BaseButton,
  },

  data() {
    return {
      form: {
        phone: '',
        fio: '',
        password: '',
        repeatPassword: '',
        code: '',
        birthday: '',
      },
      phoneIsFocus: false,
      fioIsFocus: false,
      consentIsFocus: false,
      confirmTypeCallIsFocus: false,
      confirmTypeSmsIsFocus: false,
      errors: {},
      showCodePopover: false,
      codeResendTimer: 0,
      isDisabledSendButton: false,
      consent: false,
      step: 1,
      error: '',
      confirmType: 'call',
      showFlashCodeForm: true,
      isLoading: false,
    };
  },

  computed: {
    content() {
      return {
        title: this.$t('registration'),
        codePopover: {
          title: this.$t('didnt_receive_the_code'),
          text: this.$t('help_you_register'),
          button: this.$t('write_to_technical_support'),
        },
      };
    },

    step1ValidationError() {
      if (!this.form.fio || !this.form.phone || (this.config.requireBirthDate && !this.form.birthday)) {
        return this.$t('to_continue_must_fill_all_fields');
      }

      if (this.config.requireBirthDate) {
        if (this.form.birthday.length !== 10) {
          return this.$t('date_of_birth_incorrect');
        }

        const year = yearFromDate(this.form.birthday);
        if (year < 1900) {
          return this.$t('year_of_birth_cannot_be_less');
        }
        if (year > new Date().getFullYear()) {
          return this.$t('year_of_birth_cannot_be_greater');
        }

        var month = monthFromDate(this.form.birthday);
        if (month < 1) {
          return this.$t('month_of_birth_cannot_be_less');
        }
        if (month > 12) {
          return this.$t('month_of_birth_cannot_be_more');
        }

        var day = dayFromDate(this.form.birthday);
        if (day < 1) {
          return this.$t('day_of_birth_date_cannot_be_less');
        }
        var monthDays = daysInMonth(month, year);
        if (day > monthDays) {
          return this.$t('day_of_birth_month_cannot_be_greater') + ' ' + monthDays;
        }
      }

      if (!this.consent) {
        return this.$t('to_continue_must_agree');
      }

      return '';
    },

    isValidateStep3() {
      return (
        this.form.password.length > 0 &&
        this.form.repeatPassword.length > 0 &&
        this.form.password === this.form.repeatPassword
      );
    },

    disabledNextStep() {
      if (
        this.step === 1 &&
        (!this.form.fio ||
          this.form.phone.length <= 17 ||
          !this.consent ||
          this.errors.fio ||
          this.errors.phone)
      ) {
        return true;
      }

      if ((this.step === 2 && this.form.code.length < 4) || this.errors.code) {
        return true;
      }

      if (this.step === 3 && (!this.form.password || !this.form.repeatPassword)) {
        return true;
      }

      return false;
    },
  },

  created() {
    this.$root.$on('agree', this.onDataPoliticsAgree);
    this.$root.$on('not-agree', this.onDataPoliticsNotAgree);
  },

  mounted() {
    if (this.$route.query.phone && this.$route.query.phone.length === 10) {
      this.form.phone = this.$route.query.phone;
      this.fioIsFocus = true;
    } else {
      this.phoneIsFocus = true;
    }

    this.$nextTick(() => {
      window.addEventListener('keyup', (e) => {
        if (e.code === 'Space') {
          if (this.confirmTypeCallIsFocus) {
            this.confirmType = 'call';
          }

          if (this.confirmTypeSmsIsFocus) {
            this.confirmType = 'sms';
          }
        }
      });
    });
  },

  destroyed() {
    this.$root.$off('agree');
    this.$root.$off('not-agree');
  },

  methods: {
    ...mapActions({
      registerByPhone: 'account/registerByPhone',
      login: 'account/login',
      getRequestAccessCodeAction: 'account/getRequestAccessCode',
      sendCheckCodeSMS: 'account/sendCheckCodeSMS',
      checkAccessCode: 'account/checkAccessCode',
      requestAccessCode: 'account/requestAccessCode',
    }),

    onDataPoliticsAgree() {
      this.consent = true;
    },

    onDataPoliticsNotAgree() {
      this.consent = false;
    },

    nextSmsCode() {
      const error = this.step1ValidationError;

      if (error) {
        this.$notify.error({
          position: 'top-left',
          title: this.$t('error'),
          message: error,
        });
        return false;
      }

      ++this.step;

        this.getFlashCallCode();
    },

    setCodeResendTimer() {
      this.codeResendTimer = 60;
      this.isDisabledSendButton = true;
      let timeout = 60000;
      let interval = setInterval(() => {
        this.codeResendTimer -= 1;
      }, 1000);

      setTimeout(() => {
        clearInterval(interval);
        this.isDisabledSendButton = false;
        this.codeResendTimer = 0;
        this.showFlashCodeForm = false;
      }, timeout);
    },

    getRequestAccessCode() {
      this.isLoading = true;

      this.getRequestAccessCodeAction({
        phone: this.form.phone,
      }).then(() => {
        this.setCodeResendTimer();
        this.$notify({
          position: 'top-left',
          title: this.$t('registration_code'),
          message: this.$t('wait_for_the_call'),
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },

    getSMSCode() {
      this.isLoading = true;

      this.sendCheckCodeSMS({
        phone: this.form.phone,
      }).then(() => {
        this.setCodeResendTimer();
        this.$notify({
          position: 'top-left',
          title: this.$t('registration_code'),
          message: this.$t('verification_code_sent_via_sms'),
        });
      }).finally(() => {
        this.isLoading = false;
      });
    },

    getFlashCallCode() {
      if(this.codeResendTimer) return;

      this.requestAccessCode({
        phone: this.form.phone,
        dontCheck: true,
      }).then(() => {
        this.setCodeResendTimer();
        this.showFlashCodeForm = true;
      }).finally(() => {
        this.isLoading = false;
      });
    },

    checkSmsCode() {
      this.isLoading = true;

      this.checkAccessCode({
        phone: this.form.phone.match(/(\d+)/g).join(''),
        code: this.form.code,
      }).then((result) => {
        if (result.IsCorrect) {
          ++this.step;
        } else {
          this.errors['code'] = this.$t('code_incorrect');
          this.showCodePopover = true;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    },

    onRegister() {
      this.isLoading = true;

      if (this.isValidateStep3) {
        this.registerByPhone({
          ...this.form,
          phone: this.form.phone.match(/(\d+)/g).join(''),
        }).then((result) => {
            if (result.isSucceed) {
              this.onLogin();
            }
          }).catch((error) => {
            this.$notify.error(error);
          }).finally(() => {
            this.isLoading = false;
          });
      }
    },

    onLogin() {
      this.isLoading = true;

      this.login({
        phone: this.form.phone.match(/(\d+)/g).join(''),
        password: this.form.password,
      }).then(() => {
          this.$router.push({ name: 'Home' });
        }).catch(() => {
          this.$router.push({ name: 'Login' });
        }).finally(() => {
          this.isLoading = true;
        })
    },

    requestConfirmCode() {
      if (this.confirmType === 'call') {
        this.getRequestAccessCode();
      }

      if (this.confirmType === 'sms') {
        this.getSMSCode();
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../sass/variables"
@import "../sass/mixins"
@import "../sass/pages/login-register"
@import "../sass/components/form/form"

.form-box__button
  &:not(:last-child)
    margin-bottom: 24px

.form-box__login-link
  width: 100%

.form-box__link
  cursor: pointer

.form-box__support-button
  width: 100%
  margin-top: 24px

.form-box__policy-privacy
  +TextMain()
  +Transition((color))

  font-weight: 600

  &:hover, &:focus
    color: $cAccent !important
    text-decoration: none
</style>
