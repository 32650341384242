<template>
  <div class="meters-page">
    <page-header :title="$t('readings')" />

    <div v-loading="!accounts" class="meters-page__container">
      <template v-if="accounts">
        <template v-if="accounts.length">
          <!-- accounts selector -->
          <section class="meters-page__accounts">
            <form-box-select
              v-model="selectedIdent"
              :label="$t('address')"
              :list="groupAccounts"
              valuePropName="Ident"
              keyPropName="AccountID"
              :allowSelectAll="true"
            >
              <template #selectedOption="{ item }">
                {{ item.Address ? item.Address : item.AccountType }}
              </template>
              <template #option="{ item }">
                {{ item.Address ? item.Address : item.AccountType }}
              </template>
            </form-box-select>
          </section>

          <!-- Meters list -->
          <section 
            v-loading="isLoadingMeters"
            class="meters-page__list-container"
          >
            <!-- <template v-if="meterIDs"> -->
            <template v-if="meters.length">
              <div
                v-if="filteredMeters.length"
                class="meters-page__list"
              >
                <meters-list
                  :items="filteredMeters"
                  @meters-list:values-sent="getMetersList"
                  @meters-list:removed-value="getMetersList"
                />
              </div>

              <!-- Warning -->
              <div v-else class="meters-page__placeholder">
                <div class="placeholder">
                  <figure class="placeholder__meter">
                    <img :src="resolveUrl('/img/static/meter.png')" />
                  </figure>
                  <div class="placeholder__caption">
                    <h2 class="placeholder__title">
                      {{ $t('no_meters') }}
                    </h2>
                    <p class="placeholder__attention">
                      {{ $t('no_metering_devices_registered') }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
          </section>
        </template>

        <section v-else class="meters-page__add-account">
          <add-account-ident :text="$t('enter_readings')" />
        </section>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import pageHeader from '@/components/pages/common/PageHeader';
import addAccountIdent from '@/components/pages/common/AddAccountIdent';
import FormBoxSelect from '@/components/form/FormBoxSelect';
import metersList from '@/components/pages/meters/MetersList';

export default {
  name: 'Meters',

  components: {
    pageHeader,
    addAccountIdent,
    FormBoxSelect,
    metersList,
  },

  data() {
    return {
      //accountInfoIDs: null,
      selectedIdent: null,

      meterIDs: null,

      isLoading: true,
      isLoadingAccounts: true,
      isLoadingMeters: true,
      showTestimonyModal: false,
      activeTestimonyModalId: null,

      selectedMeter: null,
      showNoticeModal: false,
      showConfirmationModal: false,

      selectedRequestInfo: {},
    };
  },

  computed: {
    // ...mapState(['additionalService', 'meter']),
    ...mapState({
      additionalService: ({ additionalService }) => additionalService.services || [],
      meters: ({ meter }) => meter.meters || [],
    }),
    ...mapGetters({
      getMeters: 'meter/getMeters',
      getMeterById: 'meter/getMeterById',
      accounts: 'account/getAllInfo',
      getAccountSettings: 'account/getAccountSettings',
    }),

    groupAccounts() {
      return this.accounts
        .filter((x, i, self) => {
          return self.findIndex((t) => t.Address == x.Address) === i;
        })
        .filter((account) => {
          return !this.getAccountSettings(account.AccountID).HideOnMetersPage;
        })
        .sort((a, b) => a.Ident - b.Ident);
    },

    selectedAccountInfo() {
      if (!this.selectedIdent) {
        return '';
      }

      return this.accounts
        .filter((account) => {
          // if (account.OriginalIdent) {
            // return account.OriginalIdent === this.selectedIdent;
            return account.Ident === this.selectedIdent || account.OriginalIdent === this.selectedIdent;
          // }

          // const address = this.accounts.find(account => account.Ident === this.selectedIdent).Address;

          // return account.Address === address;
        });
    },

    // meters() {
    //   return this.getMeters(this.meterIDs);
    // },

    meterValues() {
      return this.$store.state.meter.meterValues;
    },

    filteredMeters() {
      const meters = this.meters;
      const selectedAccountInfo = this.selectedAccountInfo;
      const authUser = this.authUser;
      let currentFilteredMetters = meters;

      if (selectedAccountInfo.length) {
        currentFilteredMetters = [];

        selectedAccountInfo.map((selectedAccount) => {
          const filteredMetersByIdent = meters.filter((meter) => {
            return (
              meter.Ident === selectedAccount.Ident ||
              (
                meter.Ident !== selectedAccount.Ident &&
                meter.Address === selectedAccount.Address
              )
            );
          });
          
          if (filteredMetersByIdent.length) {
            currentFilteredMetters = [
              ...currentFilteredMetters, 
              ...filteredMetersByIdent
            ];
          }
        });

        const unicMeters = []
        const unicMetersId = new Set();

        for (let i = 0; i < currentFilteredMetters.length; i++) {
          const currentMeter = currentFilteredMetters[i];

          if (unicMetersId.has(currentMeter.ID)) continue;

          unicMetersId.add(currentMeter.ID);
          unicMeters.push(currentMeter);
        }

        currentFilteredMetters = unicMeters;
      }

      if (authUser?.dontShowRetiredDevices) {
        currentFilteredMetters = currentFilteredMetters.filter(meter => !meter.IsDisabled)
      }

      return currentFilteredMetters;
    },

    verificationService() {
      // let service = Object.entries(this.additionalService.services).find(
      let service = Object.entries(this.services)
        .find(item => {
          return item[1].Name === 'Поверка (замена) прибора';
        });
      if (service) {
        return service[1];
      }
      return null;
    },

    isNotAllowedVerification() {
      const foundedMeter = this.filteredMeters.find(meter => {
        return meter.ExistVerificationRequest;
      });
      //Если есть хоть один счетчик с ExistVerificationRequest=true
      //То отключаем для всего ЛС все кнопки заказа поверки
      return !!foundedMeter;
    },
  },

  created() {
    this.$root.$on('updated-account-info', this.initPage);
    this.$root.$on('deleted-account-info', this.deleteLocalAccountInfo);
    this.$root.$on('added-meter', this.getMetersList);
  },

  mounted() {
    this.initPage();
    this.getEventBlockData();
  },

  destroyed() {
    this.$root.$off('updated-account-info');
    this.$root.$off('deleted-account-info');
    this.$root.$off('added-meter');
  },

  methods: {
    ...mapActions({
      getEventBlockData: 'news/getEventBlockData',
      newRequest: 'request/newShopRequest',
      getRequestDetails: 'request/getRequestDetails',
      getInfo: 'account/getInfo',
      createRequestOnMeterRecheck: 'meter/createRequestOnMeterRecheck',
      getMetersListAction: 'meter/getMetersList',
      getMeterValuesAction: 'meter/getMeterValues',
      deleteMeterValueAction: 'meter/deleteMeterValue',
    }),

    initPage() {
      this.getAccountInfo();
      this.getMetersList();
    },

    getAccountInfo() {
      this.isLoadingAccounts = true;

      this.getInfo()
        .finally(() => {
          this.isLoadingAccounts = false;
        });
    },

    deleteLocalAccountInfo(id) {
      if (!this.accounts) {
        return;
      }
      let index = this.accounts.map(x => x.AccountID).indexOf(id);
      if (index !== -1) this.accounts.splice(index, 1);
    },

    getMetersList() {
      this.isLoadingMeters = true;

      // this.meterIDs = null;

      this.getMetersListAction()
        // .then(result => {
        .then(() => {
          // this.meterIDs = result.keys;

          this.isLoadingMeters = false;
        });
    },

    getMeterValues(id, uniqueNum) {
      this.getMeterValuesAction({ meterUniqueNum: uniqueNum })
        .then(() => {
          this.activeTestimonyModalId = id;
        });
    },

    deleteMeterValue(id) {
      if (
        confirm('Действительно удалить переданные показания по прибору учета?')
      ) {
        this.deleteMeterValueAction({ ID: id })
          .then(() => {
            this.getMetersList();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },

    getIcon(meterResource) {
      if (
        ['Горячая вода', 'Горячее водоснабжение', 'Горячее ВС', 'ГВС'].includes(
          meterResource,
        )
      ) {
        return ['glipf-drop'];
      } else if (
        [
          'Холодная вода',
          'Холодное водоснабжение',
          'Холодное ВС',
          'ХВС',
        ].includes(meterResource)
      ) {
        return ['glipf-drop', 'glipf-drop_mod'];
      } else if (meterResource.match(/(электр)/i) !== null) {
        return ['glipf-electricity'];
      } else if (meterResource.match(/([Гг]аз)/i) !== null) {
        return ['glipf-gas-testimony'];
      } else if (['Тепловая энергия'].includes(meterResource)) {
        return ['glipf-thermo-testimony'];
      } else {
        return ['glipf-unknown_meter'];
      }
    },

    getMeterValueKindIcon(meterValueKind) {
      if (meterValueKind == 'Передано') {
        return ['icon_is-sent'];
      } else if (meterValueKind == 'Учтено') {
        return ['icon_checked'];
      } else {
        return [''];
      }
    },

    getDiffCheckupDate(nextCheckupDate) {
      let diff;
      if (nextCheckupDate) {
        let checkupDate = nextCheckupDate.split('.');
        checkupDate = new Date(checkupDate[2], parseInt(checkupDate[1]) - 1, checkupDate[0]);
        const nowDate = new Date();
        diff = (checkupDate - nowDate) / (1000 * 60 * 60 * 24) + 1;
      }
      if (diff) {
        return diff;
      } else {
        diff = 9999;
      }

      return diff;
    },

    openVerificationConfirmationModal(meterId) {
      if (!this.isNotAllowedVerification) {
        this.selectedMeter = this.getMeterById(meterId);

        this.openDialog({
          // title: 'Создать заявку на поверку?',
          text: `Создать заявку на поверку?`,
          buttons: [
            {
              type: 'additional',
              title: 'Нет',
              handler: () => {
                this.cancelDialog();
              },
            },
            {
              type: 'base',
              title: 'Да',
              handler: () => {
                this.onRequestVerification();
                this.cancelDialog();
              },
            },
          ],
        });
      }
    },

    closeVerificationConfirmationModal() {
      this.$modal.hide('createCheckupRequest');
    },

    onRequestVerification() {
      this.createRequestOnMeterRecheck(this.selectedMeter.ID)
        .then(response => {
          //this.getMetersList();
          if (response.data.isSucceed) {
            return this.getRequestDetails(response.data.id)
              .then(result => {
                this.selectedRequestInfo = {
                  RequestNumber: result.RequestNumber,
                };
              });
          } else {
            throw new Error(response.data.error);
          }
        })
        .then(() => {
          this.openDialog({
            title: 'Заявка на поверку успешно создана!',
            text: `
              Номер заявки: ${this.selectedRequestInfo.RequestNumber}. <br />
              В ближайшее время с вами свяжется специалист для уточнения стоимости,
              даты и времени поверки.
            `,
            buttons: [
              {
                type: 'base',
                title: 'Закрыть',
                handler: this.cancelDialog,
              },
            ],
            timeout: 10000,
          });
        })
        .finally(() => {
          this.getMetersList();
        });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../sass/variables'
@import '../sass/mixins'

.meters-page
  .modal__text
    +TextHeader(1)
    text-align: center
    margin-bottom: 24px

  .modal__success-text
    +TextParagraph()
    margin-button: 0
    text-align: center

  .modal__buttons
    display: flex
    flex-wrap: nowrap

  .modal__button
    +Button('additional')
    flex:
      shrink: 1
      basis: 50%

    &--reject
      margin-right: calc(24px / 2)

    &--primary
      +Button('base')
      margin-left: calc(24px / 2)

  .placeholder
    display: flex
    flex-wrap: wrap
    align-items: center
    margin-top: 40px

    @media screen and (min-width: 576px)
      flex-wrap: nowrap

  .placeholder__meter
    +ResponsiveImg(100%, 160px)

    flex-shrink: 0
    margin-bottom: 24px

    @media screen and (min-width: 576px)
      +Size(160px, 160px)
      margin-bottom: 0
  .placeholder__caption
    padding-left: 0

    @media screen and (min-width: 576px)
      padding-left: 24px

    @media screen and (min-width: 768px)
      padding-left: 40px

  .placeholder__title
    +TextHeader(2)

    text-align: center
    margin-bottom: 8px

    @media screen and (min-width: 576px)
      text-align: left

  .placeholder__attention
    +TextMain($cTextMain)

    text-align: center
    margin-bottom: 0

    @media screen and (min-width: 576px)
      text-align: left

.meters-page__container
  +ContentContainer()

.meters-page__list
  margin-bottom: 100px

.meters-page__item
  &:not(:last-child)
    margin-bottom: 16px
</style>
