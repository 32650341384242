export default {
  ru: {
    shop: 'Магазин',
    service: 'Сервис',
    by_price: 'По цене',
    weight: 'Вес',
    total_payable: 'Итого к оплате',
    place_order: 'Оформить заказ',
  },

  en: {
    shop: 'Shop',
    service: 'Service',
    by_price: 'By price',
    weight: 'Weight',
    total_payable: 'Total payable',
    place_order: 'Place an order',
  },
}