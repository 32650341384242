<template>
  <section class="notifications">
    <section-header :title="$t('notifications')">
      <template v-slot:icon>
        <bell-icon class="icon" />
      </template>
    </section-header>

    <div
      v-if="items && items.length"
      class="notifications__list"
    >
      <!-- <div
      v-if="items.length" 
      class="notifications__list"
    > -->
      <div
        v-for="(item, i) in items"
        :key="`notification-${i}`"
        class="notifications__item"
      >
        <card
          v-if="!item.IsEmergencyWork"
          :title="item.Header"
          :text="truncateText(item.Text, 100)"
          :detail-link="{
      name: 'Announcement',
      params: { id: item.ID },
    }"
          :card-like-link="true"
          class="notifications__card"
        />
        <card
          v-else
          :title="item.Header"
          :text="item.Text"
          :card-like-link="false"
          class="notifications__card notifications__card--emergency-work"
        />
      </div>
    </div>

    <alert
      v-else
      type="warning"
    >{{ $t('no_notifications') }}</alert>
  </section>
</template>

<script>
import { BellIcon } from '@vue-hero-icons/outline';
import SectionHeader from '@/components/pages/common/SectionHeader';
import Card from '@/components/ui/Card';
import Alert from '@/components/ui/Alert';

export default {
  name: 'NotificationsSection',

  components: {
    SectionHeader,
    BellIcon,
    Card,
    Alert,
  },

  props: {
    items: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
  },
};
</script>

<style
  lang="scss"
  scoped
>
@import '../../../sass/variables';

.notifications__list {
  display: flex;
  flex-wrap: wrap;
}

.notifications__item {
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 100%;
  max-width: 100%;
  padding-left: calc(16px / 2);
  padding-right: calc(16px / 2);
  margin-bottom: 16px;

  @media screen and (min-width: 768px) {
    flex-basis: calc(100% / 2);
    max-width: calc(100% / 2);
    margin-bottom: 20px;

    &:nth-child(1),
    &:nth-child(2n)+.notifications__item {
      padding-left: 0;
    }

    &:nth-child(2n) {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 992px) {
    padding-left: calc(20px / 2);
    padding-right: calc(20px / 2);
  }

  @media screen and (min-width: 1200px) {
    flex-basis: calc(100% / 4);
    max-width: calc(100% / 4);
    padding-left: calc(16px / 2);
    padding-right: calc(16px / 2);

    &:nth-child(2n)+.notifications__item {
      padding-left: calc(16px / 2);
    }

    &:nth-child(2n) {
      padding-right: calc(16px / 2);
    }

    &:nth-child(1),
    &:nth-child(4n)+.notifications__item {
      padding-left: 0;
    }

    &:nth-child(4n) {
      padding-right: 0;
    }
  }

  @media screen and (min-width: 1400px) {
    padding-left: calc(20px / 2);
    padding-right: calc(20px / 2);

    &:nth-child(2n)+.notifications__item {
      padding-left: calc(20px / 2);
    }

    &:nth-child(2n) {
      padding-right: calc(20px / 2);
    }
  }
}

.notifications__card {
  height: 100%;

  &:not(.notifications__card--emergency-work) {
    background: {
      image: url('../../../../public/img/static/home-page/notification-water-neutral.svg');
      repeat: no-repeat;
      position: top right;
      size: auto;
    }
  }
}

.notifications__card--emergency-work {
  background-color: $cRed;

  ::v-deep {
    .card__name {
      color: $cWhite;
    }

    .card__text {
      color: $cWhite;
    }
  }
}
</style>
