<template>
  <div class="popover" @clickout="allowCloseByClickout ? $emit('close') : false">
    <x-icon v-if="!title && closeIcon" class="popover__close popover__close--absolute" @click="$emit('close')" />

    <!-- Header -->
    <template v-if="!!title">
      <div class="popover__header">
        <h5 class="popover__title">
          {{ title }}
        </h5>
        <x-icon v-if="closeIcon" class="popover__close" @click="$emit('close')" />
      </div>
    </template>

    <!-- Body -->
    <div class="popover__body">
      <template v-if="!!bodyText">
        <p class="popover__text" v-html="bodyText"></p>
      </template>
      <template v-else>
        <slot name="body" />
      </template>
    </div>

    <!-- Footer -->
    <div class="popover__footer">
      <template v-if="!!footerText">
        <p class="popover__text" v-html="footerText"></p>
      </template>
      <template v-else>
        <slot name="footer" />
      </template>
    </div>
  </div>
</template>

<script>
import { XIcon } from '@vue-hero-icons/outline';

export default {
  name: 'Popover',

  components: {
    XIcon,
  },

  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
    closeIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    bodyText: {
      type: String,
      required: false,
      default: '',
    },
    footerText: {
      type: String,
      required: false,
      default: '',
    },
    allowCloseByClickout: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../sass/variables'
@import '../../sass/mixins'

.popover
  min-width: 270px
  max-width: 370px
  padding: 16px 24px
  background-color: $cBgMain
  border-radius: 16px
  box-shadow: 0 8px 16px 0 rgba(101,107,119,16%)
  box-sizing: border-box
  position: relative

  &::before
    content: ""
    display: block
    +Size(13px, 47px)
    position: absolute
    top: 0
    left: 50%
    transform: translate(-50%, calc(-100% + ((47px / 3) + 2px))) rotate(90deg)
    z-index: 100
    background:
      image: url('/img/static/popover-arrow.svg')
      repeat: no-repeat
      position: left center
      size: 100%

    @media screen and (min-width: map-get($bp, 'desktop-small'))
      top: 21px
      left: -12px
      transform: none

  @media screen and (min-width: map-get($bp, 'desktop-small'))
    width: 370px

.popover__header
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 16px

.popover__title
  +TextHeader(2, $cTextMain)

.popover__close
  color: $cIconNeutral
  cursor: pointer
  flex-shrink: 0

  &:hover, &:focus
    color: $cAccent

  &--absolute
    position: absolute
    top: 10px
    right: 10px

.popover__text
  +TextMain()
  margin-bottom: 16px

  &:last-child
    margin-bottom: 0

.popover__footer

.popover__button
  +Button('blue')
  width: 100%
  border-radius: 12px
</style>
