<template>
  <Modal @close="notAgree()" class="modal-content_testimony">
    <div class="testimony-item_header">{{ $t('operator_policy_regarding_processing_of_personal_data') }}</div>
    <div
      style="max-width: 600px; max-height: 400px; overflow-y: scroll; text-align: justify; padding: 5px; margin-bottom: 20px"
      v-html="text"
    ></div>
    <div
      style="display: flex; justify-content: space-around;"
    >
      <base-button @click="onAgree()">{{ $t('agree') }}</base-button>
      <base-button @click="notAgree()">{{ $t('dont_agree') }}</base-button>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/ui/Modal';
import * as commonApi from '@/api/common';
import BaseButton from "@/components/ui/BaseButton";

export default {
  name: 'personalDataPoliticsModal',
  components: { Modal, BaseButton },
  data() {
    return {
      text: '',
    };
  },
  mounted() {
    this.loadPolicy();
  },
  methods: {
    loadPolicy() {
      var host = window.location.protocol + '//' + window.location.hostname;
      // getPersonalDataPolicy
      commonApi.getPersonalDataPolicy(host).then((response) => {
        this.text = response.data
          .split('\n')
          .map(
            (x) =>
              '<p>' +
              x
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;') +
              '</p>',
          )
          .join('');
      });
    },

    onAgree() {
      this.$root.$emit("agree");
      this.$emit('close');
    },

    notAgree() {
      this.$root.$emit("not-agree");
      this.$emit('close');
    }
  },
};
</script>
