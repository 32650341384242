export default {
  ru: {
    polls: 'Опросы',
    all_polls: 'Все опросы',
    show_completed_polls: 'Показать пройденные опросы',
    no_polls_to_complete: 'Нет опросов для прохождения',
    no_polls_to_complete_at_this_time: 'В данный момент нет опросов для прохождения.',
    poll_already_completed: 'Опрос уже пройден',
    poll_not_found: 'Опрос не найден',

    failed_to_complete_poll: 'Не удалось завершить опрос. Попробуйте позже',
    you_are_passing_now: 'Сейчас проходите',
    continue_passing: 'Продолжить прохождение',
    pass_poll: 'Пройти опрос',

    submit_answers: 'Отправить ответы',
    viewing_answers: 'Просмотр ответов',
    show_answers: 'Показать ответы',

    votes: 'голосов',
  },

  en: {
    polls: 'Polls',
    all_polls: 'All polls',
    show_completed_polls: 'Show completed polls',
    no_polls_to_complete: 'No polls to complete',
    no_polls_to_complete_at_this_time: 'There are no polls to complete at this time.',
    poll_already_completed: 'The poll has already been completed',
    poll_not_found: 'Poll not found',

    failed_to_complete_poll: 'Failed to complete the poll. Try later',
    you_are_passing_now: 'You are passing now',
    continue_passing: 'Continue passing',
    pass_poll: 'Pass the poll',

    submit_answers: 'Submit answers',
    viewing_answers: 'Viewing answers',
    show_answers: 'Show answers',

    votes: 'votes',
  },
}