<template>
  <perfect-scrollbar v-if="items" tag="section" class="requests" ref="scrollSection">
    <template v-if="items">
      <article
        v-for="(item, i) in items"
        :key="i"
        :class="['request', { 'request--active': item.ID === +$route.params.requestID }]"
      >
        <div class="request__header" @click="openRequest(item.ID)">
          <time class="request__date">{{ item.Added }}</time>
          <badge :caption="item.Status === 9 ? `${$t('reopened')}` : item.Status" :type="getStatusType(item.StatusID)" />
        </div>

        <div class="request__body" @click="openRequest(item.ID)">
          <h2 class="request__title">
            <strong v-if="!item.IsReadedByClient">• </strong>
            {{ truncateText(item.Name || item.Text, 100) }}
          </h2>
          <em class="request__number">№ {{ item.RequestNumber }}</em>
          <address class="request__contacts">
            <ul class="contacts">
              <li class="contacts__item">
                {{ $t('address') }}: <span>{{ item.Address }}</span>
              </li>
              <!-- <li>
                Телефон: <span></span>
              </li> -->
            </ul>
          </address>
        </div>

        <div class="request__footer">
          <!-- TODO: Таких полей нет -->
          <!-- <p>
            Срок исполнения <span></span>
          </p>
          <p>
            Сумма <span></span>
          </p> -->
          <base-button
            v-if="canCancelingRequest(item.ID)"
            style-class="secondary"
            width="170px"
            height="40px"
            @click="cancelRequest(item.ID, item.RequestNumber)"
          >
            {{ $t('cancel_request') }}
          </base-button>
        </div>
      </article>
    </template>
  </perfect-scrollbar>
</template>

<script>
import { mapActions } from 'vuex';
import Badge from '@/components/ui/Badge';
import BaseButton from '@/components/ui/BaseButton';

export default {
  name: 'RequestList',

  components: {
    Badge,
    BaseButton,
  },

  props: {
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    canCancelingRequest() {
      return (requestID) => {
        const item = this.items.find((item) => item.ID === requestID);

        return item.CanCancelingRequest && (item.StatusID === 1);
      };
    },
  },

  methods: {
    ...mapActions({
      cancelRequestAction: 'request/cancelRequest',
      getMetersList: 'meter/getMetersList',
    }),

    getStatusType(statusId) {
      if (statusId === 1 || statusId === 7 || statusId === 12) {
        return 'neutral';
      }

      if (statusId === 2 || statusId === 3 || statusId === 4 || statusId === 9) {
        return 'warning';
      }

      if (statusId === 5 || statusId === 6 || statusId === 10 || statusId === 11) {
        return 'success';
      }

      return 'neutral';
    },

    /**
     * Открытие чата заявки
     */
    openRequest(requestId) {
      if (+this.$route.params.requestID !== requestId) {
        this.$router.push({
          name: 'Requests',
          params: { requestID: requestId },
        });
      }
      this.$emit('request-list:open-request');

      // this.selectedRequestID = requestId;
      // if (this.isMobile()) {
      //   this.showDialogModal = true;
      // }
    },

    /**
     * Закрытие выполненной (или нет) заявки
     */
    closeRequest(id) {
      // console.log('--- components -> pages -> List -> closeRequest method ---');
      this.closeRequestModal(id, this.items.find((item) => item.ID === id).RequestNumber);
    },

    /**
     * Отмена (невыполненной) заявки
     */
    cancelRequest(id, number) {
      this.openDialog({
        title: this.$t('cancellation_of_request'),
        text: `${this.$t('sure_cancel_request')} №${number}`,
        buttons: [
          {
            type: 'additional',
            title: this.$t('cancel'),
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            type: 'base',
            title: this.$t('confirm'),
            handler: () => {
              this.$modal.hide('dialog');
              this.cancelRequestAction({
                RequestID: id,
              })
                .then((response) => {
                  if (response.Error) {
                    this.$notify.error({
                      position: 'top-right',
                      title: this.$t('error'),
                      message: response.Error,
                    });
                  } else {
                    this.$notify.success({
                      position: 'top-left',
                      title: this.$t('success'),
                      message: `${this.$t('request')} №${number} ${this.$t('canceled')}.`,
                    });

                    this.$emit('request-list:request-canceled', id);
                  }

                  this.getMetersList();
                })
                .catch((error) => {
                  throw error;
                });
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../../sass/variables'
@import '../../../sass/mixins'

.request
  padding: 16px
  border-bottom: 1px solid $cBorder

  @media screen and (min-width: 768px)
    padding: 16px 24px

  &--active
    background-color: $cElmLight

  .contacts
  .contacts__item
    +TextMain()

    &:not(:last-child)
      margin-bottom: 8px

    span
      color: $cTextMain

.request__header
  display: flex
  justify-content: space-between
  margin-bottom: 16px

.request__date
  +TextMain()

.request__body
  cursor: pointer
  margin-bottom: 16px

.request__title
  +TextHeader(2)
  margin-bottom: 0

  strong
    color: $cRed

.request__title
	word-break: break-word; /* Chrome, Safari */
	word-wrap: break-word; /* IE11, Firefox */
	overflow-wrap: break-word;

.request__number
  +TextMini()
  margin-bottom: 0

.request__contacts
  margin-top: 16px

.request__footer
  display: flex
  justify-content: flex-end

.request__button
  +Size(170px, 40px)
  margin: 0 calc(16px / 2)

  &:first-child
    margin-left: 0

  &:last-child
    margin-right: 0
</style>
