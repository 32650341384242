<template>
  <div v-loading="loading" class="open-poll-page">
    <div class="open-poll-page__wrapper">
      <section class="open-poll-page__content">
        <div class="poll-page__content-overlay"></div>

        <poll-form v-if="!loading && !showOpenPollResults && !showAlert" @pass="onPassOpenPoll" />

        <base-alert v-else-if="!loading && showOpenPollResults && !showAlert">
          {{ $t('poll_already_completed') }}
        </base-alert>

        <base-alert v-else-if="showAlert">
          {{ $t('poll_not_found') }}
        </base-alert>
      </section>
    </div>
  </div>
</template>

<script>
//vuex
import { mapActions } from 'vuex';
// сщьзщтутеы
import PollForm from '@/components/pages/openPolls/OpenPollForm';
import BaseAlert from '@/components/ui/Alert';

export default {
  name: 'OpenPoll',

  components: {
    PollForm,
    BaseAlert
  },

  data() {
    return {
      loading: true,
      showOpenPollResults: false,
      showAlert: false
    }
  },

  computed: {

  },

  created() {
    this.fetchOpenPoll();
  },

  methods: {
    ...mapActions({
      getOpenPollInfo: 'openPolls/getInfo',
    }),

    fetchOpenPoll() {
      this.loading = true;

      this.getOpenPollInfo(this.$route.params.code).then((response) => {
        if(!response) {
          this.showAlert = true;
          return;
        }
        if(response.IsReaded) {
          this.showOpenPollResults = true;
        }
      }).finally(() => {
        this.loading = false;
      })
    },

    onPassOpenPoll() {
      this.showOpenPollResults = true;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../sass/variables';
@import '../sass/mixins';

.opn-poll-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 70px);
}

.open-poll-page__wrapper {
  display: flex;

  overflow-y: hidden;
}

.open-poll-page__content {
  flex-grow: 1;

  width: 100%;
  overflow-y: auto;
  border-radius: 16px 16px 0 0;

  @media screen and (min-width: map-get($bp, 'tablet')) {
    position: initial;
    max-height: initial;
    overflow-y: initial;
  }
}
</style>